













































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import TabInformationsGenerales from "./_TabInfosGenerales.vue";
import DetailsSituationProfessionnelle from "./_DetailsSituationProfessionnelle.vue";
import DetailsInformationSante from "./_DetailsInformationSante.vue";
import ListeActions from "./_ListeActions.vue";
import ListeNotes from "./_ListeNotes.vue";
import EtudeDePoste from "./_EtudeDePoste.vue";
import CreationConsignesDossier from "./_CreateConsignes.vue";
import DossiersInstruits from "./_DossiersInstruits.vue";
import Fichiers from "./_Fichiers.vue";
import { BTabs, BTab, BCardText, BButton } from "bootstrap-vue";
import { Dossier } from "@/api/models/dossiers/dossier";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import ListePassageInfirmerie from "./_ListPassageInfirmerie.vue";
import ListeConsignesDossier from "./_ListeConsignes.vue";
import Index from "@/views/administration/utilisateurs/Profils/Index.vue";

@Component({
  components: {
    TabInformationsGenerales,
    DetailsSituationProfessionnelle,
    DetailsInformationSante,
    ListeActions,
    ListeNotes,
    DossiersInstruits,
    Fichiers,
    CreationConsignesDossier,
    BTabs,
    BTab,
    BCardText,
    EtudeDePoste,
    ListePassageInfirmerie,
    ListeConsignesDossier,
    BButton,
  },
  name: "tabs-menu-details-dossier",
})
export default class TabsMenuDetailsDossier extends Vue {
  @Prop({ required: true }) dossier!: Dossier;
  clearModel = {};

  droitsEnum = DroitsEnum;
  tabs: string[] = [];
  tabIndex: number = 0;
  diffCount: number = 0;
  diff: any;
  diffMode = "";
  prevTab: number = 0;
  nextTab: number = 0;

  async created() {
    this.tabs.push("INFOS_GENERALES");
    this.createClone();
  }

  createClone() {
    var fakeClone = JSON.parse(JSON.stringify(this.dossier));
    this.clearModel = fakeClone;
  }

  deepDiff(obj1: any, obj2: any, mode?: boolean) {
    const diff: any = {};

    for (const key in obj1) {
      if (key === "vue") {
        continue;
      }

        if (obj1.hasOwnProperty(key)) {
          if (!obj2.hasOwnProperty(key)) {
            continue;
          }

          const value1 = obj1[key];
          const value2 = obj2[key];

          if (value1 !== value2) {
            if (
              (value1 === null && value2 === undefined) ||
              (value1 === undefined && value2 === null)
            ) {
              continue;
            }

            if (typeof value1 === "object" && typeof value2 === "object") {
              if (Array.isArray(value1) && Array.isArray(value2)) {
                if (JSON.stringify(value1) !== JSON.stringify(value2)) {
                  diff[key] = { obj1: value1, obj2: value2 };
                }
              } else {
                const nestedDiff = this.deepDiff(value1, value2);
                if (Object.keys(nestedDiff).length > 0) {
                  diff[key] = nestedDiff;
                }
              }
            } else {
              diff[key] = { obj1: value1, obj2: value2 };
            }
          }
        }
      
    }

    return diff;
  }

   pideepDiff(obj1: any, obj2: any, mode?: boolean) {
    const diff: any = {};

    for (const key in obj1) {
      if (key === "vue") {
        continue;
      }

          const value1 = obj1[key];
          const value2 = obj2[key];

          if (value1 !== value2) {
            if (
              (value1 === null && value2 === undefined) ||
              (value1 === undefined && value2 === null)
            ) {
              continue;
            }

            if (typeof value1 === "object" && typeof value2 === "object") {
              if (Array.isArray(value1) && Array.isArray(value2)) {
                if (JSON.stringify(value1) !== JSON.stringify(value2)) {
                  diff[key] = { obj1: value1, obj2: value2 };
                }
              } else {
                const nestedDiff = this.deepDiff(value1, value2);
                if (Object.keys(nestedDiff).length > 0) {
                  diff[key] = nestedDiff;
                }
              }
            } else {
              diff[key] = { obj1: value1, obj2: value2 };
            }
          }
    }

    return diff;
  }

  leaveWithoutSaving() {
    let situationProRef = this.$refs["tabsSituationProfessionelle"] as any;
    let infoSanteRef = this.$refs["tabsInformationSante"] as any;
    let etudeDePosteRef = this.$refs["tabsEtudeDePoste"] as any;
    let passageInfirmerieRef = this.$refs["tabsListPassageInfirmerie"] as any;

   if (
      passageInfirmerieRef &&
      passageInfirmerieRef.$refs["createPassageInfirmerieForm"]
    ) {
      let form = passageInfirmerieRef.$refs["createPassageInfirmerieForm"];
      if (form) {
        form.resetModel();
      }
    }

    if (etudeDePosteRef) {
      let clearModel = etudeDePosteRef.getClearModel();
      let model = etudeDePosteRef.etudeDePoste;
      for (var entry in model) {
        model[entry] = clearModel[entry];
      }
    }

    if (infoSanteRef) {
      let clearModel = infoSanteRef.getClearModel();
      let model = infoSanteRef.informationSante;
      for (var entry in model) {
        model[entry] = clearModel[entry];
      }
    }

    if (situationProRef) {
      let clearModel = situationProRef.getClearModel();
      let model = situationProRef.model;
      for (var entry in model) {
        model[entry] = clearModel[entry];
      }
    }

    if (this.diffMode == "general") {
      for (var entry in this.diff) {
        (this.dossier as any)[entry] = (this.clearModel as any)[entry];
      }
    }

    this.$bvModal.hide("modificationsModel");
    this.tabIndex = this.nextTab;
  }

  reloadClearModel() {
    let situationProRef = this.$refs["tabsSituationProfessionelle"] as any;
    let infoSanteRef = this.$refs["tabsInformationSante"] as any;
    let etudeDePosteRef = this.$refs["tabsEtudeDePoste"] as any;

    if (etudeDePosteRef) {
      etudeDePosteRef.createClone();
    }

    if (situationProRef) {
      situationProRef.createClone();
    }

    if (infoSanteRef) {
      infoSanteRef.createClone();
    }
    this.createClone();
  }

  async saveModifications() {
    var currentIndex = (this.$refs["dossierTabs"] as any).value;
    var tabs = (this.$refs["dossierTabs"] as any).getTabs();

    await tabs[currentIndex].$children[0].silentUpdate().then(() => {
      this.reloadClearModel();
      this.$bvModal.hide("modificationsModel");
      this.tabIndex = this.nextTab;
    });
  }

  async tabsChanged(nextTab: number, previousTab: number, event: any) {
    this.nextTab = nextTab;
    this.prevTab = previousTab;

    let situationProRef = this.$refs["tabsSituationProfessionelle"] as any;
    let infoSanteRef = this.$refs["tabsInformationSante"] as any;
    let etudeDePosteRef = this.$refs["tabsEtudeDePoste"] as any;
    let passageInfirmerieRef = this.$refs["tabsListPassageInfirmerie"] as any;

    var globalDiff: any = {};
    var diffInfoContrat: any = {};
    var diffInfoSante: any = {};
    var diffEtudeDePoste: any = {};
    var diffPassageInfirmerie: any = {};

    var diffGeneral = this.deepDiff(this.clearModel, this.dossier);

    if (
      passageInfirmerieRef &&
      passageInfirmerieRef.$refs["createPassageInfirmerieForm"]
    ) {
      let form = passageInfirmerieRef.$refs["createPassageInfirmerieForm"];
      if (form) {
        var clearModel = form.getClearModel();
        var model = form.getModel();
        diffPassageInfirmerie = this.pideepDiff(model,clearModel);
      }
    }

    if (etudeDePosteRef) {
      let clearModel = etudeDePosteRef.getClearModel();
      let model = etudeDePosteRef.etudeDePoste;
      diffEtudeDePoste = this.deepDiff(clearModel, model);
    }

    if (situationProRef) {
      let clearModel = situationProRef.getClearModel();
      let model = situationProRef.model;
      diffInfoContrat = this.deepDiff(clearModel, model);
    }

    if (infoSanteRef) {
      let clearModel = infoSanteRef.getClearModel();
      let model = infoSanteRef.informationSante;
      diffInfoSante = this.deepDiff(clearModel, model);
    }

    if (Object.keys(diffPassageInfirmerie).length > 0) {
      globalDiff = { ...diffPassageInfirmerie };
      this.diffMode = "passageInfirmerie";
    }

    if (Object.keys(diffEtudeDePoste).length > 0) {
      globalDiff = { ...diffEtudeDePoste };
      this.diffMode = "etudeDePoste";
    }

    if (Object.keys(diffInfoSante).length > 0) {
      globalDiff = { ...diffInfoSante };
      this.diffMode = "infoSante";
    }

    if (Object.keys(diffGeneral).length > 0) {
      globalDiff = { ...diffGeneral };
      this.diffMode = "general";
    }

    if (Object.keys(diffInfoContrat).length > 0) {
      globalDiff = { ...diffInfoContrat };
      this.diffMode = "infoContrat";
    }

    this.diff = globalDiff;

    if (Object.keys(globalDiff).length > 0) {
      event.preventDefault();
      this.diffCount = Object.keys(globalDiff).length;
      this.$bvModal.show("modificationsModel");
    }

    // let previousTabName = this.tabs[previousTab];

    // if (
    //   (previousTabName == this.dossierTabsEnum.DOSSIERS_FICHE_SUIVI && (this.$refs["listNotes"] as any) &&  (this.$refs["listNotes"] as any).createNoteIsUpdated) ||
    //   (previousTabName == this.dossierTabsEnum.DOSSIERS_ACCOMP && (this.$refs["listNotes"] as any) && (this.$refs["listeActions"] as any).createActionIsUpdated)
    // ) {
    //   event.preventDefault();
    //   this.$bvModal
    //     .msgBoxConfirm(
    //       "Êtes-vous sûr de vouloir quitter cette page ? Vous avez commencé à remplir le formulaire, les données seront perdues.",
    //       {
    //         title: "Confirmation de perte des données",
    //         size: "sm",
    //         okVariant: "primary",
    //         okTitle: "Oui",
    //         cancelTitle: "Non",
    //         cancelVariant: "outline-secondary",
    //         hideHeaderClose: false,
    //         centered: true,
    //       }
    //     )
    //     .then(async (value) => {
    //       if (value === true) {
    //         if (previousTabName == this.dossierTabsEnum.DOSSIERS_FICHE_SUIVI) (this.$refs["listNotes"] as any).createNoteIsUpdated = false;
    //         if (previousTabName == this.dossierTabsEnum.DOSSIERS_ACCOMP) (this.$refs["listeActions"] as any).createActionIsUpdated = false;
    //         this.tabIndex = nextTab;
    //       }
    //     });
    // }
  }

  // reloadNotes(){
  //   (this.$refs["listNotes"] as any).loadNotes({})
  // }

  reloadFiles() {
    (this.$refs["listFiles"] as any).loadListFichiers();
  }
}

