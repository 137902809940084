
































































import { Component, Vue } from "vue-property-decorator";
import DetailsDemandeAideFinanciere from "./_DetailsDemandeAideFinanciere.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DemandeAideFinanciere } from "@/api/models/dossiers/demandesAideFinanciere/demandeAideFinanciere";
import { VBPopover, BSpinner } from "bootstrap-vue";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    DetailsDemandeAideFinanciere,
    BSpinner,
    DeleteIcon,
    EditIcon
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "dossier-demandes-aide-financiere"
})
export default class DossierDemandesAideFinanciere extends Vue {
  listDemandeAideFinanciere: PaginatedList<DemandeAideFinanciere> = new PaginatedList<DemandeAideFinanciere>()

  displayDetails = false
  currentDemandeId: string | null = null
  loading: boolean = true

  async created() {
    await this.loadListDemandesAideFinanciere();
  }

  async loadListDemandesAideFinanciere() {
    await this.$http.ressifnet.demandesAideFinanciere.paginatedList(this.$route.params.id, 1, 50)
      .then(
        (response: PaginatedList<DemandeAideFinanciere>) => {
          this.listDemandeAideFinanciere = response
          this.loading = false
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des demandes d\' aide financière'
          })
        }
      )
  }

  async askDeleteDemandeAideFinanciere(id: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la demande d\' aide financière ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value === true){
          await this.confirmDeleteDemandeAideFinanciere(id)
        }
        else {
          return;
        }
      })
  }

  async confirmDeleteDemandeAideFinanciere(id: string) {
    await this.$http.ressifnet.demandesAideFinanciere
      .delete(this.$route.params.id, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une demande d\'aide financière",
            text: "Suppression effectuée avec succès",
          });
          this.loadListDemandesAideFinanciere();
          this.currentDemandeId = null
        }
      )
  }

  async askUpdateDemandeAideFinanciere(id: string){
    this.currentDemandeId = id;
    this.displayDetails = true;
  }

  addDemandeAideFinanciere() {
    this.currentDemandeId = null;
    this.displayDetails = true;
  }

}
