import { render, staticRenderFns } from "./_DetailsDemandeSurendettement.vue?vue&type=template&id=0bab2768&scoped=true&"
import script from "./_DetailsDemandeSurendettement.vue?vue&type=script&lang=ts&"
export * from "./_DetailsDemandeSurendettement.vue?vue&type=script&lang=ts&"
import style0 from "./_DetailsDemandeSurendettement.vue?vue&type=style&index=0&id=0bab2768&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bab2768",
  null
  
)

export default component.exports