




























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DemandeRqth } from "@/api/models/options/demandesRqth/demandeRqth";
import { VBPopover, BSpinner, BProgress, BFormFile, BButton } from "bootstrap-vue";
import { DemandeRetraite } from "@/api/models/dossiers/demandesRetraite/demandeRetraite";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";

@Component({
  components: {
    BSpinner,
    BButton,
    BProgress,
    BFormFile,
    RessifnetDateInput,
    RessifnetQuillEditor
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "dossier-demandes-retraite"
})
export default class DossierDemandesRetraite extends Vue {
  loading: boolean = true

  demandeEdited: DemandeRetraite = {};
  fichiersUploaded: any[] = [];
  percentUpload: number = 0;
  
  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  async created() {
    await this.loadDemandeRetraite();
  }

  get fichiers() {
    var files = [];

    if (this.demandeEdited.fichiers && this.demandeEdited.fichiers.length > 0) {
      files = this.demandeEdited
        .fichiers.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    
    return files.concat(this.fichiersUploaded);
  }

  closeDetails() {
    this.demandeEdited = {
      fichiers : []
    }
    this.$emit('close-details')
  }

  async downloadFile(fichier:any) {
    if (!fichier.id)
      return;
    else {
      await this.$http.ressifnet.fichiersDossiers
        .downloadFile(fichier.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
    }
  }
  async loadDemandeRetraite() {
    this.loading = true
    await this.$http.ressifnet.demandesRetraite.get(this.$route.params.id)
      .then(
        (response: any) => {
          if (response != '')
            this.demandeEdited = response
          this.loading = false

          this.fichiersUploaded = this.demandeEdited?.fichiers ?? []
          this.demandeEdited.fichiers = [] 
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement de la demande de retraite'
          })
        }
      )
  }
  async save(callback: any) {
    let config = {
      onUploadProgress(progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        callback(percentCompleted);
        return percentCompleted;
      },
    };

    await this.$http.ressifnet.demandesRetraite
      .put(this.$route.params.id, this.demandeEdited, config)
      .then(
        (response: any) => {
          this.$emit('reloadDossier');
          this.$emit('reloadNotes');
          this.$emit('reloadFiles');
          successAlert.fire({
            title: "Modification d'une demande de retraite",
            text: "Modification effectué avec succès",
          });
          this.percentUpload = 0;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
          this.percentUpload = 0;
        }
      )
      .finally(async() => {
        await this.loadDemandeRetraite()
      });
  }

  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de la demande retraite ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.demandeEdited.fichiers!.splice(
        (this.demandeEdited.fichiers! as any[]).indexOf(fichier),
        1
      );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.demandesRetraite
      .deleteFile(
        this.$route.params.id,
        (this.demandeEdited as any).id,
        fichier.id
      )
      .then((res: any) => {
        this.$emit('reloadDossier');
        this.loadDemandeRetraite()
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        (this.demandeEdited.fichiers! as any[]).push({ id: fichier.id });
        this.fichiersUploaded.splice(
          (this.fichiersUploaded as any[]).indexOf(fichier),
          1
        );
      });
  }
}
