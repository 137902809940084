































import { Dossier } from "@/api/models/dossiers/dossier";
import { Component, Prop, Vue } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";


@Component({
  components: {
  },
})
export default class DeleteDossier extends Vue {
  @Prop({ required: true }) dossier!: Dossier;

  async askDeleteDossier(dossierId: string) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer le dossier ? Celui-ci sera conservé pendant 30 jours",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.deleteDossier(dossierId);
        } else {
          return;
        }
      });
  }

  async deleteDossier(dossierId: string) {
    await this.$http.ressifnet.dossiers.delete(dossierId).then(
      async (response: string) => {
        successAlert.fire({
          title: "Suppression d'un dossier",
          text: "Dossier marqué pour la suppression",
        });
        this.$emit("reloadDossier");
      },
      (error: any) => {
        if (
          error.response?.data?.detail &&
          error.response.data.detail.length > 0
        ) {
          errorAlert.fire({
            text: error.response.data.detail,
          });
        } else {
          errorAlert.fire({
            text: error.message,
          });
        }
      }
    );
  }

  async askRestoreDossier(dossierId: string) {
    this.$bvModal
      .msgBoxConfirm("Voulez-vous restaurer le dossier ?", {
        title: "Confirmation de restauration",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.restoreDossier(dossierId);
        } else {
          return;
        }
      });
  }

  async restoreDossier(dossierId: string) {
    await this.$http.ressifnet.dossiers.restore(dossierId).then(
      async (response: string) => {
        successAlert.fire({
          title: "Restauration du dossier",
          text: "Restauration effectuée avec succès",
        });
        this.$emit("reloadDossier");
      },
      (error: any) => {
        if (
          error.response?.data?.detail &&
          error.response.data.detail.length > 0
        ) {
          errorAlert.fire({
            text: error.response.data.detail,
          });
        } else {
          errorAlert.fire({
            text: error.message,
          });
        }
      }
    );
  }
}
