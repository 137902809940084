



































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { BSpinner, BProgress, BButton, BPagination, BTable } from "bootstrap-vue";
import { Fichier } from "@/api/models/dossiers/fichier";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  components: {
    BSpinner,
    BProgress,
    BButton,
    BPagination,
    BTable,
    DeleteIcon,
    MultipleFileInput
  },

})
export default class Fichiers extends Vue {
  listFichiers: Fichier[] = []
  loading: boolean = true
  percentUpload = 0
  refreshIndex = 0
  fichiers: any[] = []

  tableColumns = [
    { key: 'nom', label: 'nom du fichier', sortable: true },
    { key: 'type', sortable: true },
    { key: 'created', label: 'date d\'ajout', sortable: true },
    { key: 'creator.identifiant', label: 'ajouté par', sortable: true },
    { key: 'fichier', label: 'lié à', sortable: false },
    { key: 'actions', sortable: false },
  ]


  @Watch('currentPage')
  async currentPageChange() {
    await this.loadListFichiers()
  }

  onUploadProgress(percent: any) {
    console.log("onUploadProgress");
    this.percentUpload = percent;
  }

  async created() {
    await this.loadListFichiers();
  }

  async loadListFichiers() {
    this.loading = true;
    await this.$http.ressifnet.fichiersDossiers
      .getFiles(this.$route.params.id)
      .then(
        (response: Fichier[]) => {
          this.listFichiers = response;
          this.loading = false;
          this.fichiers = [];
        }
      )
  }

  async downloadFile(fichier: any) {
    if (!fichier.id)
      return;
    else {
      await this.$http.ressifnet.fichiersDossiers
        .downloadFile(fichier.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
    }
  }

  async askDeleteFichier(id: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le fichier ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if (value === true) {
          await this.confirmDeleteFichier(id)
        }
        else {
          return;
        }
      })
  }

  async confirmDeleteFichier(id: string) {
    await this.$http.ressifnet.fichiersDossiers
      .deleteFile(this.$route.params.id, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'un fichier",
            text: "Suppression effectuée avec succès",
          });
          this.loadListFichiers();
        }
      )
  }

  async addFichier(callback: any) {
    let config = {
      onUploadProgress(progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        callback(percentCompleted);
        return percentCompleted;
      },
    };

    await this.$http.ressifnet.dossiers
      .uploadFichier(this.$route.params.id, this.fichiers, config)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Ajout d'un fichier",
            text: "Ajout effectué avec succès",
          });
          this.percentUpload = 0;
          this.loadListFichiers();
        }
      )
  }

  removeFichier(fichier: any) {
    if (fichier != undefined) {
        this.fichiers!.splice(
        (this.fichiers! as any[]).indexOf(fichier),
        1);
    }
  }

  async removeAllFichier() {
    this.fichiers = []
  }
}
