






















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Dossier } from "@/api/models/dossiers/dossier";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Referent } from "@/api/models/authorization/userDetails/userDetail";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { BFormCheckbox, BAlert, BBadge, BButton } from "bootstrap-vue";
import { InformationSante } from "@/api/models/dossiers/informationSante";
import moment from "moment";

@Component({
  components: {
    FeatherIcon,
    ValidationObserver,
    ValidationProvider,
    SearchableVueSelect,
    BFormCheckbox, 
    BAlert,
    BBadge,
    BButton
  },
})
export default class InformationsEtatDossier extends Vue {
  droitsEnum = DroitsEnum;

  listAllergies: string[] = []

  @Prop({ required: true }) dossier!: Dossier;
  @Prop({ required: true }) etatDossier!: any;
  @Prop({ required: true }) switchCheckbox!: any;

  @Watch('switchCheckbox')
  switchCheckboxFunction() {
    this.$emit('reloadSwitch', this.switchCheckbox)
  }

  async created() {
    await this.loadListInformationsSante()
  }
  
  calculerAge(birthday: any) {
    var today = moment();
    var birthdate = moment(birthday, 'DD-MM-YYYY');
    const age = today.diff(birthdate, 'years');
    return age;
  }
  
  async loadListInformationsSante() {
    await this.$http.ressifnet.informationsSante.get(this.$route.params.id).then(
      (response: InformationSante) => {
        this.listAllergies = response.allergies != null ? response.allergies : []
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des Informations de santé",
        });
      }
    );
  }
}
