

































































import { Component, Prop, Vue } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BPagination,
  BProgress
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BCardText,
    BAlert,
    BTable,
    BCol,
    BPagination,
    BProgress,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    RessifnetQuillEditor,
    FeatherIcon,
    MultipleFileInput
  }
})
export default class PassageInfirmerieCommentairesFichiers extends Vue {
  @Prop({ required: true }) editable: boolean = true;

  required = required;
  min = min;
  max = max;

  conf:any;

  fichiersUploaded :any[] = [];
  currentPage: any = 1;
  perPage: any = 10;

  percentUpload: number = 0;

  commentaireEtFichierModel: PassageInfirmerie = {
    fichiers: []
  };
  clearModel: PassageInfirmerie = {};

  created() {
    setTimeout(() => {
      this.clearModel = JSON.parse(JSON.stringify(this.commentaireEtFichierModel));  
      this.fichiersUploaded = JSON.parse(JSON.stringify(this.commentaireEtFichierModel.fichiers ?? []))
    }, 1000)    
  }

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    this.clearModel = JSON.parse(JSON.stringify(this.commentaireEtFichierModel));
  }

  get fichiers() {
    var files = [];
    if (this.commentaireEtFichierModel.fichiers && this.commentaireEtFichierModel.fichiers.length > 0) {
      
      files = this.commentaireEtFichierModel
        .fichiers
        .filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded);
  }
  
  getFormattedData(){
    return {
      model: this.commentaireEtFichierModel,
      config : this.conf
    };
  }

  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  async save(callback: any){
   await (this.$refs["formValidation"] as any)
    .validate()
    .then(async (result: boolean) => {
      if (!result) return;
      else {
        this.conf = {
          onUploadProgress(progressEvent: any) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            callback(percentCompleted);
            return percentCompleted;
          },
        };
      }
    }); 
  }

  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier du passage infirmerie ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.commentaireEtFichierModel.fichiers = this.commentaireEtFichierModel
        .fichiers!.filter(f => f.name != fichier.nom );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.passagesInfirmerie
      .deleteFile(
        this.$route.params.id,
        (this.commentaireEtFichierModel as any).id,
        fichier.id
      )
      .then((res: any) => {
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        this.fichiersUploaded = this.fichiersUploaded
          .filter(f => f.nom != fichier.nom );
      });
  }
}

