

















































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PaginatedList } from '@/api/models/common/paginatedList'
import { TypeContrat } from "@/api/models/options/typeContrats/typeContrat";
import { CategorieSocioPro } from "@/api/models/options/categoriesSocioPro/categorieSocioPro";
import { Horaire } from "@/api/models/options/horaires/horaire";
import { TempTravail } from "@/api/models/options/tempTravails/tempTravail";
import { 
  BSpinner,
  BFormSelect, 
  BFormCheckbox,
  BFormSelectOption, 
  BInputGroup, 
  BFormInput, 
  BTable,
  BCol,
  BPagination,
  BRow,
  BButton
 } from 'bootstrap-vue';
import { SituationProfessionnelles } from "@/api/models/options/situationProfessionnelles/situationProfessionnelle";
import SearchableVueSelect from '@/components/selects/SearchableVueSelect.vue'
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import { Tag } from "@/api/models/options/entreprises/tag";
import { InformationsContratCreateModelForm, InformationsContratUpdateModelForm } from "@/api/models/dossiers/situationProfessionnelle";
import { MoyenLocomotion } from "@/api/models/options/moyensLocomotion/moyenLocomotion";
import { Habilitation, HabilitationUpdateModel } from "@/api/models/options/habilitations/habilitation";
import CreateHabilitation from "./_CreateHabilitation.vue";
import UpdateHabilitation from "./_UpdateHabilitation.vue";
import { TypeHabilitationEnum } from "@/api/models/enums/typeHabilitations";
import { NatureRestriction } from '../../../api/models/options/situationProfessionnelles/naturesRestrictions';
import { AptitudeAvis } from '../../../api/models/options/situationProfessionnelles/aptitudesAvis';
import { Amenagement } from '../../../api/models/options/situationProfessionnelles/amenagement';
import { Equipement } from "@/api/models/dossiers/equipement";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { max, min, required } from "vee-validate/dist/rules";

@Component({
  components: {
    BSpinner,
    BFormSelect,
    BFormCheckbox,
    SearchableVueSelect,
    RessifnetDateInput,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    BInputGroup,
    BFormInput,
    BTable,
    CreateHabilitation,
    UpdateHabilitation,
    BCol,
    BPagination,
    BRow,
    BButton
  }
})
export default class DetailsSituationProfessionnelle extends Vue {
  @Prop({ required: true }) dossierId!: string;

  required = required;
  min = min;
  max = max;

  loading = true;
  consignStatut: boolean = false;

  isSortDirDesc = false;
  sortBy = 'dateHabilitation';

  modalIsfor = '';

  typeHabilitationEnum = TypeHabilitationEnum

  currentPage: any = 1;
  perPage: any = 10;
  perPageOptions = [2, 5, 10, 25, 50, 100];

  isAddNewHabilitationSidebarActive = false;
  isUpdateHabilitationSidebarActive = false;

  tableColumns = [
    { key: 'dateHabilitation', label: "date d'habilitation", sortable: false, disable: false },
    { key: 'typeHabilitation', label: "Habilitation", sortable: false, disable: false },
    { key: 'dateFin', label: "date de fin", sortable: false, disable: false },
    { key: 'actions', sortable: false, disable: false }
  ];

  listHabilitation: PaginatedList<Habilitation> = new PaginatedList<Habilitation>();

  selectedHabilitationId: any;
  consigneTransmissionsDate: any = '';
  habItem: any = '';
  
  model: InformationsContratUpdateModelForm = {}

  clearModel: InformationsContratUpdateModelForm = {}
  
  listCategoriesSocioPro: CategorieSocioPro[] = []
  listTypesContrat: TypeContrat[] = []
  listHoraires: Horaire[] = []
  listTempsTravail: TempTravail[] = []
  listMoyenLocomotion: MoyenLocomotion[] = []

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.listHabilitation.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.listHabilitation.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListHabilitation({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }
  listNaturesRestrictions: any[] = []
  totalCountNatureRestriction: number = 0;
  listAptitudesAvis: AptitudeAvis[] = []
  listAmenagements: Amenagement[] = []
  aptitudeAvisSelected: AptitudeAvis | null | undefined = undefined;

  listEquipements: Equipement[] = []
  totalCountEquipements: number = 0;

  async created() {
    await this.loadListTypeContrats()
    await this.loadlistCategorieSocioPros()
    await this.loadListTempsTravails()
    await this.loadListHoraires()
    await this.loadListAmenagements()
    await this.loadListAptitudesAvis()
    await this.loadListNaturesRestrictions()
    await this.loadSituationProfessionnelle()
    await this.loadMoyensLocomotion()
    await this.loadListHabilitation({
      pageNumber: this.currentPage,
      pageSize: this.perPage
    })
    await this.loadEquipements();
    this.clearModel = JSON.parse(JSON.stringify(this.model));
  }

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.model));
    this.clearModel = fakeClone;    
  }

  async silentUpdate(){
    let situationToUpdate = (
      ({ id, dossierId, dateDebut, dateFin, secteurId, tagsId, equipementsId, sst, dateCertification, typeContratId, horaireId, categorieSocioProId , tempsTravailId, distance, tempsTrajet, moyensLocomotionIds, aptitudeAvisId, amenagementId, naturesRestrictionsId, dateFinRestrictions, commentaire }) => 
      ({ id, dossierId, dateDebut, dateFin, secteurId, tagsId, equipementsId, sst, dateCertification, typeContratId, horaireId, categorieSocioProId , tempsTravailId, distance, tempsTrajet, moyensLocomotionIds, aptitudeAvisId, amenagementId, naturesRestrictionsId, dateFinRestrictions,  commentaire }))(this.model);

    await (this.$refs['formValidation'] as any)
      .validate()
      .then(async (res: boolean) => {
        if (!res) {
          return false;
        }
        else {
          await this.$http.ressifnet.situationProfessionnelles.put(this.$route.params.id, situationToUpdate)
            .then(async(response: any) => {
              return true;
            }, (error: any) => {
              return false;
            });
        }
      })
  }

  getFormatedConsigneDate(dateString:any , month:number){
    var parts = dateString.split('/');
    var formattedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];
    var date = new Date(formattedDateString);
     date.setMonth(date.getMonth() - month);
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var jourStr = jour < 10 ? '0' + jour : jour.toString();
    var moisStr = mois < 10 ? '0' + mois : mois.toString();
    var anneeStr = annee.toString();
    var dateResultat = jourStr + '/' + moisStr + '/' + anneeStr;
    return dateResultat;
  }

   toggleModal(key:any, item?:any){
    var dateString = '';
    this.consigneTransmissionsDate = '';

    switch (key) {
      case 'sst':
        var dateString = this.model.dateConsigneEcheanceSST ? new Date(this.model.dateConsigneEcheanceSST!).toLocaleDateString() : this.model.dateCertification!;  
        this.modalIsfor = 'sst';
        this.consigneTransmissionsDate = this.model.dateConsigneEcheanceSST ? new Date(this.model.dateConsigneEcheanceSST!).toLocaleDateString() : this.getFormatedConsigneDate(dateString,-18);
        console.log(this.consigneTransmissionsDate);
        if(!this.model.consigneEcheanceSSTActivee){
          this.consignStatut = true;
        }
        break;
      case 'amng':
        var dateString = this.model.dateConsigneEcheanceAmenagement ? new Date(this.model.dateConsigneEcheanceAmenagement!).toLocaleDateString() : this.model.dateFinRestrictions!; 
        this.consigneTransmissionsDate = this.model.dateConsigneEcheanceAmenagement ? new Date(this.model.dateConsigneEcheanceAmenagement!).toLocaleDateString() : this.getFormatedConsigneDate(dateString,1);
        this.modalIsfor = 'amng';
        if(!this.model.consigneEcheanceAmenagementActivee){
          this.consignStatut = true;
        }
        break;
      case 'hab':
        var dateString = item.dateConsigneEcheanceHabilitation as string ? item.dateConsigneEcheanceHabilitation as string : item.dateFin as string; 
        this.modalIsfor = 'hab';
        this.consigneTransmissionsDate = item.dateConsigneEcheanceHabilitation as string ? item.dateConsigneEcheanceHabilitation as string : this.getFormatedConsigneDate(dateString,2);
        this.habItem = item;
        if(!item.consigneEcheanceHabilitationActivee){
          this.consignStatut = true;
        }
        break;
    
      default:
        break;
    }
   
    this.$bvModal.show('modal-consignes-contrat');
  }

   async updateConsigneStatut(){
    switch (this.modalIsfor) {
      case 'sst':
        var dateString = this.model.dateCertification!;  
        this.model.consigneEcheanceSSTActivee = !this.consignStatut;  
        
        if(this.getFormatedConsigneDate(dateString, 18) != this.consigneTransmissionsDate){
          this.model.dateConsigneEcheanceSST = this.consigneTransmissionsDate;
        }
        if(!this.model.consigneEcheanceSSTActivee && this.model.dateConsigneEcheanceSST){
          this.model.dateConsigneEcheanceSST = undefined;
        }
        this.modalIsfor = 'sst';
        break;
      case 'amng':
        this.model.consigneEcheanceAmenagementActivee = !this.consignStatut;
        var dateString = this.model.dateFinRestrictions!; 
        if(this.getFormatedConsigneDate(dateString,1) != this.consigneTransmissionsDate){
          this.model.dateConsigneEcheanceAmenagement = this.consigneTransmissionsDate;
        }
        if(!this.model.consigneEcheanceAmenagementActivee && this.model.dateConsigneEcheanceAmenagement){
            this.model.dateConsigneEcheanceAmenagement = undefined;
          }
        this.modalIsfor = 'amng';
        break;
      case 'hab':
        await this.updateHabilitationStatut(this.habItem);
        this.modalIsfor = 'hab';    
      default:
        break;
    }    

    this.consignStatut = false;
  }  

  async updateHabilitationStatut(item:any){
    item.consigneEcheanceHabilitationActivee = !this.consignStatut;
    var dateString = item.dateFin!; 
    if(this.getFormatedConsigneDate(dateString,2) != this.consigneTransmissionsDate){
      item.dateConsigneEcheanceHabilitation = this.consigneTransmissionsDate;
    }
    if(item.consigneEcheanceAmenagementActivee && item.dateConsigneEcheanceHabilitation){
        item.dateConsigneEcheanceHabilitation = undefined;
      }
    item.dossierId = this.dossierId;

      await this.$http.ressifnet.habilitations.put(this.$route.params.id, item.id,item)
        .then(
          async (response: any) => {
              successAlert.fire({
                timer: 3500,
                title: "Modification d'une habilitation",
                text: "habilitation modifié avec succès",
              });
            },     
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        )
        .finally(() => {
          this.$emit('refetch-data')
        });
  }

  async updateSituationProfessionnelle(item: InformationsContratUpdateModelForm) {

    if(this.model.dateCertification && this.model.consigneEcheanceSSTActivee){
      this.model.consigneEcheanceSSTActivee = true;
    }


    if(this.model.dateFinRestrictions && this.model.consigneEcheanceAmenagementActivee){
      this.model.consigneEcheanceAmenagementActivee = true;
    }

    let situationToUpdate = (
      ({ id, dossierId, dateDebut, dateFin, secteurId, tagsId, equipementsId, sst, dateCertification, typeContratId, horaireId, categorieSocioProId , tempsTravailId, distance, tempsTrajet, moyensLocomotionIds, aptitudeAvisId, amenagementId, naturesRestrictionsId, dateFinRestrictions, commentaire, consigneEcheanceSSTActivee, consigneEcheanceAmenagementActivee, dateConsigneEcheanceAmenagement, dateConsigneEcheanceSST }) => 
      ({ id, dossierId, dateDebut, dateFin, secteurId, tagsId, equipementsId, sst, dateCertification, typeContratId, horaireId, categorieSocioProId , tempsTravailId, distance, tempsTrajet, moyensLocomotionIds, aptitudeAvisId, amenagementId, naturesRestrictionsId, dateFinRestrictions,  commentaire, consigneEcheanceSSTActivee, consigneEcheanceAmenagementActivee, dateConsigneEcheanceAmenagement, dateConsigneEcheanceSST }))(item);

    await (this.$refs['formValidation'] as any)
      .validate()
      .then(async (res: boolean) => {
        if (!res) {
          this.$swal({
            title: 'Problème dans la saisie',
            text: 'Le formulaire n\'est pas complet',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
            buttonsStyling: false,
          })
        }
        else {
          await this.$http.ressifnet.situationProfessionnelles.put(this.$route.params.id, situationToUpdate)
            .then(async(response: any) => {
              this.$emit('reloadDossier')
              successAlert.fire({
                title: 'Modification d\'une situation professionnelle',
                text: 'Modification effectuée avec succès',
              })

            }, (error: any) => {
              if (
                error?.response?.data?.errors &&
                Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            }
            );
        }
      })
  }

  async loadMoyensLocomotion(){
    await this.$http.ressifnet.moyensLocomotion.paginatedList(1, 50, true).then(
      (response: PaginatedList<MoyenLocomotion>) => {
        this.listMoyenLocomotion = response.items
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des temps de travail",
        });
      }
    );
  }

  async loadListHabilitation(params: any) {
    await this.$http.ressifnet.habilitations.paginatedList(this.$route.params.id, params.pageNumber, params.pageSize).then(
      (response: PaginatedList<Habilitation>) => {
        this.listHabilitation = response
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des habilitations",
        });
      }
    );
  }

  selectHabilitation(habilitationId: string) {
    this.selectedHabilitationId = habilitationId;
    this.isUpdateHabilitationSidebarActive = true;
  }

  deleteHabilitation(habilitationId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer l'habilitation ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(habilitationId);
        } else {
          return;
        }
      });
  }

  async confirmDelete(habilitationId: string) {
    await this.$http.ressifnet.habilitations
      .delete(this.$route.params.id, habilitationId)
      .then(
        (response: any) => {
          successAlert.fire({
            title: "Suppression d\'une habilitation",
            text: "Suppression effectuée avec succès"
          })
          this.loadListHabilitation({
            pageNumber: this.currentPage,
            pageSize: this.perPage,
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async loadSituationProfessionnelle() {
    await this.$http.ressifnet.situationProfessionnelles
      .get(this.$route.params.id).then((response: SituationProfessionnelles) => {
        const newModel : InformationsContratUpdateModelForm = {
          totalCountEntreprise: 1,
          listeEntreprises: [
            {
              id: response.entrepriseId,
              nom: response.entrepriseLibelle
            },
          ],

          totalCountSite: 1,
          listeSites: [
            {
              id: response.siteId,
              libelle: response.siteLibelle,
              regionId: response.regionId,
              regionLibelle: response.regionLibelle
            }
          ],

          totalCountTag: response.tags?.length,
          listeTags: response.tags,
          tagsId: response.tags?.map(x=> x.id),

          totalCountNatureRestriction: response.naturesRestrictions?.length,
          listeNaturesRestrictions: response.naturesRestrictions,
          naturesRestrictionsId: response.naturesRestrictions?.map(x=> x.id),

          totalCountSecteur: 1,
          listeSecteurs: [
            {
              id: response.secteurId,
              libelle: response.secteurLibelle,
              siteId: response.siteId,
              regionId: response.regionId
            }
          ],
          distance: response.distance,
          tempsTrajet: response.tempsTrajet,
          moyensLocomotionIds: response.moyensLocomotionIds,

          totalCountEquipement: response.equipements?.length,
          listeEquipements: response.equipements ?? [],
          equipementsId: response.equipements?.map(x=> x.id),
          ...response,
        }
        this.model = newModel;
        this.searchSites(null, newModel)
        this.loading = false;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des details",
        });
      }
    );
  }

  async searchEntreprises(params: any, item: InformationsContratUpdateModelForm) {
    if (params?.reset) {
      item.listeEntreprises = item.listeEntreprises?.filter(e => e.id == item.entrepriseId);
      item.totalCountEntreprise = item.listeEntreprises?.length;
    }
    
    await this.$http.ressifnet.entreprises.paginatedList(
      params?.pageNumber ?? 1, 
      params?.pageSize ?? 10, 
      params?.search ?? '')
      .then((res: PaginatedList<Entreprise>) => {
        item.totalCountEntreprise = res.totalCount
        item.listeEntreprises = item.listeEntreprises!.concat(res.items.filter(ri => !item.listeEntreprises!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  async searchSites(params: any, item: InformationsContratUpdateModelForm) {
    if (params?.reset) {
      item.listeSites = item.listeSites?.filter(e => e.id == item.siteId);
      item.totalCountSite = item.listeSites?.length;
    }

    await this.$http.ressifnet.sites
      .paginatedList(item.entrepriseId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Site>) => {
        item.totalCountSite = res.totalCount
        item.listeSites = item.listeSites!.concat(res.items.filter(ri => !item.listeSites!.some(lsi => lsi.id == ri.id)))
      })
  }

  async searchTags(params: any, item: InformationsContratUpdateModelForm) {
    if (params?.reset) {
      item.listeTags = item.listeTags?.filter(e => item.tagsId?.some(id => id == e.id));
      item.totalCountTag = item.listeTags?.length;
    }

    await this.$http.ressifnet.tags
      .paginatedList(item.entrepriseId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Tag>) => {
        item.totalCountTag = res.totalCount
        item.listeTags = item.listeTags!.concat(res.items.filter(ri => !item.listeTags!.some(lsi => lsi.id == ri.id)))
      }) 
  }
  
  async onClearEntreprise(item: InformationsContratUpdateModelForm) {
    item.entrepriseId = undefined
    item.siteId = undefined
    item.secteurId = undefined
    item.listeSites = []
    item.listeSecteurs = []
    item.listeTags = []
    item.tagsId = []
    item.brancheLibelle = "Aucun site selectionné" 
    item.regionLibelle = "Aucun site selectionné" 
    await this.searchEntreprises(null, item)
  }

  async onClearSite(item: InformationsContratUpdateModelForm) {
    item.siteId = undefined
    item.secteurId = undefined
    item.listeSites = []
    item.listeSecteurs = []
    item.brancheLibelle = "Aucun site selectionné" 
    item.regionLibelle = "Aucun site selectionné" 
    await this.searchSites(null, item)
  }

  async searchSecteurs(params: any, item: InformationsContratUpdateModelForm) {
    if (!params || params?.reset) {
      item.listeSecteurs = item.listeSecteurs?.filter(e => e.id == item.secteurId);
      item.totalCountSecteur = item.listeSecteurs?.length;
    }
    
    await this.$http.ressifnet.secteurs
      .paginatedListForSite(item.entrepriseId, item.siteId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Secteur>) => {
        item.totalCountSecteur = res.totalCount
        item.listeSecteurs = item.listeSecteurs!.concat(res.items.filter(ri => !item.listeSecteurs!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  onSiteSelected(val: any, item: InformationsContratUpdateModelForm) {
    if (val) {
      const site = item.listeSites?.filter(s => s.id == val)[0]
      item.secteurId = undefined
      item.listeSecteurs = []
      item.brancheLibelle = site?.brancheLibelle ?? "Ce site n'a pas de branche"
      item.regionLibelle = site?.regionLibelle ?? "Ce site n'a pas de région"
    }
    else {
      item.brancheLibelle = "Aucun site selectionné" 
      item.regionLibelle = "Aucun site selectionné" 
    }
  }

  onEntrepriseSelected(val: any, item: InformationsContratUpdateModelForm) {
    if (val) {
      const entreprise = item.listeEntreprises?.filter(s => s.id == val)[0]
      item.siteId = undefined
      item.listeSites = []
      item.secteurId = undefined
      item.listeSecteurs = []
      item.listeTags = []
      item.tagsId = []
      item.brancheLibelle = entreprise?.site?.brancheLibelle ?? "Ce site n'a pas de branche"
      item.regionLibelle = entreprise?.site?.regionLibelle ?? "Ce site n'a pas de région"
    }
    else {
      item.brancheLibelle = "Aucun site selectionné" 
      item.regionLibelle = "Aucun site selectionné" 
    }
  }

  async loadListTempsTravails() {
    await this.$http.ressifnet.tempsTravail.paginatedList(1, 50, true).then(
      (response: PaginatedList<TempTravail>) => {
        this.listTempsTravail = response.items
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des temps de travail",
        });
      }
    );
  }

  async loadListAmenagements() {
    await this.$http.ressifnet.amenagements.paginatedList(1, 50, true).then(
      (response: PaginatedList<Amenagement>) => {
        this.listAmenagements = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des types d'aménagement",
        });
      }
    );
  }

  async loadListAptitudesAvis() {
    await this.$http.ressifnet.aptitudesAvis.paginatedList(1, 50, true).then(
      (response: PaginatedList<AptitudeAvis>) => {
        this.listAptitudesAvis = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des aptitudes avis",
        });
      }
    );
  }

  async loadListNaturesRestrictions() {
    await this.$http.ressifnet.naturesRestrictions.paginatedList(1, 50, true).then(
      (response: PaginatedList<NatureRestriction>) => {
        this.totalCountNatureRestriction = response.totalCount;
        this.listNaturesRestrictions = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des natures de restrictions",
        });
      }
    );
  }

  async searchNaturesRestrictions(params: any, model: InformationsContratUpdateModelForm) {
    if (!params || params?.reset) {
      this.listNaturesRestrictions = this.listNaturesRestrictions.filter(e => model.naturesRestrictionsId?.some(id => id == e.id));
      this.totalCountNatureRestriction = this.listNaturesRestrictions.length;
    }
    await this.$http.ressifnet.naturesRestrictions
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Tag>) => {
        this.totalCountNatureRestriction = res.totalCount
        this.listNaturesRestrictions = this.listNaturesRestrictions!.concat(res.items.filter(ri => !this.listNaturesRestrictions!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  @Watch("model.aptitudeAvisId")
  async onSelectedAptitudeAvis(id: number) {
    this.aptitudeAvisSelected = null
    if (id) 
      this.aptitudeAvisSelected = this.listAptitudesAvis.find(a => a.id == id.toString());
    
    if (this.aptitudeAvisSelected && !this.aptitudeAvisSelected.isMPA) {
      this.model.amenagementId = null;
      this.model.naturesRestrictionsId = undefined;
      this.model.dateFinRestrictions = null;
    }
  }

  async loadListHoraires() {
    await this.$http.ressifnet.horaires.paginatedList(1, 50, true).then(
      (response: PaginatedList<Horaire>) => {
        this.listHoraires = response.items
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des horaires",
        });
      }
    );
  }

  async loadlistCategorieSocioPros() {
    await this.$http.ressifnet.categoriesSocioPro
      .paginatedList(1, 50, true)
      .then(
        (response: PaginatedList<CategorieSocioPro>) => {
          this.listCategoriesSocioPro = response.items
        },
        (error: any) => {
          errorAlert.fire({
            title:
              "Erreur lors du chargement des categories socio-professionnelles",
          });
        }
      );
  }

  async loadListTypeContrats() {
    await this.$http.ressifnet.typeContrats.paginatedList(1, 50, true).then(
      (response: PaginatedList<TypeContrat>) => {
        this.listTypesContrat = response.items
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des types de contrats",
        });
      }
    );
  }

  async loadEquipements() {
    await this.$http.ressifnet.equipements.paginatedList(1, 50, true).then(
      (response: PaginatedList<Equipement>) => {
        this.totalCountEquipements = response.totalCount;
        this.listEquipements = response.items;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des équipements",
        });
      }
    );
  }

  async searchEquipements(params: any, model: InformationsContratUpdateModelForm) {
    if (!params || params?.reset) {
      this.listEquipements = this.listEquipements.filter(e => model.equipementsId?.some(id => id == e.id));
      this.totalCountEquipements = this.listEquipements.length;
    }

    await this.$http.ressifnet.equipements
      .paginatedList(params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Tag>) => {
        this.totalCountEquipements = res.totalCount
        this.listEquipements = this.listEquipements!.concat(res.items.filter(ri => !this.listEquipements!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  @Watch("model.dateCertification")
  setConsignOn(){
      if(this.model.dateCertification && this.model.dateCertification != null){
        this.model.consigneEcheanceSSTActivee = true;
      }else {
        this.model.consigneEcheanceSSTActivee = false;
      }
    }

  @Watch("model.sst")
  onSstChange(value: boolean) {
    if(!value) this.model.dateCertification = null;
  }

  defaultDate(item: InformationsContratCreateModelForm) {
    Vue.set(item, "dateDebut", new Date("01/01/1900").toLocaleString())
  }
}

