






























































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BFormInput,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BFormRadioGroup,
  BFormGroup,
  BPagination,
  BFormRadio,
  BSpinner,
  BFormTimepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import PISituationProfessionnellesRessource from "@/api/ressources/piSituationProfessionnelles";
import { PiSituationProfessionnelle } from "@/api/models/options/piSituationsProfessionnelles/piSituationProfessionnelle";
import { Origine } from "@/api/models/options/origines/origine";
import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";
import { TypeVisite } from "@/api/models/options/typesVisite/typeVisite";
import { DetailTypeVisite } from "@/api/models/options/detailsTypeVisite/detailTypeVisite";
import { PrecisionNatureTypeVisite } from "@/api/models/options/precisionsNatureTypeVisite/precisionNatureTypeVisite";
import { TypeSuivi } from "@/api/models/options/typesSuivi/typeSuivi";
import { InformationSante, UpdateInformationSanteModel } from "@/api/models/dossiers/informationSante";

@Component({
  components: {
    BFormSelect,
    BFormGroup,
    BInputGroup,
    BFormRadio,
    BFormInput,
    BFormRadioGroup,
    BButton,
    BCardText,
    BAlert,
    BTable,
    BCol,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    SearchableVueSelect,
    RessifnetQuillEditor,
    FeatherIcon,
    RessifnetDateInput,
    BSpinner,
    BFormTimepicker
  }
})
export default class PassageInfirmerieGeneral extends Vue {
  @Prop({ required: true }) editable: boolean = true;
  
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))

  generalModel: PassageInfirmerie = {
    datePassageInfirmerie: this.dateToday,
    actionsRealiseesId: [],
    typeVisite: undefined
  };

  clearModel:PassageInfirmerie = {
    datePassageInfirmerie: this.dateToday,
    actionsRealiseesId: [],
    typeVisite: undefined
  };

  allListLoads : boolean = false;

  required = required;
  min = min;
  max = max;
  listIsLoad = true;
  refreshIndex = 0;

  currentPage: any = 1;
  perPage: any = 10;

  listOfDetails: any[] = [];
  origines: PaginatedList<Origine> = new PaginatedList<Origine>();
  situationProfessionnelles: PaginatedList<PiSituationProfessionnelle> = new PaginatedList<PiSituationProfessionnelle>();
  typesVisite: PaginatedList<TypeVisite> = new PaginatedList<TypeVisite>();
  detailsTypeVisite: PaginatedList<DetailTypeVisite> = new PaginatedList<DetailTypeVisite>();
  precisionTypeVisite: PaginatedList<PrecisionNatureTypeVisite> = new PaginatedList<PrecisionNatureTypeVisite>();
  typeSuivi: PaginatedList<TypeSuivi> = new PaginatedList<TypeSuivi>();

  informationSanteUpdateModel: UpdateInformationSanteModel | null = {
    typeSuivi: undefined
  }; 

  currentInformationSante: InformationSante | null = null; 

  async created() {
    await this.searchSituationProfessionnelles(null);
    await this.searchOrigines(null);
    await this.searchTypesVisite(null);
    await this.searchTypesSuivi(null);
    await this.searchDetailsTypeVisite(null);
    await this.loadInformationSanteDossier();
    this.clearModel = JSON.parse(JSON.stringify(this.generalModel));
  }

  @Watch("generalModel.typeVisite")
  changeTypeVisite() {
    this.$emit("changeTypeVisite");
  }

  getClearModel(){
    return this.clearModel;
  }

  async createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.generalModel));
    this.clearModel = fakeClone;    
    await this.loadInformationSanteDossier();
  }


  getFormattedData() {
    return this.generalModel;
  }

  formatTypeSuiviModel(value: TypeSuivi) {
    return this.informationSanteUpdateModel!.typeSuiviId = value.id;
  }

  async getListDetailsTypeVisite(typeVisite: TypeVisite) {
    this.listOfDetails = [];
    this.precisionTypeVisite.items = [];
    this.generalModel.typeVisiteId = typeVisite.id;

    if (this.generalModel)
      this.generalModel.detailTypeVisiteId = null;

    await this.searchDetailsTypeVisite(null);
    
    this.refreshIndex++;
    this.listOfDetails = this.detailsTypeVisite.items.filter((x: DetailTypeVisite) => x.typeVisiteId == typeVisite.id)
    this.listIsLoad = true;
    return;
  }

  async loadPrecisionNatureTypeVisite(detailTypeVisite: DetailTypeVisite) {
    if (detailTypeVisite.requirePrecision) {
      await this.searchPrecisionsTypeVisite(null);
      this.precisionTypeVisite.items = this.precisionTypeVisite.items.filter((x: PrecisionNatureTypeVisite) => x.detailTypeVisiteId == detailTypeVisite.id);
    } else {
      this.precisionTypeVisite.items = [];
    }
  }

  async searchTypesVisite(params?: any) {
    if (!params || params?.reset) {
      this.typesVisite = new PaginatedList();
    }

    await this.$http.ressifnet.typesVisite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<TypeVisite>) => {
        this.typesVisite.items = this.typesVisite.items!.concat(response.items.filter(ri => !this.typesVisite.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types de visite",
          });
        });
  }

  async searchTypesSuivi(params?: any) {
    if (!params || params?.reset) {
      this.typeSuivi = new PaginatedList();
    }

    await this.$http.ressifnet.typesSuivi
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<TypeSuivi>) => {
        this.typeSuivi.items = this.typeSuivi.items!.concat(response.items.filter(ri => !this.typeSuivi.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types de suivi",
          });
        });
  }

  async searchDetailsTypeVisite(params?: any) {

    if (!params || params?.reset) {
      this.detailsTypeVisite = new PaginatedList();
    }

    await this.$http.ressifnet.detailsTypeVisite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 100,
        params ?? ""
      )
      .then((response: PaginatedList<DetailTypeVisite>) => {
        this.detailsTypeVisite.items = this.detailsTypeVisite.items!.concat(response.items.filter(ri => !this.detailsTypeVisite.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des details sur les type de visite",
          });
        });
  }

  async searchPrecisionsTypeVisite(params?: any) {
    if (!params || params?.reset) {
      this.precisionTypeVisite = new PaginatedList();
    }

    await this.$http.ressifnet.precisionsNatureTypeVisite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<PrecisionNatureTypeVisite>) => {
        this.precisionTypeVisite.items = this.precisionTypeVisite.items!.concat(response.items.filter(ri => !this.precisionTypeVisite.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des nature de type de visite",
          });
        });
  }

  async searchSituationProfessionnelles(params?: any) {

    if (!params || params?.reset) {
      this.situationProfessionnelles = new PaginatedList();
    }

    await this.$http.ressifnet.piSituationProfessionnelles
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 100,
        params?.search ?? ""
      )
      .then((response: PaginatedList<any>) => {
        this.situationProfessionnelles.items = this.situationProfessionnelles.items!.concat(response.items.filter(ri => !this.situationProfessionnelles.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des situations professionnelles",
          });
        });
  }

  async searchOrigines(params?: any) {

    if (!params || params?.reset) {
      this.origines = new PaginatedList();
    }

    await this.$http.ressifnet.origines
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Origine>) => {
        this.origines.items = this.origines.items!.concat(response.items.filter(ri => !this.origines.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des origines",
          });
        });
  }

  async loadInformationSanteDossier() {
    await this.$http.ressifnet.informationsSante.get(this.$route.params.id).then(
      (response: InformationSante) => {
        this.currentInformationSante = response;
        this.informationSanteUpdateModel!.typeSuivi = response.typeSuivi;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des Informations de santé",
        });
      }
    );
  }

  async updateInformationsSante(){
    this.$bvModal.hide('typeSuiviModal');
    await this.$http.ressifnet.informationsSante.put(this.$route.params.id, this.currentInformationSante!.id, this.informationSanteUpdateModel)
      .then(async (response: any) => {
        successAlert.fire({
          title: 'Modifications des informations',
          text: 'Modification effectuée avec succès',
        })
        this.currentInformationSante!.typeSuivi = this.informationSanteUpdateModel!.typeSuivi!;
      }, (error: any) => {
        if (error.response.data.errors) {
          errorAlert.fire({
            text: error.response.data.errors[Object.keys(error.response.data.errors)[0]]
          })
        }
        else {
          errorAlert.fire({
            text: error.message,
          })
        }
      });
  }
}


