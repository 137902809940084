


















































































































































































































































































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { ActionCreateModelForm, InterventionCreateModelForm, TypeIntervention } from "@/api/models/options/typeInterventions/typeIntervention";
import { Component, Vue, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts'
import {
  BFormSelect, BFormSelectOption, BFormRadio, BFormCheckbox, BFormFile,BTabs, BTab, BCardText, BProgress, BIconEmojiNeutralFill, BSpinner
} from 'bootstrap-vue'
import { TypeInterlocuteur } from "@/api/models/options/typeInterlocuteurs/typeInterlocuteur";
import { NatureIntervention } from "@/api/models/options/natureInterventions/natureIntervention";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { ModeContact } from "@/api/models/options/modeContacts/modeContact";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { SituationTravail } from "@/api/models/options/situationsTravails/situationTravail";
import { SituationProfessionnelles, StatutSituationProfessionnelle } from "@/api/models/options/situationProfessionnelles/situationProfessionnelle";
import { TypeMiseADisposition } from "@/api/models/options/typesMiseADisposition/typeMiseADisposition";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import { Domaine } from "@/api/models/options/domaines/domaine";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { required } from "@/@core/utils/validations/validations";
import { Tenant } from "@/api/models/options/tenants/tenant";

@Component({
  components : {
    BFormSelect,
    BFormSelectOption,
    SearchableVueSelect,
    BFormRadio,
    BFormCheckbox,
    RessifnetDateInput,
    RessifnetQuillEditor,
    BFormFile,
    BTab,
    BTabs,
    BCardText,
    BProgress,
    ValidationObserver,
    ValidationProvider,
    BSpinner
  }
})
export default class CreateAction extends Vue {
  required = required
  // Attention a laisser car fix le typeInterlocuteur lors de la copie d'une action
  refreshIndex = 1
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))

  loading = true
  startWatchingModel = false

  optionsServices: Tenant[] = [];

  listeNaturesInterventionMHEA = ['Orientation SAS orientation LCCL',
    'Orientation SAS orientation LCCL',
    'Orientation SAS orientation PSYTEL',
    'Orientation SAS']

  statutContrat: StatutSituationProfessionnelle = {
    allowNouvelleDemande: false,
    nouvelleDemandeChecked: false,
    lastSituationTravailId: null
  }

  listSituationTravails: PaginatedList<SituationTravail> = new PaginatedList<SituationTravail>();
  listTypesMiseADisposition: PaginatedList<TypeMiseADisposition> = new PaginatedList<TypeMiseADisposition>();
  listDomaines: PaginatedList<Domaine> = new PaginatedList<Domaine>();
  listSituationProfessionnelles: SituationProfessionnelles[] = [];

  initialInterventionModel: InterventionCreateModelForm = {
    totalCountTypeIntervention: 0,
    listeTypeIntervention: [],
    typeInterventionId: undefined,

    listeNatureInterventionPrincipale: [],
    natureInterventionPrincipaleId: undefined,
    hasNatureInterventionMHEAPrincipalLibelle: false,

    listeNatureInterventionSecondaire: [],
    natureInterventionSecondaireId: undefined,
    hasNatureInterventionMHEASecondaireLibelle: false,

    totalCountModeContact: 0,
    listeModeContact: [],
    modeContactId: undefined,

    totalCountTypeInterlocuteur: 0,
    listeTypeInterlocuteur: [],
    typeInterlocuteurId: undefined,

    sousDomaines: [],
    sousDomainesComplete: [],
    sousDomainesCompleteWithLabels: [],

    duree: null,
    horsVacation: false,
    nomInterlocuteur: undefined,
    contactInterlocuteur: undefined,
    isChecked: false
  }
  model : ActionCreateModelForm = 
  {
    id: undefined,
    createdBy: undefined,
    isMAD: false,
    interventions: JSON.parse(JSON.stringify([this.initialInterventionModel])),
    fichiers: [],
    dateAction : this.dateToday
  }

  checkedSubDomains: any[] = []
  fichiersUploaded = [];
  percentUpload: number = 0;
  
  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  refreshNatureInterventionPrincipalMHEA(intervention: any) {
    var natureIntervention = intervention.listeNatureInterventionPrincipale.find((x: any) => x.id == intervention.natureInterventionPrincipaleId)

    if (this.listeNaturesInterventionMHEA.indexOf(natureIntervention.libelle) !== -1){
      intervention.hasNatureInterventionMHEAPrincipalLibelle = true
    }
    else {
      intervention.hasNatureInterventionMHEAPrincipalLibelle = false
    }
  }

  refreshNatureInterventionSecondaireMHEA(intervention: any) {
    var natureIntervention = intervention.listeNatureInterventionSecondaire.find((x: any) => x.id == intervention.natureInterventionSecondaireId)

    if (natureIntervention != null){
      if (this.listeNaturesInterventionMHEA.indexOf(natureIntervention.libelle) !== -1){
        intervention.hasNatureInterventionMHEASecondaireLibelle = true
      }
      else {
        intervention.hasNatureInterventionMHEASecondaireLibelle = false
      }
    }
  }

  get canEdit() {
    return !this.model.id || this.model.createdBy === this.$store.state.user.user.uid
  }

  async created() {
    await Promise.all([
      this.loadListSituationTravails(),
      this.loadListSituationProfessionnelles(),
      this.loadListTypesMiseADisposition(),
      this.loadDomainesAndSousdomaines(),
      this.loadTenant()
    ])
    this.loading = false;
  }

  @Watch('model', { deep: true })
  createActionUpdated(old: any, ne: any) {
    if (this.startWatchingModel)
      this.$emit('create-action-updated', true)
  }

  async loadTenant() {    
    await this.$http.ressifnet.tenants
      .paginatedList(1, 50)
      .then((res: PaginatedList<Tenant>) => {
        this.optionsServices = res.items
      })
  }
  
  async isCheckedChange(item: InterventionCreateModelForm){
    if(item.isChecked) {
      item.typeInterlocuteurId = ''
      await this.searchTypeInterlocuteur(null, item)
    }
    else {
      item.typeInterlocuteurId = ''
      await this.searchTypeInterlocuteur(null, item)
    }
  }

  async updateAction(callback: any){
    var hasNoSubDomains = this.model.interventions.some(i => i.sousDomainesComplete.length == 0);

    await (this.$refs['formValidation'] as any)
      .validate()
      .then(async (result: boolean) => {
        if (!result) {
            this.$swal({
              title: 'Problème dans la saisie',
              text: 'Le formulaire n\'est pas complet',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
      } 
      else if (hasNoSubDomains && !this.model.isMAD){
        this.$swal({
          title: 'Problème dans la saisie',
          text: 'Merci de saisir un sous domaine au minimum',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
          buttonsStyling: false,
        })
      }
      else {
        this.loading= true;

          let config = {
            onUploadProgress(progressEvent: any) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              callback(percentCompleted);
              return percentCompleted;
            },
          };

        this.model.interventions.forEach((inter: any) => {
          inter.sousDomaines = inter.sousDomainesComplete
        })

      await this.$http.ressifnet.actions.put(this.$route.params.id, this.model.id, this.model.situationProfessionnelleId, this.model)
        .then(
          (response: string ) => {
            this.$emit("action-added");
            this.$emit('unselectDossier');
            // this.$emit('reloadDossier');
            this.resetModel()
            this.fichiersUploaded =  []
            successAlert.fire({
              title: "Mise à jour d'une action",
              text: "Mise à jour effectuée avec succès"
            });
            this.$emit('create-action-updated', false)
            this.startWatchingModel = false
          },
          (error: any) => {
            errorAlert.fire({
              title:
                "Erreur lors du chargement de l'action",
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          }
        );
        this.loading= false;
      }
      })
  }

  resetModel(){
    this.initialInterventionModel = {
    totalCountTypeIntervention: 0,
    listeTypeIntervention: [],
    typeInterventionId: undefined,

    listeNatureInterventionPrincipale: [],
    natureInterventionPrincipaleId: undefined,

    listeNatureInterventionSecondaire: [],
    natureInterventionSecondaireId: undefined,

    totalCountModeContact: 0,
    listeModeContact: [],
    modeContactId: undefined,

    totalCountTypeInterlocuteur: 0,
    listeTypeInterlocuteur: [],
    typeInterlocuteurId: undefined,

    sousDomaines: [],
    sousDomainesComplete: [],
    sousDomainesCompleteWithLabels: [],

    duree: null,
    horsVacation: false,
    nomInterlocuteur: undefined,
    contactInterlocuteur: undefined,
    isChecked: false
    }
    this.model = {
      isMAD: false,
      interventions: JSON.parse(JSON.stringify([this.initialInterventionModel])),
      fichiers: [],
      situationProfessionnelleId: this.listSituationProfessionnelles.length === 1 ? this.listSituationProfessionnelles[0].id : undefined,
      dateAction : (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
    }
    this.fichiersUploaded = []
  }

  async downloadFile(fichier:any) {
    if (!fichier.id)
      return;
    else {
      await this.$http.ressifnet.fichiersDossiers
        .downloadFile(fichier.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
    }
  }
  
  async contratChanged() {
    if (this.model.situationProfessionnelleId) {
      await this.$http.ressifnet.situationProfessionnelles.getStatut(this.$route.params.id, this.model.situationProfessionnelleId)
      .then(
        (response: StatutSituationProfessionnelle) => {
          this.statutContrat = response;
          this.model.nouvelleDemande = response.nouvelleDemandeChecked

          if (response.lastSituationTravailId)
            this.model.situationTravailId = response.lastSituationTravailId;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des détails du contrat",
          });
        })

        this.model.interventions.forEach((inter) => {
          this.searchNatureInterventionPrincipale(null, inter)
          this.searchNatureInterventionSecondaire(null, inter)
        })
    }
  }

  async createAction(callback: any) {
    var hasNoSubDomains = this.model.interventions.some(i => i.sousDomainesComplete.length == 0);

    await (this.$refs['formValidation'] as any)
      .validate()
      .then(async (result: boolean) => {
        if (hasNoSubDomains && !this.model.isMAD){
          this.$swal({
          title: 'Problème dans la saisie',
          text: 'Merci de saisir un sous domaine au minimum',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-warning',
          },
          buttonsStyling: false,
          })
        }
        else if (!result) {
            this.$swal({
              title: 'Problème dans la saisie',
              text: 'Le formulaire n\'est pas complet',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
        }
        else
        {
          this.loading = true;
          let config = {
            onUploadProgress(progressEvent: any) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              callback(percentCompleted);
              return percentCompleted;
            },
          };

          this.model.interventions.forEach((inter: any) => {
            inter.sousDomaines = inter.sousDomainesComplete
          })
          await this.$http.ressifnet.actions
            .post(this.$route.params.id, this.model, config)
            .then(
              (response: string) => {
                this.$emit("action-added");
                this.$emit('unselectDossier');
                // this.$emit('reloadDossier');
                this.percentUpload = 0
                successAlert.fire({
                  title: "Création d'une action",
                  text: "Ajout effectué avec succès",
                });
                this.resetModel()
                this.$emit('create-action-updated', false)
                this.startWatchingModel = false
              },
              (error: any) => {
                this.percentUpload = 0
                if (
                  error?.response?.data?.errors &&
                  Object.keys(error.response.data.errors).length
                ) {
                  errorAlert.fire({
                    text: error.response.data.errors[
                      Object.keys(error.response.data.errors)[0]
                    ],
                  });
                } else {
                  errorAlert.fire({
                    text: error?.response?.data?.detail ?? error.message,
                  });
                }
              }
            );
            this.loading = false;
        }
      })
  }

  async loadDomainesAndSousdomaines() {
     await this.$http.ressifnet.domaines.paginatedList(1, 50, "", true, true)
      .then(
        (response: PaginatedList<Domaine>) => {
          this.listDomaines = response;
        },
        (error: any) => {
          errorAlert.fire({
            title:
              "Erreur lors du chargement des domaines",
          });
        }
      );
  }

  async createFromCopy(actionId: string){
    this.startWatchingModel = false;

    if (actionId == null || actionId == '0') {
      this.resetModel();
      await this.searchTypeIntervention(null, this.model.interventions[0])
      this.contratChanged()
    }
    else {
    this.loading = true
     await this.$http.ressifnet.actions.getById(this.$route.params.id, actionId)
      .then(
        (response: any ) => {
           this.model = {
            id: undefined,
            createdBy: response.createdBy,
            interventions: [],
            commentaire: response.commentaire,
            dateAction: response.dateAction, 
            situationProfessionnelleId: response.contratId,
            situationTravailId: response.situationTravailId,
            fichiers: [],
            isMAD: false,
            dateSaisie: response.dateSaisie,
            dateModification: response.dateModification,
            nouvelleDemande: false,
            allowNouvelleDemande: response.allowNouvelleDemande
          }
          if (response.typeMiseADispositionId != null) {
            this.model.isMAD = true
            this.model.typeMiseADispositionId = response.typeMiseADispositionId
          }

          response.interventions.forEach((res: any) => {
            this.model.interventions.push(JSON.parse(JSON.stringify(res)))
          })

          this.model.interventions.forEach(async (formIntervention: InterventionCreateModelForm, index) => {
            formIntervention.totalCountTypeIntervention = 0
            formIntervention.listeTypeIntervention= []
            formIntervention.listeTypeInterlocuteur= []
            
            await this.searchTypeIntervention(null, formIntervention)
            formIntervention.typeInterventionId = response.interventions[index].typeInterventionId

            await this.searchNatureInterventionPrincipale(null, formIntervention)
            await this.searchNatureInterventionSecondaire(null, formIntervention)
            await this.searchModeContacts(null, formIntervention)
            
            formIntervention.isChecked = response.interventions[index].typeInterlocuteurExterne;
            await this.searchTypeInterlocuteur(null, formIntervention)
            
            formIntervention.sousDomainesCompleteWithLabels = response.interventions[index].sousDomaines
            formIntervention.sousDomainesComplete = response.interventions[index].sousDomaines.map((x: any) => x.id)
            
            this.refreshIndex++
          })
          this.loading = false
          this.fichiersUploaded = response.fichiers
        },
        (error: any) => {
          errorAlert.fire({
            title:
              "Erreur lors du chargement de l'action",
          });
        }
      );
    }
    
    setTimeout(() => {
      let formCard = (this.$refs['formCard'] as any);
      formCard.scrollIntoView({ behavior: 'smooth' })
    }, 350)

    
    setTimeout(() => {
      this.startWatchingModel = true;
    }, 1500)
  }

  askClearModel() {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir annuler la création de cette action ?",
        {
          title: "Confirmation d'annulation",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.clearModel();
        } else {
          return;
        }
      });
  }

  clearModel() {
    this.$emit('unselectDossier')
  }

  selectSubDomain(checkedValues: any, intervention: any, ssDomain: any, domaine: any) {
    if (checkedValues.some((x: any) => x == ssDomain.id)) {
      intervention.sousDomainesCompleteWithLabels.push({id: ssDomain.id, domaine: domaine.libelle, libelle: ssDomain.libelle})
    }
    else {
      intervention.sousDomainesCompleteWithLabels = intervention.sousDomainesCompleteWithLabels.filter((x: any) => x.id != ssDomain.id)
    }
    
    this.refreshIndex++
  }

  async loadListSituationProfessionnelles() {
    await this.$http.ressifnet.situationProfessionnelles.get(this.$route.params.id).then(
      async (response: SituationProfessionnelles[]) => {
        this.listSituationProfessionnelles = response.map((x: SituationProfessionnelles) => <SituationProfessionnelles>{
            ...x,
            completeLabel: `${x.entrepriseLibelle} (${x.siteLibelle})`
        })
        if (this.listSituationProfessionnelles.length === 1) {
          this.model.situationProfessionnelleId = this.listSituationProfessionnelles[0].id
        }
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des contrats",
        });
      }
    );
  }

  async loadListTypesMiseADisposition() {
      await this.$http.ressifnet.typesMiseADisposition.paginatedList(1, 100, true).then(
        (response: PaginatedList<TypeMiseADisposition>) => {
          this.listTypesMiseADisposition = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types mise à disposition",
          });
        }
      );
  }

  async loadListSituationTravails() {
      await this.$http.ressifnet.situationTravails.paginatedList(1, 100, true).then(
        (response: PaginatedList<SituationTravail>) => {
          this.listSituationTravails = response;
          this.model.situationTravailId = this.listSituationTravails.items.length === 1 ? this.listSituationTravails.items[0].id : this.model.situationTravailId
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des situations professionnelles",
          });
        }
      );
  }

  async searchTypeIntervention(params: any, item: InterventionCreateModelForm ) {
    if (params?.reset) {
      item.listeTypeIntervention = item.listeTypeIntervention?.filter(e => e.id == item.typeInterventionId);
      item.totalCountTypeIntervention = item.listeTypeIntervention?.length;
    }
    
    await this.$http.ressifnet.typeInterventions.paginatedList(1, 100, params?.search, true)
      .then((res: PaginatedList<TypeIntervention>) => {
        item.totalCountTypeIntervention = res.totalCount
        if(res.items.length === 1) {
          item.typeInterventionId = res.items[0].id
          let intervention = this.model.interventions.find(x => x.typeInterventionId === item.typeInterventionId)
          this.searchNatureInterventionPrincipale(null, intervention)
          this.searchNatureInterventionSecondaire(null, intervention)
          this.searchModeContacts(null, intervention)
          this.searchTypeInterlocuteur(null, intervention)
        }
        item.listeTypeIntervention = item.listeTypeIntervention!.concat(res.items.filter(ri => !item.listeTypeIntervention!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  async searchNatureInterventionPrincipale(params: any, item: InterventionCreateModelForm) {
    if (!params || params?.reset)
      item.listeNatureInterventionPrincipale = []

    let sitPro = this.listSituationProfessionnelles.filter(x => x.id == this.model.situationProfessionnelleId)[0]
    await this.$http.ressifnet.natureInterventions
      .list(item.typeInterventionId, true, '', true, sitPro?.entrepriseId ?? null)
      .then((res: NatureIntervention[]) => {
        item.listeNatureInterventionPrincipale = res
        item.natureInterventionPrincipaleId = res.length === 1 ? res[0].id : item.natureInterventionPrincipaleId
      })
  }

  async searchNatureInterventionSecondaire(params: any, item: InterventionCreateModelForm) {
    if (!params || params?.reset)
      item.listeNatureInterventionSecondaire = []


    let sitPro = this.listSituationProfessionnelles.filter(x => x.id == this.model.situationProfessionnelleId)[0]
    await this.$http.ressifnet.natureInterventions
      .list(item.typeInterventionId, false, '', true, sitPro?.entrepriseId ?? null)
      .then((res: NatureIntervention[]) => {
        item.listeNatureInterventionSecondaire = res
      }) 
  }

  async searchModeContacts(params: any, item: InterventionCreateModelForm) {
    if (!params || params?.reset)
      item.listeModeContact = []

    await this.$http.ressifnet.modeContacts
      .paginatedList(item.typeInterventionId, 1, 100, null, true)
      .then((res: PaginatedList<ModeContact>) => {
        item.totalCountModeContact = res.totalCount
        item.modeContactId = res.items.length === 1 ? res.items[0].id : item.modeContactId
        item.listeModeContact = item.listeModeContact!.concat(res.items.filter(ri => !item.listeModeContact!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  async searchTypeInterlocuteur(params: any, item: InterventionCreateModelForm) {
    if (!params || params?.reset){
      item.listeTypeInterlocuteur = []
    }

    await this.$http.ressifnet.typeInterlocuteurs
      .paginatedList(item.typeInterventionId, item.isChecked, 1, 100)
      .then((res: PaginatedList<TypeInterlocuteur>) => {
        item.totalCountTypeInterlocuteur = res.totalCount
        item.typeInterlocuteurId = res.items.length == 1 ? res.items[0].id : item.typeInterlocuteurId
        this.$set(item, 'listeTypeInterlocuteur', res.items)
        this.$forceUpdate()
        this.refreshIndex++
      }) 
  }

  onClearTypeIntervention(item: InterventionCreateModelForm) {
    item.natureInterventionPrincipaleId = undefined
    item.listeNatureInterventionPrincipale = []
    item.hasNatureInterventionMHEAPrincipalLibelle = false
    item.natureInterventionSecondaireId = undefined
    item.listeNatureInterventionSecondaire = []
    item.hasNatureInterventionMHEASecondaireLibelle = false
    item.modeContactId = undefined
    item.listeModeContact = []
    item.duree = null
    item.horsVacation = false 
    item.isChecked = false
    item.typeInterlocuteurId = undefined
    item.listeTypeInterlocuteur = []
    item.nomInterlocuteur = undefined
    item.contactInterlocuteur = undefined
  }

  onClearNatureInterventionPrincipal(item: InterventionCreateModelForm){
    item.hasNatureInterventionMHEAPrincipalLibelle = false
  }

  onClearNatureInterventionSecondaire(item: InterventionCreateModelForm){
    item.hasNatureInterventionMHEASecondaireLibelle = false
  }

  onTypeInterventionSelected(val: any, item: InterventionCreateModelForm) {
    if (val) {
      const site = item.listeTypeIntervention?.filter(s => s.id == val)[0]
      this.onClearTypeIntervention(item)
    }
  }

  deleteItem(index: number) {
    this.model.interventions.splice(index, 1);
  }

  addIntervention(){
    this.model.interventions.push(JSON.parse(JSON.stringify(this.initialInterventionModel)))
  }
  
  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de l'action collective ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.model.fichiers!.splice(
        (this.model.fichiers! as any[]).indexOf(fichier),
        1
      );
    }
  }

  unselectSubdomain(intervention: any, selectedSSDomaine: any) {
    intervention.sousDomainesCompleteWithLabels = intervention.sousDomainesCompleteWithLabels.filter((x: any) => x.id != selectedSSDomaine.id)
    intervention.sousDomainesComplete = intervention.sousDomainesComplete.filter((x: any) => x != selectedSSDomaine.id)
    this.refreshIndex++;
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.actions
      .deleteFile(
        this.$route.params.id,
        (this.model as any).id,
        (this.model as any).situationProfessionnelleId,
        fichier.id
      )
      .then((res: any) => {
        this.$emit("action-added");
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        (this.model.fichiers! as any[]).push({ id: fichier.id });
        this.fichiersUploaded.splice(
          (this.fichiersUploaded as any[]).indexOf(fichier),
          1
        );
      });
  }

  get fichiers() {
    var files = [];

    if (this.model.fichiers && this.model.fichiers.length > 0) {
      files = this.model
        .fichiers.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    
    return files.concat(this.fichiersUploaded);
  }
}
