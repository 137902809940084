
































































































































































import { BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BFormTextarea
} from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { Vaccination, VaccinationUpdateModel } from "@/api/models/dossiers/vaccination"
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from '@/api/models/common/paginatedList';
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { Maladie } from '@/api/models/options/maladies/maladie';
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar, 
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    RessifnetDateInput,
    MultipleFileInput
  },
  name: "update-vaccination"
})
export default class UpdateVaccination extends Vue {
  @Prop({ required: true, type: Boolean }) isUpdateVaccinationSidebarActive!: boolean
  @Prop({ required: true }) vaccinationId!: string;

  loading = false
  refreshIndex = 1

  required = required;
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
  
  vaccination: VaccinationUpdateModel = {
    dossierId: this.$route.params.id,
    date: this.dateToday,
    maladiesId: [],
    fichiers: []
  }
  listMaladies = new PaginatedList<Maladie>()

  fichiersUploaded = [];


  get fichiers() {
    var files = [];

    if (this.vaccination.fichiers && this.vaccination.fichiers.length > 0) {
      files = this.vaccination
        .fichiers
        .filter((f: any) => f.id == undefined)
        .map(
          (x: any) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded);
  }

  resetForm() {
    this.vaccination = {}
  }

  async created() {
    await this.getVaccination();
    await this.loadListMaladies({
      pageNumber: 1,
      pageSize: 1000,
      actifonly: false
    })   
  }

  async getVaccination() {
    await this.$http.ressifnet.vaccinations.get(this.$route.params.id, this.vaccinationId).then(
      (response: Vaccination) => {
        this.vaccination = response
        this.vaccination.maladiesId = response.maladies.map((x: any) => x.id)
        this.fichiersUploaded = response.fichiers
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des informations de santé",
        });
      }
    );
  }

  async loadListMaladies(params: any) {
    if (!params || params?.reset) {
          this.listMaladies = new PaginatedList();
      }

    await this.$http.ressifnet.maladies.paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 1000, 
        params.actifonly ?? false
      ).then(
      (response: PaginatedList<Maladie>) => {
        this.listMaladies.items = this.listMaladies.items!.concat(response.items.filter(ri => !this.listMaladies.items!.some(lsi => lsi.id == ri.id)))
        this.listMaladies.totalCount = response.totalCount
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des Informations de santé",
        });
      }
    );
  }

  async downloadFile(fichier:any) {
    if (!fichier.id)
      return;
    else {
      await this.$http.ressifnet.fichiersDossiers
        .downloadFile(fichier.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
    }
  }

  getFormatedVaccination(){
    return {
      id: this.vaccinationId,
      dossierId: this.$route.params.id,
      date: this.vaccination.date,
      numLot: this.vaccination.numLot,
      marque: this.vaccination.marque,
      siteInjection: this.vaccination.siteInjection,
      commentaire: this.vaccination.commentaire,
      maladiesId: this.vaccination.maladiesId,
      fichiers: this.vaccination.fichiers
    }
  }

  removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de la vaccination ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.vaccination.fichiers!.splice(
        (this.vaccination.fichiers! as any[]).indexOf(fichier),
        1
      );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.vaccinations
      .deleteFile(
        this.$route.params.id,
        (this.vaccination as any).id,
        fichier.id
      )
      .then((res: any) => {
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        this.fichiersUploaded.splice(
          (this.fichiersUploaded as any[]).indexOf(fichier),
          1
        );
      });
  }

  async submit() {
    this.loading = true
    var model = this.getFormatedVaccination();
    await this.$http.ressifnet.vaccinations.put(this.$route.params.id, this.vaccinationId, model)
      .then(
        async (response: any) => {
            successAlert.fire({
              timer: 3500,
              title: "Modification d'une vaccination",
              text: "Vaccination modifié avec succès",
            });
          },     
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit('refetch-data')
        this.$emit('update:is-update-vaccination-sidebar-active', false)
        this.loading = false
      });
  } 
}
