









































































































































  import { BSidebar,
    BForm,
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormRadio,
    BDropdown,
    BFormGroup,
    BDropdownItem,
    BFormInput,
    BPagination,
    BRow,
    BTable,
    BSpinner,
    BFormTextarea,
    BFormFile
  } from 'bootstrap-vue';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
  import { PaginatedList } from '@/api/models/common/paginatedList';
  import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
  import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { TypeHabilitation } from '@/api/models/options/typeHabilitations/typeHabilitation';
import { Habilitation, HabilitationUpdateModel } from '@/api/models/options/habilitations/habilitation';
import { TypeHabilitationEnum } from '@/api/models/enums/typeHabilitations';
  
  
  @Component({
    components: {
      BCard,
      BRow,
      BCol,
      BButton,
      BFormInput,
      BTable,
      BPagination,
      BDropdown,
      BDropdownItem,
      BSidebar, 
      BForm,
      BFormGroup,
      BFormCheckbox,
      BFormRadio,
      BSpinner,
      BFormTextarea,
      BFormFile,
      ValidationProvider,
      ValidationObserver,
      SearchableVueSelect,
      RessifnetDateInput
    },
    name: "update-habilitation"
  })
  export default class UpdateHabilitation extends Vue {
    @Prop({ required: true, type: Boolean }) isUpdateHabilitationSidebarActive!: boolean
    @Prop({ required: true }) habilitationId!: string;
  
    loading = false
  
    required = required;

    consigneStatus : boolean = false;
    consigneTransmissionsDate : any = '';

    typeHabilitationEnum = TypeHabilitationEnum
    
    habilitation: HabilitationUpdateModel = {
      dossierId: this.$route.params.id,
      id: this.habilitationId
    }
    listTypeHabilitations = new PaginatedList<TypeHabilitation>()
  
    resetForm() {
      this.habilitation = {}
    }
  
    async created() {
      await this.getHabilitation();
      await this.loadListTypeHabilitations({
        pageNumber: 1,
        pageSize: 50,
        actifonly: false
      })   
    }
  
    async getHabilitation() {
      await this.$http.ressifnet.habilitations.get(this.$route.params.id, this.habilitationId).then(
        (response: Habilitation) => {
          this.habilitation = response;
          this.consigneStatus = !this.habilitation.consigneEcheanceHabilitationActivee;
          if(!this.habilitation.dateFin){
            this.consigneTransmissionsDate = '';
          }else {
            this.consigneTransmissionsDate = this.habilitation.dateConsigneEcheanceHabilitation ? this.habilitation.dateConsigneEcheanceHabilitation : this.getFormatedConsigneDate()
          }
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des habilitations",
          });
        }
      );
    }
  
    async loadListTypeHabilitations(params: any) {
      if (!params || params?.reset) {
          this.listTypeHabilitations = new PaginatedList();
      }
  
      await this.$http.ressifnet.typeHabilitations.paginatedList(
          params?.pageNumber ?? 1,
          params?.pageSize ?? 50, 
          params.actifonly ?? false
        ).then(
        (response: PaginatedList<TypeHabilitation>) => {
          this.listTypeHabilitations = response
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types d'habilitation",
          });
        }
      );
    }
  
    getFormatedHabilitation(){
      this.habilitation.consigneEcheanceHabilitationActivee = !this.consigneStatus;
      this.habilitation.dateConsigneEcheanceHabilitation = (this.consigneTransmissionsDate != this.getFormatedConsigneDate()) ? this.consigneTransmissionsDate : undefined;
      return {
        id: this.habilitation.id,
        dossierId: this.$route.params.id,
        dateHabilitation: this.habilitation.dateHabilitation,
        dateFin: this.habilitation.dateFin,
        typeHabilitationId: this.habilitation.typeHabilitationId,
        typeHabilitationCustom: this.habilitation.typeHabilitationCustom,
        consigneEcheanceHabilitationActivee : this.habilitation.consigneEcheanceHabilitationActivee,
        dateConsigneEcheanceHabilitation : this.habilitation.dateConsigneEcheanceHabilitation
      }
    }
  
    async submit() {
      this.loading = true
      var model = this.getFormatedHabilitation();
      await this.$http.ressifnet.habilitations.put(this.$route.params.id, this.habilitationId, model)
        .then(
          async (response: any) => {
              successAlert.fire({
                timer: 3500,
                title: "Modification d'une habilitation",
                text: "habilitation modifié avec succès",
              });
            },     
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        )
        .finally(() => {
          this.$emit('refetch-data')
          this.$emit('update:is-update-habilitation-sidebar-active', false)
          this.loading = false
        });
    } 

    @Watch("habilitation.dateFin")
      getEcheanceDate(){
        if(this.habilitation.dateFin && this.habilitation.dateFin != null){
          this.consigneTransmissionsDate = this.getFormatedConsigneDate()
        }
      }

    getFormatedConsigneDate(){
    var dateString = this.habilitation.dateFin!;
    var parts = dateString.split('/');
    var formattedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];
    var date = new Date(formattedDateString);
     date.setMonth(date.getMonth() - 2);
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var jourStr = jour < 10 ? '0' + jour : jour.toString();
    var moisStr = mois < 10 ? '0' + mois : mois.toString();
    var anneeStr = annee.toString();
    var dateResultat = jourStr + '/' + moisStr + '/' + anneeStr;
    return dateResultat;
  }


  }
  