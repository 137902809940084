
































































import { Component, Vue } from "vue-property-decorator";
import DetailsDemandeSurendettement from "./_DetailsDemandeSurendettement.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DemandeSurendettement } from "@/api/models/options/demandesSurendettement/demandeSurendettement";
import { VBPopover, BSpinner } from "bootstrap-vue";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    DetailsDemandeSurendettement,
    BSpinner,
    DeleteIcon,
    EditIcon
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "dossier-demandes-surendettement"
})
export default class DossierDemandesSurendettement extends Vue {
  listDemandeSurendettement: PaginatedList<DemandeSurendettement> = new PaginatedList<DemandeSurendettement>()

  displaySurendettementDetails = false
  currentDemandeId: string | null = null
  loading: boolean = true

  async created() {
    await this.loadListDemandesSurendettement();
  }

  async loadListDemandesSurendettement() {
    await this.$http.ressifnet.demandesSurendettement.paginatedList(this.$route.params.id, 1, 50)
      .then(
        (response: PaginatedList<DemandeSurendettement>) => {
          this.listDemandeSurendettement = response
          this.loading = false
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des demandes de surendettement'
          })
        }
      )
  }

  async askDeleteDemandeSurendettement(id: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la demande de surendettement ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value === true){
          await this.confirmDeleteDemandeSurendettement(id)
        }
        else {
          return;
        }
      })
  }

  async confirmDeleteDemandeSurendettement(id: string) {
    await this.$http.ressifnet.demandesSurendettement
      .delete(this.$route.params.id, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une demande de surendettement",
            text: "Suppression effectuée avec succès",
          });
          this.loadListDemandesSurendettement();
          this.currentDemandeId = null
        }
      )
  }

  async askUpdateDemandeSurendettement(id: string){
    this.currentDemandeId = id;
    this.displaySurendettementDetails = true;
  }

  addDemandeSurendettement() {
    this.currentDemandeId = null;
    this.displaySurendettementDetails = true;
  }

}
