















































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BPagination,
  BFormTextarea,
  VBPopover,
  BFormGroup,
  BForm,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, min, max } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  InformationSante,
  MaladieProfessionnelle,
  UpdateInformationSanteModel,
} from "@/api/models/dossiers/informationSante";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Vaccination } from "@/api/models/dossiers/vaccination";
import CreateVaccination from "./_CreateVaccination.vue";
import UpdateVaccination from "./_UpdateVaccination.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { TypeConsommationEnum } from "@/api/models/enums/typeConsommationEnum";
import { TypeSuivi } from "@/api/models/options/typesSuivi/typeSuivi";


@Component({
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BCardText,
    BAlert,
    BTable,
    BCol,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    CreateVaccination,
    UpdateVaccination,
    RessifnetQuillEditor,
    SearchableVueSelect,
    BFormTextarea,
    BFormGroup,
    BForm,
    FeatherIcon,
    BFormRadio,
    BFormRadioGroup
  },
  directives: {
    "b-popover": VBPopover,
  },
})
export default class DetailsInformationSante extends Vue {
  required = required;
  min = min;
  max = max;

  optionsType = TypeConsommationEnum;

  allergie = "";
  // listAllergies: string[] = []
  id = "";

  refreshIndex = 0;

  listeMaladiesPro: MaladieProfessionnelle[] = [];
  totalCountMaladiesPro: number = 0;
  showCompleteSearchMaladiePro = false;
  searchIsAgricole?: boolean;

  informationSante: UpdateInformationSanteModel = {};  
  clearModel: UpdateInformationSanteModel = {};

  maladieProIdToAdd: string = "";
  maladieProIdToAdd2: string = "";

  currentPage: any = 1;
  perPage: any = 5;

  isSortDirDesc = false;
  sortBy = "date";

  isAddNewVaccinationSidebarActive = false;
  isUpdateVaccinationSidebarActive = false;
  showMaladie = true;

  tableColumns = [
    { key: 'date', sortable: false, disable: false,formatter: (value:any) => {
              return value.toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' })
            } },
    { key: 'maladies', label: "Maladies cible(s)", sortable: false, disable: false },
    { key: 'numLot', label: "N° de Lot", sortable: false, disable: false },
    { key: 'marque', label: "Marque", sortable: false, disable: false },
    { key: 'siteInjection', label: "Site d'injection", sortable: false, disable: false },
    { key: 'details', sortable: false, disable: false },
    { key: 'actions', sortable: false, disable: false }
  ];

  typesSuivi: PaginatedList<TypeSuivi> = new PaginatedList<TypeSuivi>();

  listVaccins: PaginatedList<Vaccination> = new PaginatedList<Vaccination>();

  selectedVaccinationId: any;

  async created() {
    await this.loadListInformationsSante();
    await this.searchTypesSuivi();
    await this.loadListVaccination({
      pageNumber: this.currentPage,
      pageSize: this.perPage
    }),
    this.clearModel = JSON.parse(JSON.stringify(this.informationSante));
  } 

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.informationSante));
    this.clearModel = fakeClone;    
  }

  @Watch("currentPage")
  async currentPageChange() {
    await this.loadListVaccination({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectVaccination(vaccinationId: string) {
    this.selectedVaccinationId = vaccinationId;
    this.isUpdateVaccinationSidebarActive = true;
    
  }

  clearMaladiePro(){
    this.maladieProIdToAdd = '';
    this.maladieProIdToAdd2 = '';
    this.searchIsAgricole = undefined;
    this.showCompleteSearchMaladiePro = false;
  }

  async loadListVaccination(params: any) {
    await this.$http.ressifnet.vaccinations
      .paginatedList(this.$route.params.id, params.pageNumber, params.pageSize)
      .then(
        (response: PaginatedList<Vaccination>) => {
          this.listVaccins = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des vaccinations",
          });
        }
      );
  }

  async getListMaladiePro(){
    this.showMaladie = false;
    this.listeMaladiesPro = [];
    await this.searchMaladiesProfessionnelles(null).then(() => {
      this.showMaladie = true;
    })
  }

  async searchMaladiesProfessionnelles(params: any) {
    if (params?.reset)
      this.listeMaladiesPro = this.listeMaladiesPro?.filter(
        (e) => e.id == this.maladieProIdToAdd
      );

    await this.$http.ressifnet.maladiesProfessionnelles
      .paginatedList(
        params?.pageNumber,
        params?.pageSize,
        params?.search,
        this.searchIsAgricole
      )
      .then((res: PaginatedList<MaladieProfessionnelle>) => {
        this.totalCountMaladiesPro = res.totalCount;
        this.listeMaladiesPro = this.listeMaladiesPro!.concat(
          res.items.filter(
            (ri) => !this.informationSante.maladiesProfessionnelles!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async loadListInformationsSante() {
    await this.$http.ressifnet.informationsSante
      .get(this.$route.params.id)
      .then(
        (response: InformationSante) => {
          // this.informationSante.allergies = response.allergies != null ? response.allergies : []
          // this.informationSante.id = response.id;
          this.informationSante = response;          
          this.clearModel = {...this.informationSante};
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des Informations de santé",
          });
        }
      );
  }

  formatTypeSuiviModel(value: TypeSuivi) {
    return this.informationSante.typeSuiviId = value.id;
  }

  async searchTypesSuivi(params?: any) {
    if (!params || params?.reset) {
      this.typesSuivi = new PaginatedList();
    }

    await this.$http.ressifnet.typesSuivi
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<TypeSuivi>) => {
        this.typesSuivi.items = this.typesSuivi.items!.concat(response.items.filter(ri => !this.typesSuivi.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types de suivi",
          });
        });
  }

  deleteVaccination(vaccinationId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer la vaccination ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(vaccinationId);
        } else {
          return;
        }
      });
  }

  async confirmDelete(vaccinationId: string) {
    await this.$http.ressifnet.vaccinations
      .delete(this.$route.params.id, vaccinationId)
      .then(
        (response: any) => {
          successAlert.fire({
            title: "Suppression d'une vaccination",
            text: "Suppression effectuée avec succès",
          });
          this.loadListVaccination({
            pageNumber: this.currentPage,
            pageSize: this.perPage,
          });
          this.$emit("reload-etat-dossier");
          this.$emit("silent-reload");
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async addAllergie() {
    this.informationSante.allergies?.push(this.allergie);
    this.allergie = "";
    await this.$http.ressifnet.informationsSante
      .put(
        this.$route.params.id,
        this.informationSante.id,
        this.informationSante
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Ajout d'une allergie",
            text: "Ajout effectuée avec succès",
          });
          this.$emit("reload-etat-dossier");
          this.loadListInformationsSante();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async silentUpdate(){
     await this.$http.ressifnet.informationsSante
      .put(
        this.$route.params.id,
        this.informationSante.id,
        this.informationSante
      )
      .then(
        async (response: any) => {
         
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async updateInformationsSante() {
    await this.$http.ressifnet.informationsSante
      .put(
        this.$route.params.id,
        this.informationSante.id,
        this.informationSante
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Modifications des informations",
            text: "Modification effectuée avec succès",
          });
          this.$emit("reload-etat-dossier");
          this.$emit("reloadDossier");
          this.loadListInformationsSante();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async askDeleteAllergie(itemToDelete: any) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer l'allergie ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.deleteAllergie(itemToDelete);
        } else {
          return;
        }
      });
  }

  async deleteAllergie(itemToDelete: any) {
    this.informationSante.allergies = this.informationSante.allergies?.filter(
      (x) => x != itemToDelete
    );
    await this.$http.ressifnet.informationsSante
      .put(
        this.$route.params.id,
        this.informationSante.id,
        this.informationSante
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Suppression d'une allergie",
            text: "Suppression effectuée avec succès",
          });
          this.$emit("reload-etat-dossier");
          this.loadListInformationsSante();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async askDeleteMaladieProfessionnelle(itemToDelete: MaladieProfessionnelle) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer la maladie professionnelle (" +
          itemToDelete.code +
          ") ?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.deleteMaladieProfessionnelle(itemToDelete);
        } else {
          return;
        }
      });
  }

  async deleteMaladieProfessionnelle(itemToDelete: MaladieProfessionnelle) {
    this.informationSante.maladiesProfessionnelles =
      this.informationSante.maladiesProfessionnelles?.filter(
        (x) => x != itemToDelete
      );
    await this.$http.ressifnet.informationsSante
      .deleteMaladieProfessionnelle(
        this.$route.params.id,
        this.informationSante.id,
        itemToDelete.id
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Suppression d'une maladie professionnelle",
            text: "Suppression effectuée avec succès",
          });
          this.$emit("reload-etat-dossier");
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async addMaladieProfessionnelle() {
    await this.$http.ressifnet.informationsSante
      .addMaladieProfessionnelle(
        this.$route.params.id,
        this.informationSante.id,
        this.showCompleteSearchMaladiePro
          ? this.maladieProIdToAdd2
          : this.maladieProIdToAdd
      )
      .then(
        async (response: any) => {
         
          var maladiePro = this.listeMaladiesPro.find(
            (x) =>
              x.id ==
              (this.showCompleteSearchMaladiePro
                ? this.maladieProIdToAdd2
                : this.maladieProIdToAdd)
          );
          this.informationSante.maladiesProfessionnelles?.push(maladiePro!);
          successAlert.fire({
            title: "Ajout d'une maladie professionnelle",
            text: "Ajout effectué avec succès",
          });
          this.clearMaladiePro();
          this.$emit("reload-etat-dossier");
          await this.loadListInformationsSante();
        },
        (error: any) => {
          if (error.response.data.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  askResetModel() {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir annuler la saisie des informations ?",
        {
          title: "Confirmation d'annulation",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          this.resetModel();
        } else {
          return;
        }
      });
  }

  resetModel() {
    this.informationSante = {};
  }
}
