
































































import { Component, Vue, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import PassageInfirmerieBilan from "./passageInfirmerie/_Bilan.vue";
import PassageInfirmerieCommentairesFichiers from "./passageInfirmerie/_CommentairesFichiers.vue";
import PassageInfirmerieDiagnosticInfirmier from "./passageInfirmerie/_DiagnosticInfirmier.vue";
import PassageInfirmerieDouleurs from "./passageInfirmerie/_Douleurs.vue";
import PassageInfirmerieGeneral from "./passageInfirmerie/_General.vue";
import PassageInfirmerieExamensComplementaires from "./passageInfirmerie/_ExamensComplementaires.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BPagination,
  VBPopover,
  BSpinner
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { PassageInfirmerie, UpdatePassageInfirmerieDto } from "@/api/models/dossiers/passageInfirmerie";
import { DouleurPhysique } from "@/api/models/options/douleursPhysiques/douleursPhysiques";
import { RaisonVenue } from "@/api/models/options/rainsonsVenue/raisonVenue";

@Component({
  components: {
    PassageInfirmerieGeneral,
    PassageInfirmerieExamensComplementaires,
    PassageInfirmerieDouleurs,
    PassageInfirmerieDiagnosticInfirmier,
    PassageInfirmerieCommentairesFichiers,
    PassageInfirmerieBilan,
    BFormSelect,
    BFormInput,
    BButton,
    BCardText,
    BAlert,
    BTable,
    BCol,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    RessifnetQuillEditor,
    FeatherIcon,
    FormWizard,
    TabContent,
    BSpinner
  },
  directives: {
    'b-popover': VBPopover
  }
})
export default class DetailsPassageInfirmerie extends Vue {
  required = required;
  min = min;
  max = max;

  newPassageInfirmerie: PassageInfirmerie = {};
  updatePassageInfirmerieModel: UpdatePassageInfirmerieDto = {};

  passageInfirmerieId? : any = undefined;
  raisonNoDiagnostic : RaisonVenue = {libelle:"Pas de diagnostic"};

  currentPage: any = 1;
  perPage: any = 10;

  showStepper = false
  updateDone = true
  listsLoad = false

  nbAllError = 0;

  typeVisite = null;

  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))

  title = {
    text: "Enregistrer un passage à l'infirmerie",
    icon: "PlusIcon"
  };

  editable: boolean = true;

  async mounted() {
    await this.activateAlltab();
    this.calculateTabsMargin();
    (this.$refs['passageInfirmerieGeneral'] as any).allListLoads = true;
  }

  async changeTypeVisite() {
    this.typeVisite = (this.$refs['passageInfirmerieGeneral'] as any).generalModel.typeVisite;
  }

 calculateTabsMargin(){
      var tabs = document.querySelector('.wizard-card-footer.clearfix') as HTMLElement;
      var panel = document.querySelector('.wizard-nav.wizard-nav-pills') as HTMLElement;
      tabs.style.marginLeft = panel.offsetWidth + 'px';
      window.addEventListener('resize', () => {
        tabs.style.marginLeft = panel.offsetWidth + 'px';
      })
    }

  getClearModel(){
    var general = (this.$refs['passageInfirmerieGeneral'] as any).getClearModel();
    var examensComplementaires = (this.$refs['passageInfirmerieExamensComplementaires'] as any).getClearModel();
    var diagnosticInfirmier = (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).getClearModel();
    var douleurs = (this.$refs['passageInfirmerieDouleurs'] as any).getClearModel();
    var bilan = (this.$refs['passageInfirmerieBilan'] as any).getClearModel();
    var commentsFile = (this.$refs['passageInfirmerieCommentairesFichiers'] as any).getClearModel();
    return {...general,...examensComplementaires,...diagnosticInfirmier,...douleurs,...bilan,...commentsFile};
  }

  getModel(){
    var general =  (this.$refs['passageInfirmerieGeneral'] as any).generalModel;
    var examensComplementaires =  (this.$refs['passageInfirmerieExamensComplementaires'] as any).examensComplementairesModel;
    var diagnosticInfirmier =   (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).diagnosticInfirmierModel;
    var douleurs =  (this.$refs['passageInfirmerieDouleurs'] as any).douleursCreateModel;
    var bilan =  (this.$refs['passageInfirmerieBilan'] as any).bilanModel;
    var commentsFile =  (this.$refs['passageInfirmerieCommentairesFichiers'] as any).commentaireEtFichierModel;
    return {...general,...examensComplementaires,...diagnosticInfirmier,...douleurs,...bilan,...commentsFile};
  }


  async activateAlltab(){
    (this.$refs['formSaveDossier'] as any).changeTab(5,4);
    (this.$refs['formSaveDossier'] as any).changeTab(4,3);
    (this.$refs['formSaveDossier'] as any).changeTab(3,2);
    (this.$refs['formSaveDossier'] as any).changeTab(2,1);
    (this.$refs['formSaveDossier'] as any).changeTab(1,0);
    this.showStepper = true
  }

  async resetModel(){
    this.passageInfirmerieId = undefined;
    this.title = {
      text: "Enregistrer un passage à l'infirmerie",
      icon: "PlusIcon"
    };

    (this.$refs['passageInfirmerieGeneral'] as any).generalModel = {};
    (this.$refs['passageInfirmerieGeneral'] as any).listIsLoad = false;

    (this.$refs['passageInfirmerieExamensComplementaires'] as any).examensComplementairesModel = {};
    var examensComplementaires = (this.$refs['passageInfirmerieExamensComplementaires'] as any);
    examensComplementaires.showTaille = false;
    examensComplementaires.showPoid = false;
    examensComplementaires.showTemperature = false;
    examensComplementaires.showAudiogramme = false;
    examensComplementaires.showControleVisuel = false;
    examensComplementaires.showGlycemieCapilaire = false;
    examensComplementaires.showBandeletteUrinaire = false;
    examensComplementaires.showControleTA = false;
    examensComplementaires.showControleVisuel = false;
    examensComplementaires.showTestRespiratoire = false;
    examensComplementaires.showTestCovid = false;
    examensComplementaires.showSaO2 = false;

    (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).diagnosticInfirmierModel = {};

    (this.$refs['passageInfirmerieDouleurs'] as any).douleursModel = {};    

    (this.$refs['passageInfirmerieBilan'] as any).bilanModel = {};

    (this.$refs['passageInfirmerieCommentairesFichiers'] as any).commentaireEtFichierModel = {
      fichiers: []
    };
    (this.$refs['passageInfirmerieCommentairesFichiers'] as any).fichiersUploaded = [];

    var passageInfirmerie = (this.$refs['passageInfirmerieGeneral'] as any)!.$refs['formValidationGeneral']
    var diagnosticInfirmerie = (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any)!.$refs['diagnosticInfirmerieToValidate']
    var examensComplementaires = (this.$refs['passageInfirmerieExamensComplementaires'] as any)!.$refs['examenComplementairesToValidate']
    var douleurs = (this.$refs['passageInfirmerieDouleurs'] as any)!.$refs['formValidationDouleursPsy']
    var bilan = (this.$refs['passageInfirmerieBilan'] as any)!.$refs['formValidationBilan']

    passageInfirmerie.reset();
    diagnosticInfirmerie.reset();
    examensComplementaires.reset();
    douleurs.reset();
    bilan.reset();

  }

  async createFromCopy(passageInfirmerieId: any, editable: boolean){
    
    if(editable) {
      this.title.text = "Modifier un passage à l'infirmerie"
      this.title.icon = "Edit2Icon"
      this.passageInfirmerieId = passageInfirmerieId;
    }
    else {
      this.title.text = "Consulter un passage à l'infirmerie"
      this.title.icon = "EyeIcon"
    }
    await this.$http.ressifnet.passagesInfirmerie.getById(this.$route.params.id, passageInfirmerieId)
      .then(
        (response: any ) => {
          this.editable = editable;

          // General
          (this.$refs['passageInfirmerieGeneral'] as any).generalModel = {
            datePassageInfirmerie: response.datePassageInfirmerie,
            horaire: response.horaire,
            situationProfessionnelleId: response.situationProfessionnelleId,
            typeVisite: response.typeVisite,
            typeVisiteId: response.typeVisiteId,
            precisionNatureTypeVisiteId: response.precisionNatureTypeVisiteId,
            origineId: response.origineId,
            duree: response.duree
          };

          (this.$refs['passageInfirmerieGeneral'] as any).getListDetailsTypeVisite((this.$refs['passageInfirmerieGeneral'] as any).generalModel.typeVisite);
          (this.$refs['passageInfirmerieGeneral'] as any).generalModel.detailTypeVisiteId = response.detailTypeVisiteId; 
          
          if ((this.$refs['passageInfirmerieGeneral'] as any).generalModel.detailTypeVisiteId != null){
            (this.$refs['passageInfirmerieGeneral'] as any).loadPrecisionNatureTypeVisite(response.detailTypeVisite);
          }
          (this.$refs['passageInfirmerieGeneral'] as any).allListLoads = true;

          (this.$refs['passageInfirmerieGeneral'] as any).clearModel = JSON.parse(JSON.stringify((this.$refs['passageInfirmerieGeneral'] as any).generalModel));
          
        // Examen complementaires
          (this.$refs['passageInfirmerieExamensComplementaires'] as any).examensComplementairesModel = {
            taille: response.taille,
            poid: response.poid,
            temperature: response.temperature,
            audiogramme: response.audiogramme,
            prelevementSanguin: response.prelevementSanguin,
            controleVisuel: response.controlVisuel,
            glycemieCapilaire: response.glycemieCapilaire,
            bandeletteUrinaire: response.bandeletteUrinaire,
            controleTA: response.controleTA,
            testRespiratoire: response.testRespiratoire,
            testCovid: response.testCovid,
            sao2: response.saO2,
            autresExamensRealises: response.autresExamensRealises
          };

          var examensComplementaires = (this.$refs['passageInfirmerieExamensComplementaires'] as any);

          examensComplementaires.examensComplementairesModel.taille ? examensComplementaires.showTaille = true :  examensComplementaires.showTaille = false;
          examensComplementaires.examensComplementairesModel.poid ? examensComplementaires.showPoid = true :  examensComplementaires.showPoid = false;
          examensComplementaires.examensComplementairesModel.temperature ? examensComplementaires.showTemperature = true :  examensComplementaires.showTemperature = false;
          examensComplementaires.examensComplementairesModel.audiogramme ? examensComplementaires.showAudiogramme = true :  examensComplementaires.showAudiogramme = false;
          examensComplementaires.examensComplementairesModel.prelevementSanguin ? examensComplementaires.showPrelevementSanguin = true :  examensComplementaires.showControleVisuel = false;
          examensComplementaires.examensComplementairesModel.glycemieCapilaire ? examensComplementaires.showGlycemieCapilaire = true :  examensComplementaires.showGlycemieCapilaire = false;
          examensComplementaires.examensComplementairesModel.bandeletteUrinaire ? examensComplementaires.showBandeletteUrinaire = true :  examensComplementaires.showBandeletteUrinaire = false;
          examensComplementaires.examensComplementairesModel.controleTA ? examensComplementaires.showControleTA = true :  examensComplementaires.showControleTA = false;
          examensComplementaires.examensComplementairesModel.controleVisuel ? examensComplementaires.showControleVisuel = true : examensComplementaires.showControleVisuel = false;
          examensComplementaires.examensComplementairesModel.testRespiratoire ? examensComplementaires.showTestRespiratoire = true :  examensComplementaires.showTestRespiratoire = false;
          examensComplementaires.examensComplementairesModel.testCovid ? examensComplementaires.showTestCovid = true :  examensComplementaires.showTestCovid = false;
          examensComplementaires.examensComplementairesModel.saO2 ? examensComplementaires.showSaO2 = true :  examensComplementaires.showSaO2 = false;

          (this.$refs['passageInfirmerieExamensComplementaires'] as any).clearModel = JSON.parse(JSON.stringify((this.$refs['passageInfirmerieExamensComplementaires'] as any).examensComplementairesModel));

          // Diagnostic infirmier
          if (response.raisonsVenue.length != 0){
            (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).diagnosticInfirmierModel = {
              raisonsVenue: response.raisonsVenue
            };
          }
          else {
            (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).diagnosticInfirmierModel = {
              raisonsVenue: [this.raisonNoDiagnostic],
              precisionsRaisonsVenueId: []
            };
          }

          (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).clearModel = JSON.parse(JSON.stringify((this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).diagnosticInfirmierModel));
                       
          // Douleurs
          (this.$refs['passageInfirmerieDouleurs'] as any).douleursModel = {
            douleursPsychiques: response.douleursPsychiques,
            douleursPhysiques: this.getDouleursPhysiquesFormatted(response.douleursPhysiques)
          };

          (this.$refs['passageInfirmerieDouleurs'] as any).clearModel = JSON.parse(JSON.stringify((this.$refs['passageInfirmerieDouleurs'] as any).douleursModel));

          // Bilan
          (this.$refs['passageInfirmerieBilan'] as any).bilanModel = {
            orientationId: response.orientationId,
            periodicite: response.periodicite,
            satisfaction: response.satisfaction,
            stress: response.stress,
            pointsPositifs: response.pointsPositifs,
            pointsNegatifs: response.pointsNegatifs,
            actionsRealiseesId: response.actionsRealiseesId
          };

          let bilanModel = (this.$refs['passageInfirmerieBilan'] as any).bilanModel;
          let passageInfirmerieBilan = (this.$refs['passageInfirmerieBilan'] as any);

          passageInfirmerieBilan.satisfactionOptional = bilanModel.satisfaction == null;
          passageInfirmerieBilan.stressOptional = bilanModel.stress == null;

          (this.$refs['passageInfirmerieBilan'] as any).clearModel = JSON.parse(JSON.stringify((this.$refs['passageInfirmerieBilan'] as any).bilanModel));
          
          //Commentaire && fichiers
          (this.$refs['passageInfirmerieCommentairesFichiers'] as any).commentaireEtFichierModel = {
            id: response.id,
            note: response.note,
            noteId: response.noteId,
            fichiers: response.fichiers
          };

          (this.$refs['passageInfirmerieCommentairesFichiers'] as any).clearModel = JSON.parse(JSON.stringify((this.$refs['passageInfirmerieCommentairesFichiers'] as any).commentaireEtFichierModel));

        },
        (error: any) => {
          errorAlert.fire({
            title:
              "Erreur lors du chargement du passage infirmerie",
          });
        }
      );
  }

  getDouleursPhysiquesFormatted(douleursPhysiques:any){
    const douleursPhysiquesFormatted : any = [];

    douleursPhysiques.forEach((element:any) => {
      const douleurPhysiqueId = element.id;
      const tdp = element.typesDouleursPhysiques.find((x: any) => x.id === element.typeDouleurId);
      if(!douleursPhysiquesFormatted.some((x:any) => x.id == douleurPhysiqueId)){
        douleursPhysiquesFormatted.push(
          {
            id: douleurPhysiqueId,
            libelle: element.libelle,
            typeDouleurId: [element.typeDouleurId],
            typesDouleursPhysiques : [ {
              id : tdp.id,
              libelle : tdp.libelle,
              precision: element.precision,
              douleurPhysiqueId: douleurPhysiqueId
            }],
            typesDouleursPhysiquesToSave : [ {
              id : tdp.id,
              libelle : tdp.libelle,
              precision: element.precision,
              douleurPhysiqueId: douleurPhysiqueId
            }]
          }
        )
      }else {
        var d = douleursPhysiquesFormatted.filter((x:any) => x.id == douleurPhysiqueId)[0];
        d.typeDouleurId.push(element.typeDouleurId); 
        d.typesDouleursPhysiquesToSave.push({
              id : tdp.id,
              libelle : tdp.libelle,
              precision: element.precision,
              douleurPhysiqueId: douleurPhysiqueId
            }) 
        d.typesDouleursPhysiques.push({
          id : tdp.id,
          libelle : tdp.libelle,
          precision: element.precision,
          douleurPhysiqueId: douleurPhysiqueId
        }) 
      }
      
    });
     return douleursPhysiquesFormatted;
  }
  getTitleWithBadge() {
    return `<span class="badge badge-primary">Badge</span>`;
  }

  async validationFormsDouleurs() {   
    return new Promise((resolve) => {
      var psy = false;
      var phy = false;
      if ((this.$refs['passageInfirmerieDouleurs'] as any)) {

        if (!(this.$refs['passageInfirmerieDouleurs'] as any).pasDeDouleursPsychiques) {
          (this.$refs['passageInfirmerieDouleurs'] as any)!.$refs['formValidationDouleursPsy'].validate().then((success: any) => {
            if (success) {
              psy = true;
            } else {
              psy = false
            }
          })
        } else {
          psy = true;
        }

        if (!(this.$refs['passageInfirmerieDouleurs'] as any).pasDeDouleursPhysiques) {
          (this.$refs['passageInfirmerieDouleurs'] as any)!.$refs['formValidationDouleursPhy'].validate().then((success: any) => {
            if (success) {
              phy = true;
            }else {
              psy = false
            }
          })
        } else {
          phy = true;
        }

        setTimeout(() => {
          if (psy == true && phy == true) {
                resolve(true);
          } else {
                resolve(false)
          }
        }, 500)
      }
    })
  }
  
  validationFormDianosticInfirmerie() {
    return new Promise((resolve) => {
      if ((this.$refs['passageInfirmerieDiagnosticInfirmier'] as any)) {       
        (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any)!.$refs['diagnosticInfirmerieToValidate'].validate().then((success: any) => {
            resolve(success)
        })
      }
    })
  }

  validationFormExamenComplementaires() {
    return new Promise((resolve) => {
      if ((this.$refs['passageInfirmerieExamensComplementaires'] as any)) {       
        (this.$refs['passageInfirmerieExamensComplementaires'] as any)!.$refs['examenComplementairesToValidate'].validate().then((success: any) => {
            resolve(success)
        })
      }
    })
  }

  countErrorGeneral(){
    var passageInfirmerie = (this.$refs['passageInfirmerieGeneral'] as any)!.$refs['formValidationGeneral']
    var countError = 0
    Object.entries(passageInfirmerie.fields).forEach((x: any) => {
      if (x[1].invalid == true){
        countError++;
      }
    })
    return countError
  }
  
  countErrorDiagnosticInfirmerie(){
    var diagnosticInfirmerie = (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any)!.$refs['diagnosticInfirmerieToValidate']
    var countError = 0
    Object.entries(diagnosticInfirmerie.fields).forEach((x: any) => {
      if (x[1].invalid == true){
        countError++;
      }
    })
    return countError
  }

  
  countErrorExamensComplementairesInfirmerie(){
    var examenComp = (this.$refs['passageInfirmerieExamensComplementaires'] as any)!.$refs['examenComplementairesToValidate']
    var countError = 0
    Object.entries(examenComp.fields).forEach((x: any) => {
      if (x[1].invalid == true){
        countError++;
      }
    })
    return countError
  }

  countErrorDouleurs(){
    var douleurs = (this.$refs['passageInfirmerieDouleurs'] as any)!.$refs['formValidationDouleursPsy']
    var countError = 0
    Object.entries(douleurs.fields).forEach((x: any) => {
      if (x[1].invalid == true){
        countError++;
      }
    })
    return countError
  }

  countErrorBilan(){
    var bilan = (this.$refs['passageInfirmerieBilan'] as any)!.$refs['formValidationBilan']
    var countError = 0
    Object.entries(bilan.fields).forEach((x: any) => {
      if (x[1].invalid == true){
        countError++;
      }
    })
    return countError
  }

  async watchGeneralCorrection(interval?:any){
    var generalValidation = await this.validationFormGeneral()
    var element = document.getElementById('step-Général0')
    element?.classList.add('showError')
    element!.dataset.title = this.countErrorGeneral().toString();
    this.nbAllError = this.nbAllError + this.countErrorGeneral();
    if(!generalValidation){
      element!.dataset.title = this.countErrorGeneral().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async watchDiagnosticInfirmerieCorrection(interval?:any){
    var diagnosticInfirmerieValidation = await this.validationFormDianosticInfirmerie()
    var element = document.getElementById('step-Diagnosticinfirmier2')
    element?.classList.add('showError')
    element!.dataset.title = this.countErrorDiagnosticInfirmerie().toString();
    this.nbAllError = this.nbAllError + this.countErrorDiagnosticInfirmerie();
    if(!diagnosticInfirmerieValidation){
      element!.dataset.title = this.countErrorDiagnosticInfirmerie().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async watchExamensComplementairesCorrection(interval?:any){
    var douleursValidation = await this.validationFormExamenComplementaires()
    var element = document.getElementById('step-Examenscomplémentaires1')
    element?.classList.add('showError')
    element!.dataset.title = this.countErrorExamensComplementairesInfirmerie().toString();
    this.nbAllError = this.nbAllError + this.countErrorExamensComplementairesInfirmerie();
    if(!douleursValidation){
      element!.dataset.title = this.countErrorExamensComplementairesInfirmerie().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async watchDouleursCorrection(interval?:any){
    var douleursValidation = await this.validationFormsDouleurs()
    var element = document.getElementById('step-Douleurs3')
    element?.classList.add('showError')
    element!.dataset.title = this.countErrorDouleurs().toString();
    this.nbAllError = this.nbAllError + this.countErrorDouleurs();
    if(!douleursValidation){
      element!.dataset.title = this.countErrorDouleurs().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

  async watchBilanCorrection(interval?:any){
    var bilanValidation = await this.validationFormBilan()
    var element = document.getElementById('step-Bilan4')
    element?.classList.add('showError')
    element!.dataset.title = this.countErrorBilan().toString();
    this.nbAllError = this.nbAllError + this.countErrorBilan();
    if(!bilanValidation){
      element!.dataset.title = this.countErrorBilan().toString();
    }else {
      element?.classList.remove("showError");
      clearInterval(interval);
    }
  }

 
  async createPassageInfirmerie(){
    var douleursValidation = await this.validationFormsDouleurs()
    var diagnosticInfirmerieValidation = await this.validationFormDianosticInfirmerie()
    var examenComplementairesValidation = await this.validationFormExamenComplementaires()
    var generalValidation = await this.validationFormGeneral()
    var bilanValidation = await this.validationFormBilan()

    if (!generalValidation || !diagnosticInfirmerieValidation || !douleursValidation || !bilanValidation || !examenComplementairesValidation){

      if (!generalValidation){
        this.watchGeneralCorrection();
        var interval1 = setInterval(() => this.watchGeneralCorrection(interval1),1000)
      }

      if (!diagnosticInfirmerieValidation) {
        this.watchDiagnosticInfirmerieCorrection();
        var interval2 = setInterval(() => this.watchDiagnosticInfirmerieCorrection(interval2),1000)
      }

      if (!douleursValidation){
        this.watchDouleursCorrection();
        var interval3 = setInterval(() => this.watchDouleursCorrection(interval3),1000)
      }

      if (!bilanValidation){
       this.watchBilanCorrection();
       var interval4 = setInterval(() => this.watchBilanCorrection(interval4),1000)
      }

      if (!examenComplementairesValidation) {
        this.watchExamensComplementairesCorrection();
        var interval5 = setInterval(() => this.watchExamensComplementairesCorrection(interval5),1000)
      }
      return
    }
    else {

      var generalStep = document.getElementById('step-Général0')
      generalStep?.classList.remove('showError')

      var diagnosticInfirmerieStep = document.getElementById('step-Diagnosticinfirmier2')
      diagnosticInfirmerieStep?.classList.remove('showError')

      var douleursStep = document.getElementById('step-Douleurs3')
      douleursStep?.classList.remove('showError')

      var examensCompStep = document.getElementById('step-Examenscomplémentaires1')
      examensCompStep?.classList.remove('showError')

      var bilanStep = document.getElementById('step-Bilan4')
      bilanStep?.classList.remove('showError')

      var general = (this.$refs['passageInfirmerieGeneral'] as any).getFormattedData();
      var examensComplementaires = (this.$refs['passageInfirmerieExamensComplementaires'] as any).getFormattedData();
      var diagnosticInfirmier = (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).getFormattedData();
      var douleurs = (this.$refs['passageInfirmerieDouleurs'] as any).getFormattedData();
      var bilan = (this.$refs['passageInfirmerieBilan'] as any).getFormattedData();
      var commentsFile = await (this.$refs['passageInfirmerieCommentairesFichiers'] as any).save((this.$refs['passageInfirmerieCommentairesFichiers'] as any).onUploadProgress).then((e: any) => (this.$refs['passageInfirmerieCommentairesFichiers'] as any).getFormattedData());


      this.newPassageInfirmerie = {...general,...examensComplementaires,...diagnosticInfirmier,...douleurs,...bilan, ...commentsFile.model};
      this.newPassageInfirmerie.dossierId = this.$route.params.id;

      await this.$http.ressifnet.passagesInfirmerie.post(this.newPassageInfirmerie.dossierId, this.newPassageInfirmerie, commentsFile.config)
        .then(
          async (response: any) => {
            this.newPassageInfirmerie = {};
            successAlert.fire({
              title: "Création d'un passage d'infirmerie",
              text: "Création effectuée avec succès"
            });
            this.resetModel()
            this.$emit("resetDisplayAddPassageInfirmerie");
            this.$emit("loadListPassageInfirmerie");
          },
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          })
    }
  }

  async updatePassageInfirmerie(){
    this.nbAllError = 0;
    this.updateDone = false;
    var douleursValidation = await this.validationFormsDouleurs()
    var diagnosticInfirmerieValidation = await this.validationFormDianosticInfirmerie()
    var examenComplementairesValidation = await this.validationFormExamenComplementaires()
    console.log(examenComplementairesValidation)
    var generalValidation = await this.validationFormGeneral()
    var bilanValidation = await this.validationFormBilan()

    if (!generalValidation || !diagnosticInfirmerieValidation || !douleursValidation || !bilanValidation || !examenComplementairesValidation){

      if (!generalValidation){
        this.watchGeneralCorrection();
        var interval1 = setInterval(() => this.watchGeneralCorrection(interval1),1000)
      }

      if (!diagnosticInfirmerieValidation) {
        this.watchDiagnosticInfirmerieCorrection();
        var interval2 = setInterval(() => this.watchDiagnosticInfirmerieCorrection(interval2),1000)
      }

      if (!douleursValidation){
        this.watchDouleursCorrection();
        var interval3 = setInterval(() => this.watchDouleursCorrection(interval3),1000)
      }

      if (!bilanValidation){
       this.watchBilanCorrection();
       var interval4 = setInterval(() => this.watchBilanCorrection(interval4),1000)
      }
      
      if (!examenComplementairesValidation) {
        this.watchExamensComplementairesCorrection();
        var interval5 = setInterval(() => this.watchExamensComplementairesCorrection(interval5),1000)
      }
      
      this.updateDone = true

      return
    }
    else {

      var generalStep = document.getElementById('step-Général0')
      generalStep?.classList.remove('showError')

      var diagnosticInfirmerieStep = document.getElementById('step-Diagnosticinfirmier2')
      diagnosticInfirmerieStep?.classList.remove('showError')

      var douleursStep = document.getElementById('step-Douleurs3')
      douleursStep?.classList.remove('showError')

      var bilanStep = document.getElementById('step-Bilan4')
      bilanStep?.classList.remove('showError')

      var general = (this.$refs['passageInfirmerieGeneral'] as any).getFormattedData();
      var examensComplementaires = (this.$refs['passageInfirmerieExamensComplementaires'] as any).getFormattedData();
      var diagnosticInfirmier = (this.$refs['passageInfirmerieDiagnosticInfirmier'] as any).getFormattedData();
      var douleurs = (this.$refs['passageInfirmerieDouleurs'] as any).getFormattedData();
      var bilan = (this.$refs['passageInfirmerieBilan'] as any).getFormattedData();
      var commentsFile = await (this.$refs['passageInfirmerieCommentairesFichiers'] as any).save((this.$refs['passageInfirmerieCommentairesFichiers'] as any).onUploadProgress).then((e: any) => (this.$refs['passageInfirmerieCommentairesFichiers'] as any).getFormattedData());

      this.updatePassageInfirmerieModel = {...general,...examensComplementaires,...diagnosticInfirmier,...douleurs,...bilan, ...commentsFile.model};
      this.updatePassageInfirmerieModel.dossierId = this.$route.params.id;    
      this.updatePassageInfirmerieModel.passageInfirmerieId = this.passageInfirmerieId;

      await this.$http.ressifnet.passagesInfirmerie.put(
        this.updatePassageInfirmerieModel.dossierId,
        this.updatePassageInfirmerieModel.passageInfirmerieId,
        this.updatePassageInfirmerieModel,
        commentsFile.config
      )
        .then(
          async (response: any) => {
            this.updatePassageInfirmerieModel = {};
            successAlert.fire({
              title: "Modification d'un passage d'infirmerie",
              text: "Modification effectuée avec succès"
            });
            this.resetModel()
            this.$emit("resetDisplayAddPassageInfirmerie");
            this.$emit("loadListPassageInfirmerie");
          },
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          })
    }
    this.updateDone = true;

  }

  validationFormGeneral() {
    return new Promise((resolve) => {
      if ((this.$refs['passageInfirmerieGeneral'] as any)) {    
        (this.$refs['passageInfirmerieGeneral'] as any)!.$refs['formValidationGeneral'].validate().then((success: any) => {
            resolve(success)
        })
      }
    })
  }

  validationFormBilan() {
    return new Promise((resolve) => {
      if ((this.$refs['passageInfirmerieBilan'] as any)) {   
        var bilanDry = (this.$refs['passageInfirmerieBilan'] as any);
        var generalDry = (this.$refs['passageInfirmerieGeneral'] as any);
        var general = generalDry.getFormattedData();
        if(general.typeVisite?.libelle == 'Visite sous délégation MDT'){          
          bilanDry.setTypeSuivisRequired(true);          
        }else {
          bilanDry.setTypeSuivisRequired(false);          

        }
        setTimeout(() => {
          (this.$refs['passageInfirmerieBilan'] as any)!.$refs['formValidationBilan'].validate().then((success: any) => {
              resolve(success); 
        })
        },500)
         
       
      }
    })
  }
}

