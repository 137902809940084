







































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import {
    BForm,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
    BFormDatepicker,
    BSpinner,
    BModal,
    BButton,
    BFormInput,
    BInputGroup,
    BTab,
    BCardText,
    BTabs,
    VBPopover,
    BPagination,
    BFormCheckboxGroup,
    VBTooltip
} from "bootstrap-vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { required, min, max } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Prescripteur } from "@/api/models/options/prescripteurs/prescripteur";
import { TypeDePoste } from "@/api/models/options/typesDePoste/typeDePoste";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import Cleave from 'vue-cleave-component'
import { Risque, RisqueCreateModel, RisqueToSave } from "@/api/models/dossiers/risques";
import { FamilleDeRisque } from "@/api/models/dossiers/famillesDeRisque";
import { ValeurMesure } from "@/api/models/dossiers/valeursMesures";
import { Mesure, MesureCreateModel } from "@/api/models/dossiers/mesures";
import { Impact, ImpactCreateModel } from "@/api/models/dossiers/impacts";
import { DetailsEtudeDePosteModel, EtudeDePoste } from "@/api/models/dossiers/etudeDePoste";
import { SousImpact, SousImpactCreateModel } from "@/api/models/dossiers/sousImpacts";
import { NiveauAlerte, NiveauAlerteCreateModel } from "@/api/models/dossiers/niveauxAlerte";
import { Binome } from '../../../api/models/options/binomes/binome';
import { Contexte } from "@/api/models/options/contextes/contexte";
import { CreateEtudeDePosteModel } from '../../../api/models/dossiers/etudeDePoste';
import { NiveauAlerteValeur } from "@/api/models/enums/niveauAlerteValeur";
import VueSlider from 'vue-slider-component';
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { CreateNoteModel } from "@/api/models/dossiers/note";
import { ref } from "@vue/composition-api";
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
    components: {
        RessifnetDateInput,
        BForm,
        BFormRadioGroup,
        BFormCheckbox,
        BFormSelect,
        BFormSelectOption,
        BFormDatepicker,
        BFormSpinbutton,
        BSpinner,
        BModal,
        ValidationObserver,
        ValidationProvider,
        SearchableVueSelect,
        BButton,
        BFormInput,
        BInputGroup,
        BTab,
        BTabs,
        BCardText,
        VueSlider,
        RessifnetQuillEditor,
        Cleave,
        BPagination,
        BFormCheckboxGroup,
        MultipleFileInput      
    },
    directives: {
        'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
    }
})
export default class TabEtudeDePoste extends Vue {
    @Prop({ required: true }) secteurActiviteId!: string;

    currentPage = 1;
    perPage = 5;

    formTitle: string = 'Créer une étude de poste';
    formIcon: string = 'Plus'
    viewMode: boolean = false;
    editMode: boolean = false;
    showCreateEtudeDePoste: boolean = false;

    nonEvalueStress: boolean = false;
    niveauAlertEmpty: boolean = false;

    required = required;
    droitsEnum = DroitsEnum;

    saving = false;

    ticksLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    refreshIndex = 0;
    selectedBinomes: Binome[] = [];
    dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
    etudeDePoste: CreateEtudeDePosteModel = {
            dossierId: this.$route.params.id,
            dateEtudeDePoste: this.dateToday,
            contextes : [],
            binomesId : [],
            binomes : [],
            duree : undefined,
            typeDePosteId : undefined,
            stress : 1,
            note : {message :''},
            risques: [],            
            prescripteurId: undefined
    }
    clearModel: CreateEtudeDePosteModel = {
        dossierId: this.$route.params.id,
        dateEtudeDePoste: this.dateToday,
        stress: 1,
        risques: [],
        binomesId: [],
        contextesId: [],
        fichiers: [],
        note: {message : ''}
    }
    risquesSelected: RisqueCreateModel[] | undefined = [];
    loadImpactsList = false;
    hasSousImpact = false;
    etudeDePosteLoad = false;

    percentUpload: number = 0;

    onUploadProgress(percent: any) {
        this.percentUpload = percent;
    }

    etudesDePoste: PaginatedList<EtudeDePoste> = new PaginatedList<EtudeDePoste>();
    famillesDeRisques: PaginatedList<FamilleDeRisque> = new PaginatedList<FamilleDeRisque>();
    prescripteurs: PaginatedList<Prescripteur> = new PaginatedList<Prescripteur>();
    typesDePoste: PaginatedList<TypeDePoste> = new PaginatedList<TypeDePoste>();
    binomes: PaginatedList<Binome> = new PaginatedList<Binome>();
    contextes: PaginatedList<Contexte> = new PaginatedList<Contexte>();

    @Watch('currentPage')
    async currentPageChange() {
        await this.loadEtudesDePostes(null)
    }

    async created() {
        await this.loadEtudesDePostes(null);
        await this.loadFamilleDeRisques();
        await this.searchBinomes(null);
        await this.searchContextes(null);
        await this.searchTypesDePoste(null);
        await this.searchPrescripteurs(null);
    }

    displayAddEtudeDePoste(){
        if(!this.viewMode && this.showCreateEtudeDePoste){
            var diff = this.deepDiff(this.clearModel,this.etudeDePoste);
            var hasDiff : boolean = diff && Object.keys(diff).length != 0;
            if(!hasDiff){
                this.formTitle = 'Créer une étude de poste';
                this.formIcon = 'Plus';
                this.editMode = false;
                this.viewMode = false;
                this.resetModel();
            }else {
                let answer = window.confirm('Vous avez des modifications non sauvegardées. Êtes-vous sûr de vouloir quitter la page ?');
                    if(answer){
                        this.formTitle = 'Créer une étude de poste';
                        this.formIcon = 'Plus';
                        this.editMode = false;
                        this.viewMode = false;
                    }         
                    // this.resetModel();
            }
        }else{
            this.formTitle = 'Créer une étude de poste';
            this.formIcon = 'Plus';
            this.editMode = false;
            this.viewMode = false;
            this.resetModel();
        }

    }

    toggleNonEvaluate() {
        this.nonEvalueStress = false;
        this.refreshIndex++;
    }

    getClearModel(){
        return this.clearModel;
    }

   createClone(){
        var fakeClone = JSON.parse(JSON.stringify(this.etudeDePoste));
        this.clearModel = fakeClone;    
    } 

    formatedContexte(contextes: any) {
        return contextes.map((contexte: any) => contexte.libelle).join(';')
    }

    clearRisquesList() {
        this.etudeDePoste.risques = this.risquesSelected;
    }

    clearStress(val: any) {
        if (val) {
            this.etudeDePoste.stress = 0;
        }
    }

    addNiveauAlert(niveau: NiveauAlerteCreateModel, value: any, element: any, risque: RisqueCreateModel) {

        if(this.viewMode) return;
        // Element avec déjà une valeur -> ToSave
        if (element.target.closest('.icon-status-item').classList.contains("active")) {
            element.target.closest('.icon-status-item').classList.remove("active");
            var indexOfNiveauDalerte = risque.niveauxAlertes.indexOf(risque.niveauxAlertes.find((e) => e.libelle == niveau.libelle) as any);
            if (indexOfNiveauDalerte !== -1) {
                risque.niveauxAlertes.splice(indexOfNiveauDalerte, 1);
                niveau.valeur = null;
            }
        }
        // Element sans valeur
        else {
            var parent = element.target.closest(".icon-list-custom")
            var icons = parent.querySelectorAll('.icon-status-item');
            icons.forEach((icon: any) => {
                icon.classList.remove("active");
            })
            element.target.closest('.icon-status-item').classList.toggle("active");
            switch (value) {
                case 1:
                    niveau.valeur = NiveauAlerteValeur.Faible;
                    break;
                case 2:
                    niveau.valeur = NiveauAlerteValeur.Moyen;

                    break;
                case 3:
                    niveau.valeur = NiveauAlerteValeur.Important;

                    break;

                default:
                    break;
            }
            risque.niveauxAlertes = [];
            risque.niveauxAlertes.push(niveau);
        }
        this.refreshIndex++;
    }

    getOptionsImpact(risque: RisqueCreateModel, array? : any) {
        
        if(this.viewMode && array){
            return Object.values(array);
        }

        if (risque.impactsLibelle && risque.impactsLibelle.length != 0 && Object.values(risque.impactsLibelle)) {
            return Object.values(risque.impactsLibelle)
        }
    }

    onSousImpactUnselect(sousImpact: any, risque: RisqueCreateModel) {

        var sousImpactToSaveIndex = risque.sousImpactsToSave.indexOf(sousImpact);
        if (sousImpactToSaveIndex !== -1) {
            risque.sousImpactsToSave.splice(sousImpactToSaveIndex, 1);
        }

        if (risque.niveauxAlertesList) {
            var ele: any = risque.niveauxAlertesList.find((element: any) => element.sousImpactLibelle == sousImpact.libelle);
            var indexOfUnSelect = risque.niveauxAlertesList.indexOf(ele);
            if (indexOfUnSelect !== -1) {
                risque.niveauxAlertesList.splice(indexOfUnSelect, 1);
            }
        }

        this.refreshIndex++;
    }

    removeNiveauAlert(niveauAlert: NiveauAlerteCreateModel, risque: RisqueCreateModel) {


    if(this.viewMode){
      return;
    }
        
        // Pas de sousImpact et impacts
    if(risque.impacts && !niveauAlert.sousImpactId){
      risque.impacts = risque.impacts.filter((x:any) => x.id != niveauAlert.impactId)
      this.refreshIndex++;
    }

    type listSousImpacts = {sousImpactsLibelle: { id: string }[]};

     // SousImpacts present 
    if(risque.impacts && niveauAlert && niveauAlert.sousImpactId && risque.impacts.some((x:listSousImpacts) => x.sousImpactsLibelle && x.sousImpactsLibelle.length != 0) ){
        risque.impacts = risque.impacts.filter((x: listSousImpacts) =>
        x.sousImpactsLibelle = x.sousImpactsLibelle.filter((y: { id: string }) => y.id !== niveauAlert.sousImpactId)
        );
    }

    if (risque.sousImpactsToSave) {
      risque.sousImpactsToSave = risque.sousImpactsToSave.filter(
        (x) => x.id != niveauAlert.sousImpactId
      );
    }

    risque.niveauxAlertesList = risque.niveauxAlertesList.filter((x:any) => x.impactId != niveauAlert.impactId)

    this.refreshIndex++;

    }

    deleteNiveauAlerte(sousImpact: SousImpactCreateModel, risque: RisqueCreateModel) {
        if (risque.niveauxAlertesList) {
            risque.niveauxAlertesList = risque.niveauxAlertesList.filter((e: any) => e.sousImpactId != sousImpact.id);
        }
        this.refreshIndex++;
    }

    deleteSousImpact(sousImpact: SousImpactCreateModel, risque: RisqueCreateModel) {
        if (risque.sousImpactsToSave) {
            risque.sousImpactsToSave = risque.sousImpactsToSave.filter((e: any) => e.libelle != sousImpact.libelle);
        }
        this.refreshIndex++;
    }

    onImpactUnselect(impact: ImpactCreateModel, risque: RisqueCreateModel) {

        if (impact.sousImpacts.length != 0) {

            impact.sousImpacts.forEach((e) => {
                this.deleteSousImpact(e, risque)
                this.deleteNiveauAlerte(e, risque);
            })
        }

        risque.niveauxAlertesList = [];

        this.refreshIndex++;

    }

    onSousImpactSelect(sousImpacts: any, risque: RisqueCreateModel) {
        if (!(risque.niveauxAlertesList)) {
            risque.niveauxAlertesList = [];
        }
        var niveaualert: NiveauAlerteCreateModel = { valeur: null, impactId: sousImpacts.impactId, libelle: sousImpacts.libelle, sousImpactLibelle: sousImpacts.libelle, sousImpactId: sousImpacts.id }

        var isInArray = risque.niveauxAlertesList.includes(risque.niveauxAlertesList.find((e) => e.sousImpactLibelle == niveaualert.sousImpactLibelle) as any);

        if (!isInArray) {
            risque.sousImpactsToSave = [];
            risque.sousImpactsToSave.push(sousImpacts);
            risque.niveauxAlertesList.push(niveaualert);
        }
        this.refreshIndex++;
    }

    getSousImpactsList(impacts: ImpactCreateModel[], risque: RisqueCreateModel) {

        // On nettoie la liste    
        risque.sousImpacts = [];


        // Pour tout les impacts de la liste
        impacts.forEach((et: ImpactCreateModel) => {


            // Si impact a des sous impacts
            if (et.sousImpacts.length != 0) {
                // On prend les sous impacts et on les met dans la liste
                et.sousImpacts.forEach((e: SousImpactCreateModel) => {
                    e.impactLibelle = et.libelle;
                    risque.sousImpacts.push(e);
                })
                this.refreshIndex++;


            } else
            // Le niveau d'alerte se place a ce niveau
            {

                this.refreshIndex++;

                if (risque.niveauxAlertesList && risque.niveauxAlertesList.length != 0) {

                    // Déjà un element dans la liste
                    //On créer l'élement
                    var niveau: NiveauAlerteCreateModel = { valeur: null, impactId: et.id, libelle: et.libelle }

                    // Vérifier s'il n'est pas dedans
                    var isList = risque.niveauxAlertesList.find((lvl: NiveauAlerteCreateModel) => lvl.impactId == niveau.impactId);

                    // Si pas dedans on l'ajoute
                    if (!isList) {
                        risque.niveauxAlertesList.push(niveau);
                    }

                } else {
                    if (risque)
                        if (!risque.niveauxAlertesList) {
                            risque.niveauxAlertesList = [];
                        }
                    if (!(risque.niveauxAlertesList.length != 0)) {
                        //On créer l'élement
                        var niveau: NiveauAlerteCreateModel = { valeur: null, libelle: et.libelle, impactId: et.id };
                        risque.niveauxAlertesList.push(niveau);
                    }
                }
            }
        })


    }

    addMesureValue(value: any, mesure: MesureCreateModel, risque: RisqueCreateModel) {
        if (value) {
            mesure.selected = true;
            mesure.valeur = null;
            var valeurMesure: ValeurMesure = { valeur: mesure.valeur, mesure: mesure }
            if (!(risque.valeursMesures)) {
                risque.valeursMesures = [];
                risque.valeursMesures.push(valeurMesure);
            } else {
                risque.valeursMesures.push(valeurMesure);
            }
        } else {
            mesure.selected = false;
            mesure.valeur = null;
            if(!risque.valeursMesures){
                risque.valeursMesures = [];
            }
            var index = risque.valeursMesures.indexOf(risque.valeursMesures.filter(x => x.mesure == mesure)[0]);
            if (index !== -1) {
                risque.valeursMesures.splice(index, 1);
            }
        }
        this.refreshIndex++;
    }

    async askDeleteEtudeDePoste(etudeDePosteId: any) {
        this.$bvModal
            .msgBoxConfirm(
                "Etes-vous sûr de vouloir supprimer l'étude de poste ? ",
                {
                    title: "Confirmation de suppression",
                    size: "sm",
                    okVariant: "primary",
                    okTitle: "Oui",
                    cancelTitle: "Non",
                    cancelVariant: "outline-secondary",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            .then(async (value) => {
                if (value === true) {
                    this.deleteEtudeDePoste(etudeDePosteId);
                    this.showCreateEtudeDePoste = false;
                    this.loadEtudesDePostes(null)
                } else {
                    return;
                }
            });
    }

    async deleteEtudeDePoste(edpId: any) {
        await this.$http.ressifnet.dossiers.deleteEtudeDePoste(this.$route.params.id, edpId).then(
            async (response: string) => {
                successAlert.fire({
                    title: "Suppression d'une étude de poste",
                    text: "Dossier marqué pour la suppression",
                });
                this.loadEtudesDePostes(null);
            },
            (error: any) => {
                if (
                    error.response?.data?.detail &&
                    error.response.data.detail.length > 0
                ) {
                    errorAlert.fire({
                        text: error.response.data.detail,
                    });
                } else {
                    errorAlert.fire({
                        text: error.message,
                    });
                }
            }
        );
    }

    async askDeleteRisque(risque: RisqueCreateModel) {
        this.$bvModal
            .msgBoxConfirm(
                "Etes-vous sûr de vouloir supprimer le risque ? ",
                {
                    title: "Confirmation de suppression",
                    size: "sm",
                    okVariant: "primary",
                    okTitle: "Oui",
                    cancelTitle: "Non",
                    cancelVariant: "outline-secondary",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            .then(async (value) => {
                if (value === true) {
                    this.risquesSelected = this.risquesSelected?.filter(x => x.libelle != risque.libelle);
                    var index = this.etudeDePoste.risques?.indexOf(this.etudeDePoste.risques.find((e) => e.libelle == risque.libelle) as any) as any;
                    this.etudeDePoste!.risquesIds = this.etudeDePoste!.risquesIds!.filter((x:any) => x != risque.id)
                    risque.sousImpactsToSave = [];
                    risque.valeursMesures = [];
                    risque.impacts = [];
                    risque.mesures.forEach((e) => e.valeur = '');
                    risque.niveauxAlertesList = [];
                    if (index !== -1) {
                        this.etudeDePoste.risques?.splice(index, 1)
                    }
                } else {
                    return;
                }
            });
    }

    selectRisque(value: any, risque: Risque, famille: string) {
        risque.familleLibelle = famille;
        this.etudeDePoste.risques?.push(risque as RisqueCreateModel);
        this.etudeDePoste.risques = this.etudeDePoste.risques!.filter((risque) =>
         value.includes(risque.id)
        );
        this.refreshIndex++;    
    }

    unselectRisque(risque: any) {
        if (this.etudeDePoste) {
            this.etudeDePoste.risques = this.etudeDePoste.risques!.filter((x:any) => x.id != risque.id);
            this.etudeDePoste.risquesIds = this.etudeDePoste.risquesIds!.filter((x:any) => x != risque.id);
        }
        this.refreshIndex++
    }

    async loadEtudesDePostes(params: any) {
        await this.$http.ressifnet.etudesDePoste.paginatedList(this.$route.params.id, this.currentPage, this.perPage, params?.search).then(
            (response: PaginatedList<EtudeDePoste>) => {
                this.etudesDePoste = response;
                this.etudeDePosteLoad = true;
            },
            (error: any) => {
                errorAlert.fire({
                    title: "Erreur lors du chargement des etudes de poste",
                });
            }
        );
    }

    async populateImpactsList(){
        await this.$http.ressifnet.impacts.getImpactsByRisques(this.etudeDePoste.risques).then(
            (response: PaginatedList<Impact>) => {
                this.refreshIndex++;
                this.etudeDePoste.risques?.forEach((risque: any) => {
                    var impactLoaded = response.items.filter((impact: Impact) => impact.risqueId == risque.id);
                    if (impactLoaded.length) {
                        risque.impactsLibelle = impactLoaded
                    }
                    this.loadImpactsList = true;
                });
            },
            (error: any) => {
                errorAlert.fire({
                    title: "Erreur lors du chargement des familles de risque",
                });
            }
        );
    }
    async loadRisquesRelativeData() {
        this.risquesSelected = this.etudeDePoste.risques;
        await this.$http.ressifnet.impacts.getImpactsByRisques(this.etudeDePoste.risques).then(
            (response: PaginatedList<Impact>) => {
                this.refreshIndex++;
                this.etudeDePoste.risques?.forEach((risque: any) => {
                    if (!this.editMode) {
                        if(!risque.impacts){
                            risque.impacts = [];
                        }
                    }
                    var impactLoaded = response.items.filter((impact: Impact) => impact.risqueId == risque.id);
                    if (impactLoaded.length) {
                        risque.impactsLibelle = impactLoaded
                    }
                    this.loadImpactsList = true;
                });
            },
            (error: any) => {
                errorAlert.fire({
                    title: "Erreur lors du chargement des familles de risque",
                });
            }
        );
    }

    async loadFamilleDeRisques() {
        await this.$http.ressifnet.famillesDeRisque.paginatedList(1, 50, true).then(
            (response: PaginatedList<FamilleDeRisque>) => {
                this.famillesDeRisques = response;
            },
            (error: any) => {
                errorAlert.fire({
                    title: "Erreur lors du chargement des familles de risque",
                });
            }
        );
    }

    async searchBinomes(params?: any) {
        if (!params || params?.reset) {
            this.binomes = new PaginatedList();
        }

        await this.$http.ressifnet.binomes
            .paginatedList(
                params?.pageNumber ?? 1,
                params?.pageSize ?? 10,
                params?.search ?? ""
            )
            .then((response: PaginatedList<Binome>) => {
                this.binomes.items = this.binomes.items!.concat(response.items.filter(ri => !this.binomes.items!.some(lsi => lsi.id == ri.id)))
            },
                (error: any) => {
                    errorAlert.fire({
                        title: "Erreur lors du chargement des binomes",
                    });
                });
    }

    async searchPrescripteurs(params?: any) {
        if (!params || params?.reset) {
            this.prescripteurs = new PaginatedList();
        }

        await this.$http.ressifnet.prescripteurs
            .paginatedList(
                params?.pageNumber ?? 1,
                params?.pageSize ?? 10,
                params?.search ?? ""
            )
            .then((response: PaginatedList<Prescripteur>) => {
                this.prescripteurs.items = this.prescripteurs.items!.concat(response.items.filter(ri => !this.prescripteurs.items!.some(lsi => lsi.id == ri.id)))
            },
                (error: any) => {
                    errorAlert.fire({
                        title: "Erreur lors du chargement des prescripteurs",
                    });
                });
    }

    async searchContextes(params?: any) {
        if (!params || params?.reset) {
            this.contextes = new PaginatedList();
        }

        await this.$http.ressifnet.contextes
            .paginatedList(
                params?.pageNumber ?? 1,
                params?.pageSize ?? 10,
                params?.search ?? ""
            )
            .then((response: PaginatedList<Contexte>) => {
                this.contextes.items = this.contextes.items!.concat(response.items.filter(ri => !this.contextes.items!.some(lsi => lsi.id == ri.id)))
            },
                (error: any) => {
                    errorAlert.fire({
                        title: "Erreur lors du chargement des contextes",
                    });
                });
    }

    async searchTypesDePoste(params: any) {
        if (!params || params?.reset) {
            this.typesDePoste = new PaginatedList();
        }

        await this.$http.ressifnet.typesDePoste
            .paginatedList(
                this.secteurActiviteId,
                params?.pageNumber ?? 1,
                params?.pageSize ?? 10,
                params?.search ?? ""
            )
            .then((response: PaginatedList<TypeDePoste>) => {
                this.typesDePoste.items = this.typesDePoste.items!.concat(response.items.filter(ri => !this.typesDePoste.items!.some(lsi => lsi.id == ri.id)))
            },
                (error: any) => {
                    errorAlert.fire({
                        title: "Erreur lors du chargement des types de poste",
                    });
                });
    }

    removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de l'étude de poste ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.etudeDePoste.fichiers!.splice(
        (this.etudeDePoste.fichiers! as any[]).indexOf(fichier),
        1
      );
    }
  }

  async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.etudesDePoste
      .deleteFile(
        this.$route.params.id,
        (this.etudeDePoste as any).id,
        fichier.id
      )
      .then((res: any) => {
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        this.etudeDePoste.fichiers?.splice(
          (this.etudeDePoste.fichiers as any[]).indexOf(fichier),
          1
        );
      });
  }

    async saveEtudeDePoste(callback: any) {
        if (this.etudeDePoste.risques && this.etudeDePoste.risques?.some(x => x.niveauxAlertesList?.some(x => x.valeur == null))) {
            this.niveauAlertEmpty = true;
            return;
        }

        (this.$refs["formValidationEtudeDePoste"] as any)
            .validate()
            .then(async (result: boolean) => {
                if (!result) return;
                else {
                    this.saving = true;
                    
                    let config = {
                        onUploadProgress(progressEvent: any) {
                            var percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );

                            callback(percentCompleted);
                            return percentCompleted;
                        },
                    };
                    this.editMode ? this.updateEtudeDePoste(config) : this.createEtudeDePoste(config)  ;
                }
            });
    }

    async updateEtudeDePoste(config:any){
        var model = this.getEtudeDePosteFormatted();
        await this.$http.ressifnet.etudesDePoste
            .put(this.$route.params.id, this.etudeDePoste.id, model, config)
            .then(
                (response: any) => {
                    this.saving = false;
                    
                    successAlert.fire({
                        title: this.editMode ? "Modification d'une étude de poste" : "Création d'une étude de poste",
                        text: this.editMode ? "Modification effectué avec succès" :"Ajout effectué avec succès",
                    });

                    this.clearModel = {...this.etudeDePoste};

                    this.loadEtudesDePostes(null);
                    (error: any) => {
                        if (
                            error?.response?.data?.errors &&
                            Object.keys(error.response.data.errors).length
                        ) {
                            errorAlert.fire({
                                text: error.response.data.errors[
                                    Object.keys(error.response.data.errors)[0]
                                ],
                            });
                        } else {
                            errorAlert.fire({
                                text: error?.response?.data?.detail ?? error.message,
                            });
                        }
                    }
                }
            );
    }

    async createEtudeDePoste(config: any) {    

        var model = this.getEtudeDePosteFormatted();
        await this.$http.ressifnet.etudesDePoste
            .post(this.$route.params.id, model, config)
            .then(
                (response: any) => {
                    this.saving = false;
                    
                    successAlert.fire({
                        title: this.editMode ? "Modification d'une étude de poste" : "Création d'une étude de poste",
                        text: this.editMode ? "Modification effectué avec succès" :"Ajout effectué avec succès",
                    });
                    this.resetModel();
                    this.loadEtudesDePostes(null);
                    (error: any) => {
                        if (
                            error?.response?.data?.errors &&
                            Object.keys(error.response.data.errors).length
                        ) {
                            errorAlert.fire({
                                text: error.response.data.errors[
                                    Object.keys(error.response.data.errors)[0]
                                ],
                            });
                        } else {
                            errorAlert.fire({
                                text: error?.response?.data?.detail ?? error.message,
                            });
                        }
                    }
                }
            );
    }

    async editEtudeDePoste(edpId: any){
        if (edpId == null || edpId == '0') {
            this.resetModel()
        } {
        this.editMode = true;
        var diff = this.deepDiff(this.clearModel,this.etudeDePoste);
        var hasDiff = diff && Object.keys(diff).length != 0;

          // Pas de diff + en mode edit
          if(!hasDiff || this.editMode){          
                this.showCreateEtudeDePoste = true;
                this.formTitle = 'Editer une étude de poste';
                this.formIcon = 'Edit2';
                this.viewMode = false;

                await this.$http.ressifnet.dossiers
                    .getEtudeDePosteById(edpId)
                    .then((edp: DetailsEtudeDePosteModel) => {

                        this.loadImpactsList = true;


                        this.etudeDePoste = {
                            id : edp.id,
                            dossierId: edp.dossierId,
                            dateEtudeDePoste: edp.dateEtudeDePoste,
                            duree: edp.duree,
                            prescripteurId: edp.prescripteurId,
                            contextesId: edp.contextes?.map(x => x.id),
                            binomesId: edp.binomes?.map(x => x.id),
                            fichiers: edp.fichiers,
                            typeDePosteId: edp.typeDePosteId,
                            stress: edp.stress,
                            note: edp.commentaire ?? {'message' :''},
                            noteId: edp.commentaire?.id,
                            risques: edp.risques,
                            risquesIds: edp.risques?.map((x:any) => x.id),
                            valeurMesures: edp.valeurMesures,
                            niveauxAlertes: edp.niveauAlertes,
                            commentaire : edp.commentaire?.message 
                        };
                        

                        this.etudeDePoste.risques?.forEach((risque) => {

                            var risquesImpactsLinked = [...risque.impacts];

                            risque.impacts = [];
                            risquesImpactsLinked.forEach((impact) => {

                                var impactAlerte = edp.niveauAlertes?.find((alerte) => {
                                    return alerte.impactId === impact.id;
                                });

                                if (impactAlerte) {
                                    risque.impacts.push(impact);
                                }
                            }); 

                            risque.niveauxAlertesList = [];

                
                            edp.niveauAlertes?.forEach((alerte, index) => {
                                var niveauAlerte = {
                                    risqueId: alerte.risqueId,
                                    valeur: alerte.valeur,
                                    impactId: alerte.impactId,
                                    sousImpactId: alerte.sousImpactId,
                                    libelle: ""
                                };

                                // Vérification si l'impactId est présent
                                if (alerte.impactId) {
                                    var impact = risque.impacts.find((imp) => {
                                        return imp.id === alerte.impactId;
                                    });

                                    if (impact) {
                                        niveauAlerte.libelle = impact.libelle;
                                    }
                                }

                                // Vérification si le sousImpactId est présent
                                if (alerte.sousImpactId) {
                                    var sousImpact = risque.impacts.reduce((acc: any, imp) => {
                                        var sousImpact = imp.sousImpacts.find((simp) => {
                                            return simp.id === alerte.sousImpactId;
                                        });
                                        return sousImpact ? sousImpact : acc;
                                    }, null);

                                    if (sousImpact) {
                                        niveauAlerte.libelle = sousImpact.libelle;
                                    }
                                }

                                if(risque.id == niveauAlerte.risqueId){
                                    risque.niveauxAlertesList.push(niveauAlerte);
                                }
                                setTimeout(() => {
                                    switch (niveauAlerte.valeur) {
                                        case 0:
                                            (this.$refs['levelOne'] as any )[index].classList.add("active");
                                            break;
                                        case 1:
                                            (this.$refs['levelTwo'] as any )[index].classList.add("active");
                                            break;
                                        case 2:
                                            (this.$refs['levelThree'] as any )[index].classList.add("active");
                                            break;

                                        default:
                                            break;
                                    }
                                }, 500)
                            });

                            risque.impacts.forEach((impact) => {
                                impact.sousImpactsLibelle = []; // Création du tableau "sousImpactLibelle"

                                // Parcours des sous-impacts de l'impact actuel
                                impact.sousImpacts.forEach((sousImpact) => {
                                    // Vérification si le sous-impact est présent à la fois dans risque.impacts.sousImpacts et dans niveauAlertes
                                    var sousImpactExiste = edp.niveauAlertes?.some((alerte) => {
                                        return alerte.sousImpactId === sousImpact.id;
                                    });

                                    // Si le sous-impact existe, ajouter son libellé à sousImpactLibelle
                                    if (sousImpactExiste) {
                                        impact.sousImpactsLibelle.push(sousImpact as SousImpact);
                                    }

                                    risque.sousImpacts = [...impact.sousImpacts]

                                });
                            });

                            this.populateImpactsList();

                            risque.mesures.forEach((mesure) => {
                                var valeurMesure = this.etudeDePoste?.valeurMesures?.find((vm) => {
                                    return vm.mesureLibelle === mesure.libelle;
                                });

                                if (valeurMesure) {
                                    mesure.valeur = valeurMesure.valeur;
                                    mesure.selected = true;
                                }
                            });

                        })                    

                        this.refreshIndex++;
                        
                        this.clearModel = {...this.etudeDePoste};

                        }
                    );
           }
        }
    }

    deepDiff(obj1: any, obj2: any) {
    const diff: any = {};

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (!obj2.hasOwnProperty(key)) {
          continue; // Skip properties that exist in obj1 but not in obj2
        }

        if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
          if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
              diff[key] = { obj1: obj1[key], obj2: obj2[key] };
            }
          } else {
            const nestedDiff = this.deepDiff(obj1[key], obj2[key]);
            if (Object.keys(nestedDiff).length > 0) {
              diff[key] = nestedDiff;
            }
          }
        } else if (obj1[key] !== obj2[key]) {
          diff[key] = { obj1: obj1[key], obj2: obj2[key] };
        }
      }
    }

    return diff;
  }

    async selectEtudeDePoste(edpId: any) {
        if (edpId == null || edpId == '0') {
            this.resetModel()
        } {
            this.viewMode = true;
            this.editMode = false;
            var diff = this.deepDiff(this.clearModel,this.etudeDePoste);
            var hasDiff = diff && Object.keys(diff).length != 0;
            if(!hasDiff && this.viewMode){
                this.showCreateEtudeDePoste = true;
                this.formTitle = 'Consulter une étude de poste';
                this.formIcon = 'Eye';
                await this.$http.ressifnet.dossiers
                .getEtudeDePosteById(edpId)
                .then((edp: DetailsEtudeDePosteModel) => {
                    this.loadImpactsList = true;
                    this.etudeDePoste = {
                        id : edp.id,
                        dossierId: edp.dossierId,
                        dateEtudeDePoste: edp.dateEtudeDePoste,
                        duree: edp.duree,
                        prescripteurId: edp.prescripteurId,
                        contextesId: edp.contextes?.map(x => x.id),
                        binomesId: edp.binomes?.map(x => x.id),
                        fichiers: edp.fichiers,
                        typeDePosteId: edp.typeDePosteId,
                        stress: edp.stress,
                        note: edp.commentaire ?? {'message': ''},
                        risques: edp.risques,
                        valeurMesures: edp.valeurMesures,
                        niveauxAlertes: edp.niveauAlertes
                    };                                        
                    this.etudeDePoste.risques?.forEach((risque) => {
                        var risquesImpactsLinked = [...risque.impacts];

                        risque.impacts = [];
                        risquesImpactsLinked.forEach((impact) => {

                            var impactAlerte = edp.niveauAlertes?.find((alerte) => {
                                return alerte.impactId === impact.id;
                            });

                            if (impactAlerte) {
                                risque.impacts.push(impact);
                            }
                        }); 

                        risque.niveauxAlertesList = [];

                
                        edp.niveauAlertes?.forEach((alerte, index) => {
                            var niveauAlerte = {
                                risqueId: alerte.risqueId,
                                valeur: alerte.valeur,
                                impactId: alerte.impactId,
                                sousImpactId: alerte.sousImpactId,
                                libelle: ""
                            };

                            // Vérification si l'impactId est présent
                            if (alerte.impactId) {
                                var impact = risque.impacts.find((imp) => {
                                    return imp.id === alerte.impactId;
                                });

                                if (impact) {
                                    niveauAlerte.libelle = impact.libelle;
                                }
                            }

                            // Vérification si le sousImpactId est présent
                            if (alerte.sousImpactId) {
                                var sousImpact = risque.impacts.reduce((acc: any, imp) => {
                                    var sousImpact = imp.sousImpacts.find((simp) => {
                                        return simp.id === alerte.sousImpactId;
                                    });
                                    return sousImpact ? sousImpact : acc;
                                }, null);

                                if (sousImpact) {
                                    niveauAlerte.libelle = sousImpact.libelle;
                                }
                            }

                            if(risque.id == niveauAlerte.risqueId){
                                risque.niveauxAlertesList.push(niveauAlerte);
                            }
                            setTimeout(() => {
                                switch (niveauAlerte.valeur) {
                                    case 0:
                                        (this.$refs['levelOne'] as any )[index].classList.add("active");
                                        break;
                                    case 1:
                                        (this.$refs['levelTwo'] as any )[index].classList.add("active");
                                        break;
                                    case 2:
                                        (this.$refs['levelThree'] as any )[index].classList.add("active");
                                        break;

                                    default:
                                        break;
                                }
                            }, 500)
                        });

                        risque.impacts.forEach((impact) => {
                            impact.sousImpactsLibelle = []; // Création du tableau "sousImpactLibelle"

                            // Parcours des sous-impacts de l'impact actuel
                            impact.sousImpacts.forEach((sousImpact) => {
                                // Vérification si le sous-impact est présent à la fois dans risque.impacts.sousImpacts et dans niveauAlertes
                                var sousImpactExiste = edp.niveauAlertes?.some((alerte) => {
                                    return alerte.sousImpactId === sousImpact.id;
                                });

                                // Si le sous-impact existe, ajouter son libellé à sousImpactLibelle
                                if (sousImpactExiste) {
                                    impact.sousImpactsLibelle.push(sousImpact as SousImpact);
                                }

                                risque.sousImpacts = [...impact.sousImpacts]

                            });
                        });

                        this.populateImpactsList();

                        risque.mesures.forEach((mesure) => {
                            var valeurMesure = this.etudeDePoste?.valeurMesures?.find((vm) => {
                                return vm.mesureLibelle === mesure.libelle;
                            });

                            if (valeurMesure) {
                                mesure.valeur = valeurMesure.valeur;
                                mesure.selected = true;
                            }
                        });

                    })
                    this.refreshIndex++;
                    this.clearModel = {...this.etudeDePoste};
                    }
                );
               }   
                            
        }
    }

    askResetModel() {
        this.$bvModal
            .msgBoxConfirm(
                "Etes-vous sûr de vouloir annuler la création de cette étude de poste ?",
                {
                    title: "Confirmation d'annulation",
                    size: "sm",
                    okVariant: "primary",
                    okTitle: "Oui",
                    cancelTitle: "Non",
                    cancelVariant: "outline-secondary",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            .then(async (value) => {
                if (value === true) {
                    await this.resetModel();
                } else {
                    return;
                }
            });
    }

    resetModel() {
        if( (this.$refs['formValidationEtudeDePoste'] as any)){
            (this.$refs['formValidationEtudeDePoste'] as any).reset();
        }

        // Clear a la main des risque idk why
        this.etudeDePoste.risques?.map((r:any) =>{ r.niveauxAlertesList = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.impacts = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.valeursMesures = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.sousImpacts = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.sousImpactsToSave = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.niveauxAlertesList = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.impactsLibelle = []});
        this.etudeDePoste.risques?.map((r:any) =>{ r.mesures = []});

        this.etudeDePoste = {
            dossierId: this.$route.params.id,
            dateEtudeDePoste: this.dateToday,
            contextes : [],
            binomesId : [],
            binomes : [],
            duree : undefined,
            typeDePosteId : undefined,
            stress : 1,
            note : {message :''},
            risques: [],            
            risquesIds : [],
            valeurMesures : [],
            niveauxAlertes : [],
            prescripteurId: undefined,
            fichiers: []
        };
    }

    getEtudeDePosteFormatted() {
        var risques: RisqueToSave[] = [];
        this.etudeDePoste.risques?.forEach((risque) => {
            var risqueToPush: RisqueToSave = {};
            {
                risqueToPush.id = risque.id,
                    risqueToPush.niveauxAlertes = risque.niveauxAlertes,
                    risqueToPush.valeursMesures = risque.valeursMesures
            }
            risques.push(risqueToPush);
        })
        return {
            dossierId: this.$route.params.id,
            dateEtudeDePoste: this.etudeDePoste.dateEtudeDePoste,
            duree: this.etudeDePoste.duree,
            typeDePosteId: this.etudeDePoste.typeDePosteId,
            prescripteurId: this.etudeDePoste.prescripteurId,
            binomesId: this.etudeDePoste.binomesId,
            contextesId: this.etudeDePoste.contextesId,
            risquesIds: this.etudeDePoste.risques?.map(x => x.id),
            fichiers: this.etudeDePoste.fichiers,
            stress: this.etudeDePoste.stress,
            note: this.etudeDePoste.note,
            niveauxAlertes: this.etudeDePoste.risques?.map(r => r.niveauxAlertesList?.map(na => ({
                valeur: na.valeur,
                impactId: na.impactId,
                sousImpactId: na.sousImpactId
            }))).filter(x => x != undefined).flatMap(x => x),
            valeursMesures: this.etudeDePoste.risques?.map((x) => x.mesures.filter(x => x.selected))
                .flatMap((x: any) => x)
                .filter((x: any) => x != undefined)
                .map((x: any) => ({ mesureId: x.id, valeur: x.valeur }))
        }
    }
}
