














import { Component, Prop, Vue } from "vue-property-decorator";
import DossierDemandesRqth from "./dossiersInstruits/demandeRQTH/Index.vue"
import DossierDemandesSurendettement from "./dossiersInstruits/demandeSurendettement/Index.vue"
import DossierDemandesRetraite from "./dossiersInstruits/demandeRetraite/Index.vue"
import DossierDemandesLogement from "./dossiersInstruits/demandeLogement/Index.vue"
import DossierDemandesCapitalDeces from "./dossiersInstruits/demandeCapitalDeces/Index.vue"
import DossierDemandesInvalidite from "./dossiersInstruits/demandeInvalidite/Index.vue"
import DossierDemandesAideFinanciere from "./dossiersInstruits/demandeAideFinanciere/Index.vue"


@Component({
  components: {
    DossierDemandesRqth,
    DossierDemandesSurendettement,
    DossierDemandesAideFinanciere,
    DossierDemandesRetraite,
    DossierDemandesLogement,
    DossierDemandesCapitalDeces,
    DossierDemandesInvalidite
  },
})
export default class DossiersInstruits extends Vue {

}
