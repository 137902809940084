

























































import { Component, Vue, Watch } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import { CreateNoteModel } from "@/api/models/dossiers/note";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import router from '@/router'

@Component({
  components: {
    RessifnetQuillEditor,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateNote extends Vue {
  required = required

  newNote: CreateNoteModel = {
    dossierId: this.$route.params.id,
  };

  @Watch('newNote')
  update() {
    if (this.newNote.message?.length)
      this.$emit("updated", true);
    else
      this.$emit('updated', false);
  }

  async createNote() {
    await this.$http.ressifnet.notes
      .post(this.$route.params.id, this.newNote)
      .then(
        (response: any) => {
          this.$emit("load-notes");
          successAlert.fire({
            title: "Création d'une note",
            text: "Ajout effectué avec succès",
          });
          this.newNote.message = "";
          this.$emit("updated", false);
          (this.$refs['formValidationIntervention'] as any).reset();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  resetNote() {
    this.newNote = {
      dossierId: this.$route.params.id,
    }
    this.$emit("updated", false);
    (this.$refs['formValidationIntervention'] as any).reset();
  }
}
