









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import {
  BFormSelect,
  BFormCheckboxGroup,
  BFormSelectOption,
  BFormInput,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BPagination
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { RaisonVenue } from "@/api/models/options/rainsonsVenue/raisonVenue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { PrecisionRaisonVenue } from "@/api/models/options/precisionsRaisonVenue/precisionRaisonVenue";
import PassageInfirmerieBilan from "./_Bilan.vue";
import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";

@Component({
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BCardText,
    BFormCheckboxGroup,
    BAlert,
    BFormRadio,
    BTable,
    BCol,
    BPagination,
    SearchableVueSelect,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    RessifnetQuillEditor,
    FeatherIcon,
    BFormCheckbox
  }
})
export default class PassageInfirmerieDiagnosticInfirmier extends Vue {
  @Prop({ required: true }) editable: boolean = true;
  required = required;
  min = min;
  max = max;
  raisonNoDiagnostic : RaisonVenue = {libelle:"Pas de diagnostic"}
  raisonsVenue: PaginatedList<RaisonVenue> = new PaginatedList<RaisonVenue>();
  listPrecisionsRaisonsvenue?: PrecisionRaisonVenue[] = [];

  diagnosticInfirmierModel: PassageInfirmerie = {};
  clearModel: PassageInfirmerie = {};
  noDiagnostic: boolean = false;

  currentPage: any = 1;
  perPage: any = 10;

  async created() {
    await this.loadListRaisonsVenue();
    this.clearModel = JSON.parse(JSON.stringify(this.diagnosticInfirmierModel));
  }

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.diagnosticInfirmierModel));
    this.clearModel = fakeClone;    
  }

  getSelectedRaison(val:any){
    if(val.slice(-1)[0] && val.slice(-1)[0].libelle == 'Pas de diagnostic'){
      this.diagnosticInfirmierModel.raisonsVenue = [];
      this.diagnosticInfirmierModel.precisionsRaisonsVenueId = [];
      this.diagnosticInfirmierModel.raisonsVenue.push(this.raisonNoDiagnostic);
    }else if(val.length == 0 &&  this.diagnosticInfirmierModel.raisonsVenue){
      this.diagnosticInfirmierModel.raisonsVenue.push(this.raisonNoDiagnostic);
    } else {
      this.diagnosticInfirmierModel.raisonsVenue = val.filter((x:any) =>  x.libelle != 'Pas de diagnostic')
    }
  }

  async searchRaisonsVenues(params?: any) {

    if (!params || params?.reset) {
      this.raisonsVenue = new PaginatedList();
    }

    await this.$http.ressifnet.raisonsVenue
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<RaisonVenue>) => {
        this.raisonsVenue.items = this.raisonsVenue.items!.concat(response.items.filter(ri => !this.raisonsVenue.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des raison de venue",
          });
        });
  }

  clearPrecision(raisonVenue: RaisonVenue, value: RaisonVenue[]) {
    if (!value.find(x => x.id == raisonVenue.id)) {
      this.diagnosticInfirmierModel.precisionsRaisonsVenueId = [];
    }
  }

  getFormattedData() {
    this.diagnosticInfirmierModel.raisonsVenueId = this.diagnosticInfirmierModel.raisonsVenue?.filter((x:any) => x.libelle != "Pas de diagnostic").map(x => x.id);
    return this.diagnosticInfirmierModel;
  }

  async loadListRaisonsVenue() {
    await this.$http.ressifnet.raisonsVenue.paginatedList(1, 50)
      .then(
        (response: PaginatedList<RaisonVenue>) => {
          this.raisonsVenue = response;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des demandes Logement'
          })
        }
      )
  }

}

