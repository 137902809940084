

















































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Action } from "@/api/models/options/actions/action";
import { Component, Vue, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts'
import CreateAction from "./_CreateAction.vue";
import { VBPopover, BPagination,BDropdown, BTable } from 'bootstrap-vue'
import store from '@/store/index'
@Component({
  components: {
    CreateAction,
    BPagination,
    BDropdown,
    BTable
  },
  directives: {
    'b-popover' : VBPopover
  }
})
export default class ListeActions extends Vue {
  get userPreferences() {
    return this.$store.state.user.user_preferences
  }
  get preference() {
    return this.userPreferences.find((value: any) => {
      return value.key == 'actionsColumns'
    });
  }

  timeout: any = null;

  currentPage: any =  1;
  perPage: any = 5;

  isSortDirDesc = false;
  sortBy='dateAction';

  selectedActionId: string = "0";
  listActions: PaginatedList<Action> = new PaginatedList<Action>();
  listDomaine: String[] = []

  sortable = true;
  
  tableColumns = [
    { key: 'dateAction', sortable: true, disable: false },
    { key: 'createur', label: "Créateur", sortable: false, disable: false },
    { key: 'contratLibelle', label: "Contrat", sortable: false, disable: false },
    { key: 'typesInterventions', label: "Type d'intervention", sortable: false, disable: false },
    { key: 'naturesInterventions', label: "Nature principale", sortable: false, disable: false },
    { key: 'sousDomainesInterventions', label: "Sous-Domaines", sortable: false, disable: false },
    { key: 'details', sortable: false, disable: false },
    { key: 'actions', sortable: false, disable: false }
  ];

  interventionColumns = []; 
  actionsColumns : any = [];

  createActionIsUpdated = false

  async created() {
    await this.loadListActions({
      pageNumber: this.currentPage,
      pageSize: 5,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
    });
    await this.loadListActionsColumns(); 
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadListActions({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
    })
  }

  @Watch("sortBy")
  @Watch("isSortDirDesc")
  async search() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListActions({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc,
      })
    }, 500);
  }


  async loadListActionsColumns(){
    if(this.userPreferences){        
      if(this.preference){
        var parseColumns = JSON.parse(this.preference.value);
        // pourquoi sort par ordre alphabetique ?
        // this.tableColumns = parseColumns.sort( (a:any,b:any) => a.key.localeCompare(b.key));   
        this.tableColumns = parseColumns.map((x: any) => <any>{
          key: x.key,
          label: x.libelle,
          sortKey: x.sortKey,
          sortable: x.sortable
        });   
        // a quoi ça sert ?
        // this.tableColumns = this.tableColumns.filter((x) => x.disable === false);
        this.tableColumns.push({ key: 'actions', label: "Actions", sortable: false, disable: false });
      } else {
        this.tableColumns.push(...this.tableColumns,{ key: 'actions', label: "Actions", sortable: false, disable: false })
      }          
    }
    
  }

  createActionUpdated(val: boolean = false) {
    this.createActionIsUpdated = val
  }

  async loadListActions(params: any) {
    
    // pour la pagination mettre les params
    await this.$http.ressifnet.actions.paginatedList(this.$route.params.id, params.pageNumber, params.pageSize, params.orderBy, params.orderByDescending).then(
      (response: PaginatedList<Action>) => {
        this.listActions = response;
        this.listActions.items.forEach((action: Action) => {
          action.interventions.forEach((intervention) => {
            intervention.domaines = [ ...new Set(intervention.sousDomaines.map(x => x.domaine)) ]
          })
        })
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des actions",
        });
      }
    );
  }

  createAction(id: string) {
    this.selectedActionId = id;
    (this.$refs['createActionForm'] as any).createFromCopy('0')
  }
  
  createCopy(actionId: string){
    (this.$refs['createActionForm'] as any).createFromCopy(actionId)
  }

  async updateAction(actionId: string){
    await (this.$refs['createActionForm'] as any).createFromCopy(actionId),
    (this.$refs['createActionForm'] as any).model.id = actionId
  }

  async deleteAction(id: string, contratId: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer l\'action ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if (value === true) {
          await this.confirmDeleteAction(id, contratId)
        }
        else {
          return
        }
      })
  }

  async confirmDeleteAction(id: string, contratId: string) {
    await this.$http.ressifnet.actions
      .delete(this.$route.params.id, id, contratId)
      .then(
        async (res: any) => {
          successAlert.fire({
            title: "Suppression d'une action",
            text: "Suppression effectuée avec succès",
          });
          await this.loadListActions({
            pageNumber: this.currentPage,
            pageSize: this.perPage,
            orderBy: this.sortBy,
            orderByDescending: this.isSortDirDesc,
          })
        }
      )
  }

}

