



























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {BFormFile, BProgress, BButton, BFormCheckbox} from "bootstrap-vue";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { DemandeSurendettement } from "@/api/models/options/demandesSurendettement/demandeSurendettement";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
  RessifnetQuillEditor,
  BFormFile,
  BButton,
  BProgress,
  BFormCheckbox,
  RessifnetDateInput,
  },
})
export default class DetailsDemandeSurendettement extends Vue {
    @Prop({ required: true }) demandeId!: string | null

  demandeSurendettementEdited: DemandeSurendettement = {
    recevable: false
  };
  fichiersUploaded = [];
  percentUpload: number = 0;

  
  onUploadProgress(percent: any) {
    this.percentUpload = percent;
  }

  @Watch('demandeId')
  async demandeIdChanged() {
    if (this.demandeId) {
      await this.$http.ressifnet.demandesSurendettement
        .getById(this.$route.params.id, this.demandeId)
        .then((demandeSurendettement : any) => {
        this.demandeSurendettementEdited = { 
            ...demandeSurendettement,
            commentaire: demandeSurendettement.note?.message,
            fichiers: []
        };
        this.fichiersUploaded = demandeSurendettement.fichiers;
        })
    }
    else {
    this.demandeSurendettementEdited = {
      recevable : false
    }
    this.fichiersUploaded = []
    }
  }

  async created() {
    if (this.demandeId) {
      await this.$http.ressifnet.demandesSurendettement
        .getById(this.$route.params.id, this.demandeId)
        .then((demandeSurendettement : any) => {
        this.demandeSurendettementEdited = { 
            ...demandeSurendettement,
            commentaire: demandeSurendettement.note?.message,
            fichiers: []
        };
        this.fichiersUploaded = demandeSurendettement.fichiers;
      })
    }
  }

  get fichiers() {
    var files = [];

    if (this.demandeSurendettementEdited.fichiers && this.demandeSurendettementEdited.fichiers.length > 0) {
      files = this.demandeSurendettementEdited
        .fichiers.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded);
  }

  async downloadFile(fichier:any) {
    if (!fichier.id)
      return;
    else {
      await this.$http.ressifnet.fichiersDossiers
        .downloadFile(fichier.id)
        .then(
          async (response: any) => {
            window.open(response);
          },
          (error: any) => {
            if (error?.response?.data?.errors) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error.message,
              });
            }
          }
        );
    }
  }
  closeDetails() {
    this.demandeSurendettementEdited = {
      fichiers : [],
      recevable : false
    }
    this.$emit('close-details')
  }

  async save(callback: any) {
    let config = {
      onUploadProgress(progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        callback(percentCompleted);
        return percentCompleted;
      },
    };

    if (this.demandeSurendettementEdited.id) {
      await this.$http.ressifnet.demandesSurendettement
        .put(this.$route.params.id, this.demandeSurendettementEdited.id, this.demandeSurendettementEdited, config)
        .then(
          (response: any) => {
            this.$emit('reloadDossier');
            this.$emit('reloadNotes');
            this.$emit('reloadFiles');
            successAlert.fire({
              title: "Modification d'une demande de surendettement",
              text: "Modification effectué avec succès",
            });
            this.percentUpload = 0;
          },
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
            this.percentUpload = 0;
          }
        );
        this.closeDetails()
    }
    else {
      
      let config = {
        onUploadProgress(progressEvent: any) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          callback(percentCompleted);
          return percentCompleted;
        },
      };
      await this.$http.ressifnet.demandesSurendettement.post(this.$route.params.id, this.demandeSurendettementEdited, config)
        .then(
          (response: any) => {
            this.$emit('reloadDossier');
            this.$emit('reloadNotes');
            this.$emit('reloadFiles');
            this.percentUpload = 0;
            successAlert.fire({
              title: "Ajout d'une demande de surendettement",
              text: "Ajout effectué avec succès",
            });
            
            this.percentUpload = 0;
          },
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        );
        this.closeDetails()
    }  
    this.$emit('reload-list')
  }
    removeFichier(fichier: any) {
    if (fichier.id != undefined) {
      this.$bvModal
        .msgBoxConfirm(
          "Etes-vous sûr de vouloir supprimer ce fichier de la demande de surendettement ? Cela le supprimera directement",
          {
            title: "Confirmation de suppression",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            this.confirmRemoveFichier(fichier);
          } else {
            return;
          }
        });
    } else {
      this.demandeSurendettementEdited.fichiers!.splice(
        (this.demandeSurendettementEdited.fichiers! as any[]).indexOf(fichier),
        1
      );
    }
  }

    async confirmRemoveFichier(fichier: any) {
    await this.$http.ressifnet.demandesSurendettement
      .deleteFile(
        this.$route.params.id,
        (this.demandeSurendettementEdited as any).id,
        fichier.id
      )
      .then((res: any) => {
        this.$emit('reloadDossier');
        this.$emit('reload-list')
        successAlert.fire({
          title: "Suppression d'un document",
          text: "Suppression effectuée avec succès",
        });

        (this.demandeSurendettementEdited.fichiers! as any[]).push({ id: fichier.id });
        this.fichiersUploaded.splice(
          (this.fichiersUploaded as any[]).indexOf(fichier),
          1
        );
      });
  }
    
}
