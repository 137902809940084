
























































































import { Component, Vue, Watch } from "vue-property-decorator";
import CreationConsignesDossier from "./_CreateConsignes.vue";
import { BFormCheckbox, BPagination } from 'bootstrap-vue'
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Consigne } from "@/api/models/dossiers/consignes";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    CreationConsignesDossier,
    BFormCheckbox,
    BPagination
  }
})
export default class ListeConsignesDossier extends Vue {
  listConsignes: PaginatedList<Consigne> = new PaginatedList<Consigne>();

  currentPage: any =  1;
  perPage: any = 4;
  traitee = false

  async created(){
    await this.loadListConsignes(
      this.currentPage,
      this.perPage,
      this.traitee
      )
  }

  @Watch('currentPage')
    async currentPageChange(){
      await this.loadListConsignes(
        this.currentPage,
        this.perPage,
        this.traitee
      )
    }

  @Watch('traitee')
    async displayConsigneTraitee(){
      await this.loadListConsignes(
        1,
        4,
        this.traitee
      )
    }

  async loadListConsignes(pageNumber: number, pageSize: number, traitee: boolean){
    await this.$http.ressifnet.consignes.consignesByDossier(this.$route.params.id, pageNumber, pageSize, traitee)
      .then((response: any) => {
        this.listConsignes = response;       
      })
  }

  async updateTraitee(id: string){
    await this.$http.ressifnet.consignes.updateTraiteeForConsigneDossier(this.$route.params.id, id)
        .then(
          async (response: string ) => {
            successAlert.fire({
              title: "Mise à jour d'une consigne",
              text: "Mise à jour effectuée avec succès"
            });
            await this.loadListConsignes(this.currentPage, this.perPage, this.traitee)
          },
          (error: any) => {
            errorAlert.fire({
              title:
                "Erreur lors du chargement de la consigne",
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          }
        );
  }
}
