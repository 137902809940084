





























import { Component, Vue, Watch } from "vue-property-decorator";
import InformationsEtatDossier from "./_InformationsEtat.vue";
import TabsMenuDetailsDossier from "./TabsMenuDetailsDossier.vue";
import DeleteDossier from "./DeleteDossier.vue";
import { Dossier } from "@/api/models/dossiers/dossier";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { BSpinner, BFormCheckbox, BButton } from "bootstrap-vue";
import { TypePreference } from "@/api/models/enums/typePreference";
import DossierAlert from "./DossierAlert.vue";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";

@Component({
  components: {
    DeleteDossier,
    InformationsEtatDossier,
    TabsMenuDetailsDossier,
    BSpinner,
    BFormCheckbox,
    DossierAlert,
    BButton
  }
})
export default class DetailsDossier extends Vue {
  dossier: Dossier = {};
  etatDossier: any = {};
  loading = true
  switchCheckbox = true
  droitsEnum = DroitsEnum;

  get userPreferences() {
    return this.$store.state.user.user_preferences
  }
  get preference() {
    return this.userPreferences.find((value: any) => {
      return value.key == 'anchor'
    });
  }

 @Watch("switchCheckbox")
 activeListener(value:boolean){
  if(value){
    this.handleScroll(true);
  }else {
    this.handleScroll(false);
  }
 }

 handleScroll(value:boolean){
  var barEtat = document.querySelector('#info-etat-scrolled');
  if(value){
    document.addEventListener("scroll",(value:any) => {
    const elementDistanceTop = barEtat?.getBoundingClientRect().top;
    const triggerPosition = 90;    
      if(elementDistanceTop != triggerPosition){
        barEtat?.classList.add('sticky-force')
      }else {
        barEtat?.classList.remove('sticky-force')
      }
    });
  }else {
    barEtat?.removeEventListener("scroll", () => {});
  }  
 }

  async beforeRouteLeave(to: any, from: any, next: any) {
    if (
      ((this.$refs.tabs as any)?.$refs?.listNotes && (this.$refs.tabs as any).$refs.listNotes?.createNoteIsUpdated) ||
      ((this.$refs.tabs as any)?.$refs?.listeActions && (this.$refs.tabs as any).$refs.listeActions?.createActionIsUpdated)      
    ) {
      this.$bvModal
        .msgBoxConfirm(
          "Êtes-vous sûr de vouloir quitter cette page ? Vous avez commencé à remplir le formulaire, les données seront perdues.",
          {
            title: "Confirmation de perte des données",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Non",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value === true) {
            next();
          }
        }); 
    }
    else {
      next()
    }
  }

  async created() {
    await this.loadDossier()
    await this.loadPreference();
    this.handleScroll(this.switchCheckbox);
  }

  async loadPreference(){
    if (!this.preference) {
      this.$store.dispatch('user/saveUserPreference', {
        key: "anchor",
        stockable: true,
        value: JSON.stringify(this.switchCheckbox),
        type: TypePreference.Affichage
      });
    } else {      
      let value = JSON.parse(this.preference.value);
      this.switchCheckbox = value;
    }
  };

  async switchPreference() {
    let payload = { ...this.preference };
    payload.value = JSON.stringify(this.switchCheckbox);
    await this.$http.ressifnet.preferencesUtilisateur.put(
      payload.id, payload
    ).then(async (response: any) => {
      this.$store.dispatch('user/fetchPreferencesUtilisateur');
    }, (error: any) => {

    });
  }

  @Watch('switchCheckbox')
  switchCheckboxFunction(switchValue: any) {
    this.switchCheckbox = switchValue    
  }

  async reloadBandeauAllergies() {
    (this.$refs['etat-sante'] as any).loadListInformationsSante();
  }

  async loadDossier() {
    this.loading = true
    await this.$http.ressifnet.dossiers.get(this.$route.params.id).then(
      (response: Dossier) => {
        this.dossier = response;
        if(this.dossier.email != null)
          this.dossier.hasEmail = true;
        if (this.dossier.droitsStatuts == null)
          this.dossier.droitsStatuts = {}
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des informations du dossier",
        });
      }
    );

    if (this.$can(this.droitsEnum.DOSSIER_INFO_SANTE)) {
      await this.$http.ressifnet.dossiers.getStats(this.$route.params.id).then(
        (response: any) => {
          this.etatDossier = response;
          this.loading = false
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des informations du dossier",
          });
        }
      );
    }
    else 
      this.loading = false
  }
}
