



























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BInputGroup,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BPagination
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";

@Component({
  components: {
    BFormSelect,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BCardText,
    BAlert,
    BTable,
    BCol,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    RessifnetQuillEditor,
    BFormCheckbox,
    FeatherIcon,
  }
})
export default class PassageInfirmerieExamensComplementaires extends Vue {
  @Prop({ required: true }) editable: boolean = true;

  required = required;
  min = min;
  max = max;

  currentPage: any = 1;
  perPage: any = 10;

  showTaille = false;
  showPoid = false; 
  showAudiogramme = false;
  showPrelevementSanguin = false;
  showControleVisuel = false;
  showGlycemieCapilaire = false;
  showTemperature = false;
  showBandeletteUrinaire = false;
  showControleTA = false;
  showTestRespiratoire = false;
  showTestCovid = false;
  showSaO2 = false;

  examensComplementairesModel: PassageInfirmerie = {};
  clearModel : PassageInfirmerie = {};

  async created() {
    this.clearModel = JSON.parse(JSON.stringify(this.examensComplementairesModel));
  }

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.examensComplementairesModel));
    this.clearModel = fakeClone;    
  }

  getFormattedData() {
    return this.examensComplementairesModel;
  } 

  replaceChar(char:string, model:string){
    let formattedChar = '';
    switch (model) {
      case 'taille':
        formattedChar = char.replace(/[.,]/, "");
        this.examensComplementairesModel.taille = parseFloat(formattedChar);

        if (char == '')
          this.examensComplementairesModel.taille = undefined;

        break;
      case 'poids':
        formattedChar = char.replace(/,+/, ".");
        this.examensComplementairesModel.poid = parseFloat(formattedChar);
        
        if (char == '')
          this.examensComplementairesModel.poid = undefined;

        break;
      default:
        return;
        break;
    }
    return;
  }

}

