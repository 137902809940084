











































































































































  import { BSidebar,
    BForm,
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormRadio,
    BDropdown,
    BFormGroup,
    BDropdownItem,
    BFormInput,
    BPagination,
    BRow,
    BTable,
    BSpinner,
    BFormTextarea,
    BFormFile
  } from 'bootstrap-vue';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
  import { PaginatedList } from '@/api/models/common/paginatedList';
  import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
  import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
  import { HabilitationCreateModel } from '@/api/models/options/habilitations/habilitation';
  import { TypeHabilitation } from '@/api/models/options/typeHabilitations/typeHabilitation';
import { TypeHabilitationEnum } from '@/api/models/enums/typeHabilitations';
  
@Component({
    components: {
      BCard,
      BRow,
      BCol,
      BButton,
      BFormInput,
      BTable,
      BPagination,
      BDropdown,
      BDropdownItem,
      BSidebar, 
      BForm,
      BFormGroup,
      BFormCheckbox,
      BFormRadio,
      BSpinner,
      BFormTextarea,
      BFormFile,
      ValidationProvider,
      ValidationObserver,
      SearchableVueSelect,
      RessifnetDateInput
    },
    name: "create-habilitation"
  })
export default class CreateHabilitation extends Vue {
    @Prop({ required: true, type: Boolean }) isAddNewHabilitationSidebarActive!: boolean
    loading = false
    required = required;


    consigneStatus : boolean = false;
    consigneTransmissionsDate : any = '';
    
    typeHabilitationEnum = TypeHabilitationEnum

    newHabilitation: HabilitationCreateModel = {
      dossierId: this.$route.params.id,
      dateHabilitation : (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
    }
    listTypeHabilitations = new PaginatedList<TypeHabilitation>()
  
    resetForm() {
      this.newHabilitation = {
        dateHabilitation : (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
      }
    }
  
    async created() {
      await this.loadListTypeHabilitations({
          pageNumber: 1,
          pageSize: 50,
          actifonly: false
      })   
    }
  
    async loadListTypeHabilitations(params: any) {
      if (!params || params?.reset) {
          this.listTypeHabilitations = new PaginatedList();
      }
  
      await this.$http.ressifnet.typeHabilitations.paginatedList(
          params?.pageNumber ?? 1,
          params?.pageSize ?? 50, 
          params.actifonly ?? false
        ).then(
        (response: PaginatedList<TypeHabilitation>) => {
          this.listTypeHabilitations = response
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types d'habilitation",
          });
        }
      );
    }
  
    getFormatedHabilitation(){      
      if(this.newHabilitation.dateFin && this.newHabilitation.dateFin != null){
        this.newHabilitation.consigneEcheanceHabilitationActivee = true;
        this.newHabilitation.consigneEcheanceHabilitationActivee = !this.consigneStatus;
      }else {
        this.newHabilitation.consigneEcheanceHabilitationActivee = false;
      }

      if(!this.consigneTransmissionsDate){
        this.newHabilitation.consigneEcheanceHabilitationActivee = false;
      }

      this.newHabilitation.dateConsigneEcheanceHabilitation = this.newHabilitation.dateFin && (this.consigneTransmissionsDate != this.getFormatedConsigneDate()) ? this.consigneTransmissionsDate : undefined;

      return {
        dossierId: this.$route.params.id,
        dateHabilitation: this.newHabilitation.dateHabilitation,
        dateFin: this.newHabilitation.dateFin,
        typeHabilitationId: this.newHabilitation.typeHabilitationId,
        typeHabilitationCustom: this.newHabilitation.typeHabilitationCustom,
        consigneEcheanceHabilitationActivee : this.newHabilitation.consigneEcheanceHabilitationActivee,
        dateConsigneEcheanceHabilitation: this.newHabilitation.dateConsigneEcheanceHabilitation
      }
    }

    getFormatedConsigneDate(){
    var dateString = this.newHabilitation.dateFin!;
    var parts = dateString.split('/');
    var formattedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];
    var date = new Date(formattedDateString);
     date.setMonth(date.getMonth() - 2);
    var jour = date.getDate();
    var mois = date.getMonth() + 1;
    var annee = date.getFullYear();
    var jourStr = jour < 10 ? '0' + jour : jour.toString();
    var moisStr = mois < 10 ? '0' + mois : mois.toString();
    var anneeStr = annee.toString();
    var dateResultat = jourStr + '/' + moisStr + '/' + anneeStr;
    return dateResultat;
  }

    @Watch("newHabilitation.dateFin")
      getEcheanceDate(){
        if(this.newHabilitation.dateFin && this.newHabilitation.dateFin != null){
          this.consigneTransmissionsDate = this.getFormatedConsigneDate()
        }else if (this.newHabilitation.dateFin == null){
          this.consigneStatus = true;
        }
      }
    
    async submit() {
      this.loading = true
      var model = this.getFormatedHabilitation();
      await this.$http.ressifnet.habilitations.post(this.$route.params.id, model)
        .then(
          async (response: any) => {
              successAlert.fire({
                timer: 3500,
                title: "Création d'une habilitation",
                text: "Habilitation créé avec succès",
              });
            },     
          (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        )
        .finally(() => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-habilitation-sidebar-active', false)
          this.loading = false
        }); 
    } 
  }
  