






















































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Component, Vue, Watch } from "vue-property-decorator";
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts'
import CreateAction from "./_CreateAction.vue";
import { VBPopover, BPagination, BDropdown, BTable, BCol, BButton, BFormCheckbox, BSpinner, BProgress } from 'bootstrap-vue'
import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";
import { DouleurPsychique } from "@/api/models/options/douleursPsychiques/douleursPsychiques";
import DetailsPassageInfirmerie from "./_DetailsPassageInfirmerie.vue";
import { DouleurPhysique } from '../../../api/models/options/douleursPhysiques/douleursPhysiques';
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Dossier } from "@/api/models/dossiers/dossier";


@Component({
  components: {
    CreateAction,
    BPagination,
    BDropdown,
    BTable,
    BCol,
    BButton,
    BFormCheckbox,
    DetailsPassageInfirmerie,
    RessifnetDateInput,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
    BProgress
  },
  directives: {
    'b-popover' : VBPopover
  }
})
export default class ListePassageInfirmerie extends Vue {
  droitsEnum = DroitsEnum;
  dossier: Dossier = {};
  
  isSortDirDesc = false;
  sortBy='date';

  currentPage: any =  1;
  perPage: any = 5;

  selectedPassageInfirmerieId: string = "0";

  displayAddPassageInfirmerie = false

  searchLoading = false

  IncludeAllPI = false
  IncludeFile = false
  DateDebutIncludePI = null
  DateFinIncludePI = null
  
  listPassageInfirmerie: PaginatedList<PassageInfirmerie> = new PaginatedList<PassageInfirmerie>();
  listDouleursPsychiques: PaginatedList<DouleurPsychique> = new PaginatedList<DouleurPsychique>();
  listDouleursPhysiques: PaginatedList<DouleurPhysique> = new PaginatedList<DouleurPhysique>();

  tableColumns = [
    { key: 'datePassageInfirmerie', sortable: true, disable: false },
    { key: 'horaire', sortable: false, disable: false},
    { key: 'createur', label: "Créateur", sortable: false, disable: false },
    { key: 'typePassageLibelle', label: "Type de passage", sortable: false, disable: false },
    { key: 'listActionsRealisees', label: "Action(s) réalisée(s)", sortable: false, disable: false },
    { key: 'douleurs', label: "Douleurs Présentes", sortable: false, disable: false },
    { key: 'details', sortable: false, disable: false },
    { key: 'actions', sortable: false, disable: false }
  ];
  
  async created() {
    await this.loadListPassageInfirmerie({
      pageNumber: this.currentPage,
      pageSize: 5
    });
    await this.loadDouleursPsychiques();
    await this.loadDouleursPhysiques();
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadListPassageInfirmerie({
      pageNumber: this.currentPage,
      pageSize: this.perPage
    })
  }
  
  clearDataExtratPI(){
    this.DateDebutIncludePI = null
    this.DateFinIncludePI = null
  }

  async extractPI() {

    this.searchLoading = true
    await this.$http.ressifnet.dossiers.get(this.$route.params.id).then(
      (response: Dossier) => {
        this.dossier = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des informations du dossier",
        });
      }
    );
    var data = {
      dateDebutIncludePI: this.DateDebutIncludePI,
      dateFinIncludePI: this.DateFinIncludePI
    }
    await this.$http.ressifnet.passagesInfirmerie
      .extractPI(this.$route.params.id, data)
      .then(
        async (response: any) => {
          var blob = new Blob([response], { type: 'application/pdf;' });
          console.log(blob)
          var url = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = url;
          var date = new Date();
          link.setAttribute('download', `extraction_PI_${this.dossier.numeroDossier}_${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}.pdf`);
          document.body.appendChild(link);
          link.click();
          this.clearDataExtratPI();
          this.IncludeAllPI = false;
          this.$bvModal.hide('ExtratPiModal');
        },
        (error: any) => {
          const errorRepsonse = JSON.parse(new TextDecoder().decode(error.response.data));
          if (errorRepsonse.errors) {
            errorAlert.fire({
              text: errorRepsonse.errors[
                Object.keys(errorRepsonse.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );

      if (this.IncludeFile) {
        await this.$http.ressifnet.passagesInfirmerie
          .downloadFile(this.$route.params.id, data)
          .then(
            async (response: any) => {
              response.forEach((x: any) => window.open(x));
            },
            (error: any) => {
              if (error?.response?.data?.errors) {
                errorAlert.fire({
                  text: error.response.data.errors[
                    Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error.message,
                });
              }
            }
          );
      }
    this.searchLoading = false;
  }

  async addPassageInfirmerie(){
    this.displayAddPassageInfirmerie = true;
    var passageInfirmerieForm = (this.$refs['createPassageInfirmerieForm'] as any);
    if(passageInfirmerieForm)
    {
     passageInfirmerieForm.resetModel();
     passageInfirmerieForm.editable = true;
    }
  }

  resetDisplayAddPassageInfirmerie(){
    this.displayAddPassageInfirmerie = false
  }

  getUniqueIds(list: Array<{ id: number,douleurPhysiqueId:number }>): number[] {
    console.log(list);
    const uniqueIds = new Set<number>();

    list.forEach((element) => {
      uniqueIds.add(element.douleurPhysiqueId);
    });

    var unique = Array.from(uniqueIds);

    return Array.from(uniqueIds);
    }



  async createCopy(passageInfirmerieId: string, editable: boolean){
    setTimeout(async () => {
      await (this.$refs['createPassageInfirmerieForm'] as any).createFromCopy(passageInfirmerieId, editable)
    }, 500);
  }

  async loadListPassageInfirmerie(params: any) {
    await this.$http.ressifnet.passagesInfirmerie.paginatedList(this.$route.params.id, params?.pageNumber, params?.pageSize).then(
      (response: PaginatedList<PassageInfirmerie>) => {
        this.listPassageInfirmerie = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des actions",
        });
      }
    );
  }

  deletePassageInfirmerie(passageInfirmerieId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer le passage infirmerie ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(passageInfirmerieId);
        } else {
          return;
        }
      });
  }

  async confirmDelete(passageInfirmerieId: string) {
    await this.$http.ressifnet.passagesInfirmerie
      .delete(this.$route.params.id, passageInfirmerieId)
      .then(
        (response: any) => {
          successAlert.fire({
            title: "Suppression d\'un passage infirmerie",
            text: "Suppression effectuée avec succès"
          })
          this.loadListPassageInfirmerie({
            pageNumber: this.currentPage,
            pageSize: this.perPage
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  getDouleursPsychiquesLibelle(douleursPsychiquesId: number){
      var douleursPsychiques = this.listDouleursPsychiques.items.filter(x => x.id == douleursPsychiquesId)[0]
      return douleursPsychiques?.libelle
  }

  getDouleurPhysiqueLibelle(douleurPhysiqueId: number){
      var douleurPhysique = this.listDouleursPhysiques.items.filter(x => x.id == douleurPhysiqueId)[0]
      return douleurPhysique?.libelle
  }

  async loadDouleursPsychiques(){
    await this.$http.ressifnet.douleursPsychiques.paginatedList(1, 50).then(
        (response: PaginatedList<PassageInfirmerie>) => {
          this.listDouleursPsychiques = response;
        },
        (error: any) => {
        errorAlert.fire({
            title: "Erreur lors du chargement des douleurs psychiques",
        });
      }
    );
  }

  async loadDouleursPhysiques(){
    await this.$http.ressifnet.douleursPhysiques.paginatedList(1, 50).then(
        (response: PaginatedList<PassageInfirmerie>) => {
          this.listDouleursPhysiques = response;
        },
        (error: any) => {
        errorAlert.fire({
            title: "Erreur lors du chargement des douleurs physiques",
        });
      }
    );
  }
}
