var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"examens-complementaires-wrapper"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('validation-observer',{ref:"examenComplementairesToValidate",attrs:{"tag":"form"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row d-flex justify-content-between align-items-center mb-1"},[_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) { value ? '' : _vm.examensComplementairesModel.taille = undefined , _vm.showTaille = value}},model:{value:(_vm.showTaille),callback:function ($$v) {_vm.showTaille=$$v},expression:"showTaille"}},[_vm._v("Taille")]),_c('validation-provider',{attrs:{"rules":{required : _vm.showTaille },"name":"taille"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"cm"}},[_c('b-form-input',{attrs:{"id":"taille","disabled":!_vm.editable || !_vm.showTaille,"required":_vm.showTaille,"type":"text","placeholder":"Taille"},on:{"input":function (char) { return _vm.replaceChar(char, 'taille'); }},model:{value:(_vm.examensComplementairesModel.taille),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "taille", $$v)},expression:"examensComplementairesModel.taille"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.poid = undefined , _vm.showPoid = value}},model:{value:(_vm.showPoid),callback:function ($$v) {_vm.showPoid=$$v},expression:"showPoid"}},[_vm._v("Poids")]),_c('validation-provider',{attrs:{"rules":{required : _vm.showPoid },"name":"poids"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"kg"}},[_c('b-form-input',{attrs:{"id":"poids","disabled":!_vm.editable || !_vm.showPoid,"required":_vm.showPoid,"type":"text","placeholder":"Poids"},on:{"input":function (char) { return _vm.replaceChar(char, 'poids'); }},model:{value:(_vm.examensComplementairesModel.poid),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "poid", $$v)},expression:"examensComplementairesModel.poid"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),(_vm.examensComplementairesModel.poid && _vm.examensComplementairesModel.taille)?_c('div',{staticClass:"col-2 text-right"},[_c('span',[_c('strong',[_vm._v("IMC :")]),_vm._v(" "+_vm._s(Math.round(_vm.examensComplementairesModel.poid / ((_vm.examensComplementairesModel.taille / 100) * (_vm.examensComplementairesModel.taille / 100)))))])]):_c('div',{staticClass:"col-2"},[_c('strong',[_vm._v("IMC :")]),_vm._v(" -")])]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.audiogramme = undefined , _vm.showAudiogramme = value}},model:{value:(_vm.showAudiogramme),callback:function ($$v) {_vm.showAudiogramme=$$v},expression:"showAudiogramme"}},[_vm._v("Audiogramme")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showAudiogramme },"name":"mesure de l'audiogramme"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"audiogramme","disabled":!_vm.editable || !_vm.showAudiogramme,"required":_vm.showAudiogramme,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.audiogramme),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "audiogramme", $$v)},expression:"examensComplementairesModel.audiogramme"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.prelevementSanguin = undefined , _vm.showPrelevementSanguin = value}},model:{value:(_vm.showPrelevementSanguin),callback:function ($$v) {_vm.showPrelevementSanguin=$$v},expression:"showPrelevementSanguin"}},[_vm._v("Prélèvement sanguin")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showPrelevementSanguin },"name":"mesure du prelevement sanguin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"prelevemetnSanguin","disabled":!_vm.editable || !_vm.showPrelevementSanguin,"required":_vm.showPrelevementSanguin,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.prelevementSanguin),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "prelevementSanguin", $$v)},expression:"examensComplementairesModel.prelevementSanguin"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.controleVisuel = undefined , _vm.showControleVisuel = value}},model:{value:(_vm.showControleVisuel),callback:function ($$v) {_vm.showControleVisuel=$$v},expression:"showControleVisuel"}},[_vm._v("Contrôle visuel")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showControleVisuel },"name":"mesure du controle visuel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"controleVisuel","disabled":!_vm.editable || !_vm.showControleVisuel,"required":_vm.showControleVisuel,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.controleVisuel),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "controleVisuel", $$v)},expression:"examensComplementairesModel.controleVisuel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.glycemieCapilaire = undefined , _vm.showGlycemieCapilaire = value}},model:{value:(_vm.showGlycemieCapilaire),callback:function ($$v) {_vm.showGlycemieCapilaire=$$v},expression:"showGlycemieCapilaire"}},[_vm._v("Glycémie capilaire")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showGlycemieCapilaire },"name":"mesure de la glycémie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"glycemieCapilaire","disabled":!_vm.editable || !_vm.showGlycemieCapilaire,"required":_vm.showGlycemieCapilaire,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.glycemieCapilaire),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "glycemieCapilaire", $$v)},expression:"examensComplementairesModel.glycemieCapilaire"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) { value ? '' : _vm.examensComplementairesModel.sao2 = undefined , _vm.showSaO2 = value}},model:{value:(_vm.showSaO2),callback:function ($$v) {_vm.showSaO2=$$v},expression:"showSaO2"}},[_vm._v("SaO2")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showSaO2 },"name":"mesure de la SaO2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"sao2","disabled":(!_vm.editable || !_vm.showSaO2),"required":_vm.showSaO2,"type":"number","placeholder":"SaO2"},model:{value:(_vm.examensComplementairesModel.sao2),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "sao2", $$v)},expression:"examensComplementairesModel.sao2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.temperature = undefined , _vm.showTemperature = value}},model:{value:(_vm.showTemperature),callback:function ($$v) {_vm.showTemperature=$$v},expression:"showTemperature"}},[_vm._v("Température")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showTemperature },"name":"mesure de la température"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"°C"}},[_c('b-form-input',{attrs:{"id":"controleVisuel","disabled":!_vm.editable || !_vm.showTemperature,"required":_vm.showTemperature,"type":"text","placeholder":"0"},model:{value:(_vm.examensComplementairesModel.temperature),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "temperature", $$v)},expression:"examensComplementairesModel.temperature"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.bandeletteUrinaire = undefined , _vm.showBandeletteUrinaire = value}},model:{value:(_vm.showBandeletteUrinaire),callback:function ($$v) {_vm.showBandeletteUrinaire=$$v},expression:"showBandeletteUrinaire"}},[_vm._v("Bandelette urinaire")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showBandeletteUrinaire },"name":"mesure de la bandelette"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"bandeletteUrinaire","disabled":!_vm.editable || !_vm.showBandeletteUrinaire,"required":_vm.showBandeletteUrinaire,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.bandeletteUrinaire),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "bandeletteUrinaire", $$v)},expression:"examensComplementairesModel.bandeletteUrinaire"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.controleTA = undefined , _vm.showControleTA = value}},model:{value:(_vm.showControleTA),callback:function ($$v) {_vm.showControleTA=$$v},expression:"showControleTA"}},[_vm._v("Contrôle TA")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showControleTA },"name":"mesure de la TA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"controleTa","disabled":!_vm.editable || !_vm.showControleTA,"required":_vm.showControleTA,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.controleTA),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "controleTA", $$v)},expression:"examensComplementairesModel.controleTA"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.testRespiratoire = undefined , _vm.showTestRespiratoire = value}},model:{value:(_vm.showTestRespiratoire),callback:function ($$v) {_vm.showTestRespiratoire=$$v},expression:"showTestRespiratoire"}},[_vm._v("Test respiratoire")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showTestRespiratoire },"name":"mesure du test respiratoire"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"testRespiratoire","disabled":!_vm.editable || !_vm.showTestRespiratoire,"required":_vm.showTestRespiratoire,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.testRespiratoire),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "testRespiratoire", $$v)},expression:"examensComplementairesModel.testRespiratoire"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row align-items-center mb-1"},[_c('div',{staticClass:"col-4"},[_c('b-form-checkbox',{staticClass:"mr-1",attrs:{"disabled":!_vm.editable},on:{"change":function (value) {value ? '' : _vm.examensComplementairesModel.testCovid = undefined , _vm.showTestCovid = value}},model:{value:(_vm.showTestCovid),callback:function ($$v) {_vm.showTestCovid=$$v},expression:"showTestCovid"}},[_vm._v("Tests COVID")])],1),_c('div',{staticClass:"col-8"},[_c('validation-provider',{attrs:{"rules":{required : _vm.showTestCovid },"name":"mesure du test covid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"testCovid","disabled":!_vm.editable || !_vm.showTestCovid,"required":_vm.showTestCovid,"type":"text","placeholder":"information sur la mesure (obligatoire)"},model:{value:(_vm.examensComplementairesModel.testCovid),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "testCovid", $$v)},expression:"examensComplementairesModel.testCovid"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"col-12"},[_c('label',{attrs:{"for":"noteComment"}},[_vm._v("Autres")]),_c('ressifnet-quill-editor',{attrs:{"disabled":!_vm.editable},model:{value:(_vm.examensComplementairesModel.autresExamensRealises),callback:function ($$v) {_vm.$set(_vm.examensComplementairesModel, "autresExamensRealises", $$v)},expression:"examensComplementairesModel.autresExamensRealises"}})],1)])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h2',{staticClass:"card-title mb-0"},[_vm._v(" Examens complémentaires ")]),_c('p',{staticClass:"sub-title"},[_vm._v(" Examens effectués et prise de mesures ")])])}]

export { render, staticRenderFns }