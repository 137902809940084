




























































import { Component, Prop, Vue } from "vue-property-decorator";
import DetailsDemandeLogement from "./_DetailsDemandeLogement.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DemandeLogement } from "@/api/models/dossiers/demandesLogement/demandeLogement";
import { VBPopover, BSpinner } from "bootstrap-vue";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    DetailsDemandeLogement,
    BSpinner,
    DeleteIcon,
    EditIcon
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "dossier-demandes-logement"
})
export default class DossierDemandesLogement extends Vue {
  listDemandeLogement: PaginatedList<DemandeLogement> = new PaginatedList<DemandeLogement>()

  displayLogementDetails = false
  currentDemandeId: string | null = null
  loading: boolean = true

  async created() {
    await this.loadListDemandesLogement();
  }

  async loadListDemandesLogement() {
    await this.$http.ressifnet.demandesLogement.paginatedList(this.$route.params.id, 1, 50)
      .then(
        (response: PaginatedList<DemandeLogement>) => {
          this.listDemandeLogement = response
          this.loading = false
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des demandes Logement'
          })
        }
      )
  }

  async askDeleteDemandeLogement(id: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la demande logement ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value === true){
          await this.confirmDeleteDemandeLogement(id)
        }
        else {
          return;
        }
      })
  }

  async confirmDeleteDemandeLogement(id: string) {
    await this.$http.ressifnet.demandesLogement
      .delete(this.$route.params.id, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une demande logement",
            text: "Suppression effectuée avec succès",
          });
          this.loadListDemandesLogement();
          this.currentDemandeId = null
        }
      )
  }

  async askUpdateDemandeLogement(id: string){
    this.$emit('reloadDossier');
    this.currentDemandeId = id;
    this.displayLogementDetails = true;
  }

  addDemandeLogement() {
    this.currentDemandeId = null;
    this.displayLogementDetails = true;
  }

  
}
