

















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BButton,
  BInputGroup,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BPagination,
  BFormCheckbox
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { DouleurPsychique } from "@/api/models/options/douleursPsychiques/douleursPsychiques";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { DouleursCreateModel, PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";
import { DouleurPhysique } from "@/api/models/options/douleursPhysiques/douleursPhysiques";
import { TypeDouleurPhysique, TypeDouleurPhysiqueCreateModel } from "@/api/models/options/typesDouleursPhysiques/typesDouleursPhysiques";

@Component({
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BInputGroup,
    BCardText,
    BAlert,
    BTable,
    BCol,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    RessifnetQuillEditor,
    SearchableVueSelect,
    FeatherIcon,
    BFormCheckbox
  }
})
export default class PassageInfirmerieDouleurs extends Vue {
  @Prop({ required: true }) editable: boolean = true;
  min = min;
  max = max;

  currentPage: any = 1;
  perPage: any = 10;

  pasDeDouleursPsychiques = true;
  pasDeDouleursPhysiques = true;

  totalCountPrecisions: number = 0
  refreshIndex: number = 0

  douleursModel: PassageInfirmerie = {};
  clearModel: PassageInfirmerie = {};
  douleursCreateModel: DouleursCreateModel = {};


  douleursPsychiques: PaginatedList<DouleurPsychique> = new PaginatedList<DouleurPsychique>();
  douleursPhysiques: PaginatedList<DouleurPhysique> = new PaginatedList<DouleurPhysique>();

  async created() {
    await this.loadListDouleursPsychiques();
    await this.loadListDouleursPhysiques();
    this.clearModel = JSON.parse(JSON.stringify(this.douleursCreateModel));
  }

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.douleursCreateModel));
    this.clearModel = fakeClone;    
  }

  clearErrors(val: any) {
    if (val) {
      this.douleursModel.douleursPsychiques = [];
      (this.$refs['formValidationDouleursPsy'] as any).reset();
    }
  }

  @Watch('douleursModel.douleursPhysiques')
  onDouleurPhysiqueAdd() {
    if (this.douleursModel.douleursPhysiques?.length != 0) {
      this.pasDeDouleursPhysiques = false;
    } else {
      this.pasDeDouleursPhysiques = true;
    }
  }

  clearErrorsPhysiques(val: any) {
    if (val) {
      this.douleursModel.douleursPhysiques = [];
      this.douleursCreateModel.typeDouleursPhysiques = [];
      (this.$refs['formValidationDouleursPhy'] as any).reset();

    }
  }

  @Watch('douleursModel.douleursPsychiques')
  onDouleurAdd() {
    if (this.douleursModel.douleursPsychiques?.length != 0) {
      this.pasDeDouleursPsychiques = false;
    } else {
      this.pasDeDouleursPsychiques = true;
    }
  }


  async searchDPsy(params?: any) {

    if (!params || params?.reset) {
      this.douleursPsychiques = new PaginatedList();
    }

    await this.$http.ressifnet.douleursPsychiques
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DouleurPsychique>) => {
        this.douleursPsychiques.items = this.douleursPsychiques.items!.concat(response.items.filter(ri => !this.douleursPsychiques.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des douleurs",
          });
        });
  }


  async searchDPhy(params?: any) {

    if (!params || params?.reset) {
      this.douleursPhysiques = new PaginatedList();
    }

    await this.$http.ressifnet.douleursPhysiques
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DouleurPhysique>) => {
        this.douleursPhysiques.items = this.douleursPhysiques.items!.concat(response.items.filter(ri => !this.douleursPhysiques.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des douleurs",
          });
        });
  }



  getFormattedData() {
    this.douleursCreateModel.douleursPsychiques = this.douleursModel.douleursPsychiques;
    if (this.douleursModel.douleursPhysiques) {
      this.douleursCreateModel.typeDouleursPhysiques = this.douleursModel.douleursPhysiques.flatMap(x => x.typesDouleursPhysiquesToSave);
    }
    return this.douleursCreateModel;
  }

  clearPrecisionOnDouleurUnselect(value: DouleurPsychique, mode?: boolean) {
    value.precision = undefined;
    if (mode) {
      this.douleursModel.douleursPsychiques = this.douleursModel.douleursPsychiques?.filter(x => x.id != value.id);
    }
  }

  onTypeDouleurSelecting(typeDouleur: TypeDouleurPhysique, model: DouleurPhysique) {

    // Create array if empty
    if (!model.typesDouleursPhysiquesToSave?.length) {
      model.typesDouleursPhysiquesToSave = [];
    }
    // Selecting Douleur Type De Douleur
    var TypeDouleurPhysiqueCreate: TypeDouleurPhysique = { id: typeDouleur.id, precision: typeDouleur.precision, libelle: typeDouleur.libelle, douleurPhysiqueId: model.id };
    model.typesDouleursPhysiquesToSave?.push(TypeDouleurPhysiqueCreate);

    this.refreshIndex++;
  }

  clearDouleurPhysique(douleur: DouleurPhysique) {
    douleur.typesDouleursPhysiquesToSave = [];
    if(douleur.typesDouleursPhysiques){
      douleur.typesDouleursPhysiques.find((x:any) => x.douleurPhysiqueId == douleur.id)!.precision = undefined;
    }
    if (this.douleursModel.douleursPhysiques) {
      this.douleursModel.douleursPhysiques = this.douleursModel.douleursPhysiques.filter(x => x.id != douleur.id);
    }
  }
  clearTypeDouleurPhysique(value: any) {

    var currentDouleurPhysique: DouleurPhysique | undefined = this.douleursModel.douleursPhysiques?.find(x => x.id == value.douleurPhysiqueId);
    // Clear la precision du type de douleur    
    if (currentDouleurPhysique && currentDouleurPhysique.typesDouleursPhysiquesToSave) {
      currentDouleurPhysique.typesDouleursPhysiquesToSave = currentDouleurPhysique.typesDouleursPhysiquesToSave.filter(x => x.id != value.id);
      if(currentDouleurPhysique.typesDouleursPhysiques)
        currentDouleurPhysique.typesDouleursPhysiques.find((x:any) => x.douleurPhysiqueId == currentDouleurPhysique!.id)!.precision = undefined;

      this.refreshIndex++;
    }

  }


  onTypeDouleurDeselecting(typeDouleur: TypeDouleurPhysique, model: DouleurPhysique) {
    // Selecting Douleur Type De Douleur
    model.typesDouleursPhysiquesToSave = model.typesDouleursPhysiquesToSave?.filter(x => x.id != typeDouleur.id);

  }

  async loadListDouleursPhysiques() {
    await this.$http.ressifnet.douleursPhysiques.paginatedList(1, 50)
      .then(
        (response: PaginatedList<DouleurPhysique>) => {
          this.douleursPhysiques = response;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des données'
          })
        }
      )
  }

  async loadListDouleursPsychiques() {
    await this.$http.ressifnet.douleursPsychiques.paginatedList(1, 50)
      .then(
        (response: PaginatedList<DouleurPsychique>) => {
          this.douleursPsychiques = response;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des données'
          })
        }
      )
  }

}

