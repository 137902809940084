







































































































import { Note, UpdateNoteModel } from "@/api/models/dossiers/note";
import { Component, Vue, Watch } from "vue-property-decorator";
import CreateNote from "./_CreateNote.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import { required } from "@validations";
import { BForm, BFormGroup, BPagination } from 'bootstrap-vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    CreateNote,
    ValidationProvider,
    ValidationObserver,
    RessifnetQuillEditor,
    BPagination,
    BForm,
    BFormGroup,
    DeleteIcon,
    EditIcon
  },
})
export default class ListeNotes extends Vue {
  required = required

  currentPage: any =  1;
  perPage: any = 5;

  listNotes: PaginatedList<Note> = new PaginatedList<Note>();
  noteToUpdate: UpdateNoteModel = {
    dossierId: this.$route.params.id
  }

  createNoteIsUpdated = false

  async created() {
    await this.loadNotes({});
  }

  @Watch('currentPage')
  async currentPageChange(){
    await this.loadNotes({})
  }
  
  createNoteUpdated(val: boolean = true) {
    this.createNoteIsUpdated = val;
  }

  async loadNotes(params: any) {
    await this.$http.ressifnet.notes
      .paginatedList(
        this.$route.params.id,
        this.currentPage,
        this.perPage
      )
      .then((response: PaginatedList<Note>) => {
        this.listNotes = response;
      });
  }

  deleteNote(noteId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer la note ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(noteId);
        } else {
          return;
        }
      });
  }

  async confirmDelete(noteId: string) {
    await this.$http.ressifnet.notes
      .delete(this.$route.params.id, noteId)
      .then(
        (response: any) => {
          successAlert.fire({
            title: "Suppression d\'une note",
            text: "Suppression effectuée avec succès"
          })
          this.loadNotes({});
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  updateNote(note: Note) {
    this.noteToUpdate.message = note.message
    this.noteToUpdate.id = note.id
    this.$bvModal.show('modal-put-note')
  }

  async confirmUpdate() {
    await this.$http.ressifnet.notes
      .put(this.$route.params.id, this.noteToUpdate.id, this.noteToUpdate)
      .then(
        (response: any) => {
          successAlert.fire({
            title: "Modification d\'une note",
            text: "Modification effectuée avec succès"
          })
          this.loadNotes({});
          this.$bvModal.hide('modal-put-note')
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }
}
