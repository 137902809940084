

































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import RessifnetQuillEditor from "@/components/inputs/RessifnetQuillEditor.vue";
import VueSlider from 'vue-slider-component';
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BFormCheckbox,
  BFormInput,
  BButton,
  BCardText,
  BAlert,
  BTable,
  BCol,
  BPagination
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { Orientation } from "@/api/models/options/orientations/orientation";
import { PointPositif } from "@/api/models/options/pointsPositifs/pointPositif";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { PointNegatif } from "@/api/models/options/pointsNegatifs/pointNegatif";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { PassageInfirmerie } from "@/api/models/dossiers/passageInfirmerie";
import { ActionRealisee } from "@/api/models/options/actionsRealisees/actionRealisee";
import { TypeVisite } from "@/api/models/options/typesVisite/typeVisite";

@Component({
  components: {
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BButton,
    BCardText,
    BAlert,
    BInputGroup,
    VueSlider,
    BTable,
    SearchableVueSelect,
    BCol,
    BPagination,
    ValidationObserver,
    ValidationProvider,
    BFormSelectOption,
    RessifnetQuillEditor,
    FeatherIcon,
  }
})
export default class PassageInfirmerieBilan extends Vue {
  @Prop({ required: true }) editable: boolean = true;
  @Prop({ required: true }) typeVisite!: TypeVisite;
  
  required = required;
  min = min;
  max = max;

  currentPage: any = 1;
  perPage: any = 10;

  bilanModel: PassageInfirmerie = {};
  clearModel: PassageInfirmerie = {};

  orientations: PaginatedList<Orientation> = new PaginatedList<Orientation>();
  pointsPositifs: PaginatedList<PointPositif> = new PaginatedList<PointPositif>();
  pointsNegatifs: PaginatedList<PointNegatif> = new PaginatedList<PointNegatif>();
  actionsRealisees: PaginatedList<ActionRealisee> = new PaginatedList<ActionRealisee>();

  typeSuivisRequired = false;
  
  stressOptional: boolean = false;
  satisfactionOptional: boolean = false;

  async created() {
    await this.loadListOrientation();
    await this.loadListPointsPositifs();
    await this.loadListPointsNegatifs();
    await this.searchActionsRealisees(null);
    this.clearModel = JSON.parse(JSON.stringify(this.bilanModel));
  }

  getClearModel(){
    return this.clearModel;
  }

  createClone(){
    var fakeClone = JSON.parse(JSON.stringify(this.bilanModel));
    this.clearModel = fakeClone;    
  }

  setTypeSuivisRequired(value:boolean){
    this.typeSuivisRequired = value;
  }

  getFormattedData() {
    if(this.satisfactionOptional){
      this.bilanModel.satisfaction = undefined;
    }
    if(this.stressOptional){
      this.bilanModel.stress = undefined;
    }
    return this.bilanModel;
  }

  async getTypeVisite(){
    let generalRef = (this.$refs['formValidationGeneral'] as any);
    console.log(generalRef);
  };

  async searchOrientations(params?: any) {
    if (!params || params?.reset) {
      this.orientations = new PaginatedList();
    }

    await this.$http.ressifnet.orientations
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Orientation>) => {
        this.orientations.items = this.orientations.items!.concat(response.items.filter(ri => !this.orientations.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des orientation",
          });
        });
  }

  async searchPointsPositifs(params?: any) {
    if (!params || params?.reset) {
      this.pointsPositifs = new PaginatedList();
    }

    await this.$http.ressifnet.pointsPositifs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<PointPositif>) => {
        this.pointsPositifs.items = this.pointsPositifs.items!.concat(response.items.filter(ri => !this.pointsPositifs.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des points positifs",
          });
        });
  }

  async searchPointsNegatifs(params?: any) {
    if (!params || params?.reset) {
      this.pointsNegatifs = new PaginatedList();
    }

    await this.$http.ressifnet.pointsNegatifs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<PointNegatif>) => {
        this.pointsNegatifs.items = this.pointsNegatifs.items!.concat(response.items.filter(ri => !this.pointsNegatifs.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des points negatifs",
          });
        });
  }

  async loadListOrientation() {
    await this.$http.ressifnet.orientations.paginatedList(1, 50)
      .then(
        (response: PaginatedList<Orientation>) => {
          this.orientations = response;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des données'
          })
        }
      )
  }

  async loadListPointsPositifs() {
    await this.$http.ressifnet.pointsPositifs.paginatedList(1, 50)
      .then(
        (response: PaginatedList<PointPositif>) => {
          this.pointsPositifs = response;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des données'
          })
        }
      )
  }

  async loadListPointsNegatifs() {
    await this.$http.ressifnet.pointsNegatifs.paginatedList(1, 50)
      .then(
        (response: PaginatedList<PointNegatif>) => {
          this.pointsNegatifs = response;
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des données'
          })
        }
      )
  }

  async searchActionsRealisees(params?: any) {

    if (!params || params?.reset) {
      this.actionsRealisees = new PaginatedList();
    }

    await this.$http.ressifnet.actionsRealisees
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<ActionRealisee>) => {
        this.actionsRealisees.items = this.actionsRealisees.items!.concat(response.items.filter(ri => !this.actionsRealisees.items!.some(lsi => lsi.id == ri.id)));
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des Actions Réalisées",
          });
        });
    }

}

