
















































































































































































import { BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormRadio,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BSpinner,
  BFormTextarea
} from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { VaccinationCreateModel } from "@/api/models/dossiers/vaccination"
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from '@/api/models/common/paginatedList';
import { errorAlert, successAlert } from '@/libs/sweetAlerts/alerts';
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { Maladie } from '@/api/models/options/maladies/maladie';
import MultipleFileInput from "@/components/inputs/MultipleFileInput.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar, 
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    RessifnetDateInput,
    MultipleFileInput
  },
  name: "create-vaccination"
})
export default class CreateVaccination extends Vue {
  @Prop({ required: true, type: Boolean }) isAddNewVaccinationSidebarActive!: boolean
  loading = false

  maladieSearchQuery = ''
  required = required;
  dateToday = (new Date().toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', year: 'numeric' }))
  
  newVaccination: VaccinationCreateModel = {
    dossierId: this.$route.params.id,
    date: this.dateToday,
    maladiesId: [],
    fichiers: []
  }
  listMaladies = new PaginatedList<Maladie>()

  fichiersUploaded = [];


  get fichiers() {
    var files = [];

    if (this.newVaccination.fichiers && this.newVaccination.fichiers.length > 0) {
      files = this.newVaccination
        .fichiers.filter((f: any) => f.id == undefined)
        .map(
          (x: File) =>
            <any>{
              nom: x.name,
            }
        );
    }
    return files.concat(this.fichiersUploaded);
  }


  resetForm() {
    this.newVaccination = {
      date : this.dateToday
    };
    (this.$refs['createFormValidation'] as any).reset();
  }

  async created() {
    await this.loadListMaladies({
        pageNumber: 1,
        pageSize: 1000,
        actifonly: false
    })   
  }

  async loadListMaladies(params: any) {
    if (!params || params?.reset) {
        this.listMaladies = new PaginatedList();
    }

    await this.$http.ressifnet.maladies.paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 1000, 
        params.actifonly ?? false,
        params.search
      ).then(
      (response: PaginatedList<Maladie>) => {
        this.listMaladies.items = this.listMaladies.items!.concat(response.items.filter(ri => !this.listMaladies.items!.some(lsi => lsi.id == ri.id)))
        this.listMaladies.totalCount = response.totalCount
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des maladies",
        });
      }
    );
  }

  getFormatedVaccination(){
    return {
      dossierId: this.$route.params.id,
      date: this.newVaccination.date,
      numLot: this.newVaccination.numLot,
      marque: this.newVaccination.marque,
      siteInjection: this.newVaccination.siteInjection,
      commentaire: this.newVaccination.commentaire,
      maladiesId: this.newVaccination.maladiesId,
      fichiers: this.newVaccination.fichiers
    }
  }

  removeFichier(fichier: any) {
    if (fichier != undefined) {
        this.newVaccination.fichiers!.splice(
        (this.newVaccination.fichiers! as any[]).indexOf(fichier),
        1);
    }
  }
  
  async submit() {
    this.loading = true
    var model = this.getFormatedVaccination();
    await this.$http.ressifnet.vaccinations.post(this.$route.params.id, model)
      .then(
        async (response: any) => {
            successAlert.fire({
              timer: 3500,
              title: "Création d'un vaccin",
              text: "Vaccin créé avec succès",
            });
            (this.$refs['createFormValidation'] as any).reset();
            this.resetForm();
          },     
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit('refetch-data')
        this.$emit('update:is-add-new-vaccin-sidebar-active', false)
        this.loading = false
      }); ;
  } 
}
