

































































import { Component, Prop, Vue } from "vue-property-decorator";
import DetailsDemandeRqth from "./_DetailsDemandeRqth.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DemandeRqth } from "@/api/models/options/demandesRqth/demandeRqth";
import { VBPopover, BSpinner, BFormCheckbox } from "bootstrap-vue";
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    DetailsDemandeRqth,
    BSpinner,
    BFormCheckbox,
    DeleteIcon,
    EditIcon
  },
  directives: {
    'b-popover': VBPopover
  },
  name: "dossier-demandes-rqth"
})
export default class DossierDemandesRqth extends Vue {
  listDemandeRqth: PaginatedList<DemandeRqth> = new PaginatedList<DemandeRqth>()

  displayRqthDetails = false
  currentDemandeId: string | null = null
  loading: boolean = true

  async created() {
    await this.loadListDemandesRqth();
  }

  async loadListDemandesRqth() {
    await this.$http.ressifnet.demandesRqth.paginatedList(this.$route.params.id, 1, 50)
      .then(
        (response: PaginatedList<DemandeRqth>) => {
          this.listDemandeRqth = response
          this.loading = false
        }, (error: any) => {
          errorAlert.fire({
            title: 'Erreur lors du chargement des demandes RQTH'
          })
        }
      )
  }

  async askDeleteDemandeRqth(id: string) {
    this.$bvModal
      .msgBoxConfirm('Etes-vous sûr de vouloir supprimer la demande RQTH ?', {
        title: 'Confirmation de suppression',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async value => {
        if(value === true){
          await this.confirmDeleteDemandeRqth(id)
        }
        else {
          return;
        }
      })
  }

  async confirmDeleteDemandeRqth(id: string) {
    await this.$http.ressifnet.demandesRqth
      .delete(this.$route.params.id, id)
      .then(
        (res: any) => {
          successAlert.fire({
            title: "Suppression d'une demande RQTH",
            text: "Suppression effectuée avec succès",
          });
          this.loadListDemandesRqth();
          this.currentDemandeId = null
        }
      )
  }

  async askUpdateDemandeRqth(id: string){
    this.currentDemandeId = id;
    this.displayRqthDetails = true;
  }

  addDemandeRQTH() {
    this.currentDemandeId = null;
    this.displayRqthDetails = true;
  }

  
}
