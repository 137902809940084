































































import { Component, Vue, Prop } from "vue-property-decorator";


@Component({
  components: {
  }
})
export default class DossierAlert extends Vue {
  @Prop({ required: true }) switchCheckbox!: any;
  @Prop({ required: true }) etatDossier!: any;
}
